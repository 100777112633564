var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSizes", class: { visible: "open" } },
    [
      _vm.options.length > 1
        ? [
            _vm.collapse
              ? _c(
                  "a",
                  {
                    staticClass: "toggleSizes",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.visible = !_vm.visible
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("Select Size")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "svgIcon" }, [
                      !_vm.visible
                        ? _c(
                            "svg",
                            {
                              attrs: {
                                viewBox: "0 0 12 12",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M0 6C0 5.44772 0.447715 5 1 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H1C0.447715 7 0 6.55228 0 6Z",
                                  fill: "#8497B5",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M6 -4.37114e-08C6.55228 -1.95703e-08 7 0.447715 7 1L7 11C7 11.5523 6.55228 12 6 12C5.44771 12 5 11.5523 5 11L5 1C5 0.447715 5.44772 -6.78525e-08 6 -4.37114e-08Z",
                                  fill: "#8497B5",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "svg",
                            {
                              attrs: {
                                viewBox: "0 0 12 2",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z",
                                  fill: "#8497B5",
                                },
                              }),
                            ]
                          ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.multiSelect === true
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visible || !_vm.collapse,
                        expression: "visible || !collapse",
                      },
                    ],
                    staticClass: "multiSize",
                  },
                  [
                    _vm.opts.packSize.length > 1
                      ? _c("div", { staticClass: "productSize" }, [
                          _c("label", { attrs: { for: "sizePackSize" } }, [
                            _vm._v("Pack Size:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "sizeSelect" },
                            [
                              _c("chosen", {
                                attrs: {
                                  options: _vm.opts.packSize,
                                  name: "packSize",
                                  placeholder: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setSelected()
                                  },
                                },
                                model: {
                                  value: _vm.selected.packSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selected, "packSize", $$v)
                                  },
                                  expression: "selected.packSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.opts.strength.length
                      ? _c("div", { staticClass: "productSize" }, [
                          _c("label", { attrs: { for: "sizeStrength" } }, [
                            _vm._v("Strength:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "sizeSelect" },
                            [
                              _c("chosen", {
                                attrs: {
                                  options: _vm.opts.strength,
                                  name: "strength",
                                  placeholder: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setSelected()
                                  },
                                },
                                model: {
                                  value: _vm.selected.strength,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selected, "strength", $$v)
                                  },
                                  expression: "selected.strength",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              : _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visible || !_vm.collapse,
                        expression: "visible || !collapse",
                      },
                    ],
                    staticClass: "singleSize",
                  },
                  [
                    _c("div", { staticClass: "productSize" }, [
                      _c("label", { attrs: { for: "sizeStrength" } }, [
                        _vm._v("Size:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sizeSelect" },
                        [
                          _c("chosen", {
                            attrs: {
                              options: _vm.options,
                              name: "size",
                              label: "display_value",
                              trackBy: "id",
                              placeholder: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.triggerChange()
                              },
                            },
                            model: {
                              value: _vm.selectedID,
                              callback: function ($$v) {
                                _vm.selectedID = $$v
                              },
                              expression: "selectedID",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedID,
                  expression: "selectedID",
                },
              ],
              attrs: { type: "hidden", name: "size" },
              domProps: { value: _vm.selectedID },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.selectedID = $event.target.value
                  },
                  function ($event) {
                    return _vm.triggerChange()
                  },
                ],
              },
            }),
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedID,
                  expression: "selectedID",
                },
              ],
              attrs: { type: "hidden", name: "size" },
              domProps: { value: _vm.selectedID },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.selectedID = $event.target.value
                  },
                  function ($event) {
                    return _vm.triggerChange()
                  },
                ],
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }