var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      attrs: {
        "data-placeholder": _vm.placeholder,
        multiple: _vm.multiple,
        disabled: _vm.disabled,
      },
    },
    [
      _c("option", {
        attrs: { value: "" },
        domProps: { innerHTML: _vm._s(_vm.placeholder) },
      }),
      _vm._v(" "),
      _vm._l(_vm.localOptions, function (option) {
        return _c("option", {
          domProps: {
            value: option[_vm.trackBy],
            innerHTML: _vm._s(option[_vm.label]),
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }