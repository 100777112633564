function createPaymentWaypoint() {
    'use strict';

    var waypoint = new Waypoint({
        element: $('.footer'),
        handler: function(direction) {
            if (direction === 'down') {
                $('.paymentDetails').removeClass('fixed');
            } else {
                $('.paymentDetails').addClass('fixed');
            }
        }, offset: -300
    });
}

$(document).ready(function () {
    'use strict';

    if ($('.paymentDetails').length) {
        createPaymentWaypoint();
    }

}); // document ready
