$(document).ready(function() {

    'use strict';

    informationBanner();

});

$(window).on('resize', function() {

    informationBanner();

});

function informationBanner() {

    'use strict';

    if ($('.infoBanner .iconList').length) {

        slickIt('.infoBanner .iconList', 1000, {
            dots : false,
            infinite : true,
            arrows : false,
            autoplay : true,
            slidesToShow: 5,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 3000,
            fade: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }

}

