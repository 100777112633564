var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c("dialog", { staticClass: "travelQuantityAdjust" }, [
        _c("div", { staticClass: "dialogBody" }, [
          _c("header", [
            _vm.product
              ? _c(
                  "div",
                  { staticClass: "headerImage" },
                  [
                    _c("product-images", {
                      attrs: {
                        images: _vm.product.images,
                        "lazy-load": true,
                        size: "thumbs",
                        slideshow: false,
                        "show-arrows": false,
                        "show-thumbs": false,
                        "allow-zoom": false,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "headerTitle" }, [
              _c("h3", { domProps: { innerHTML: _vm._s(_vm.dialogTitle) } }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "closeButton",
                  attrs: {
                    type: "button",
                    "aria-label": "Close quanitity adjustment",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeOverlay(true)
                    },
                  },
                },
                [
                  _c("i", [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "20",
                          viewBox: "0 0 20 20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "1.41406",
                            width: "25",
                            height: "2",
                            rx: "1",
                            transform: "rotate(45 1.41406 0)",
                            fill: "currentColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            y: "17.6777",
                            width: "25",
                            height: "2",
                            rx: "1",
                            transform: "rotate(-45 0 17.6777)",
                            fill: "currentColor",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dialogContent" }, [
            _c(
              "label",
              {
                staticClass: "dialogContentTitle",
                attrs: { for: "travelQuantitySelection" },
              },
              [_vm._v("Adjust medication quantity")]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "quantitySelectionWrap" }, [
              _c("div", { staticClass: "selectionLeft" }, [
                _c("p", [
                  _vm._v(
                    "\n                        Recommended quantity based on your travel dates: "
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.quantityString) },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "selectionRight" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("mtc-number-input", {
                      attrs: {
                        max: 99,
                        min: _vm.minimumQuantity,
                        value: _vm.quantity,
                        id: "travelQuantitySelection",
                        name: "product_quantity",
                      },
                      on: { input: _vm.updateQuantity },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "quantityConfirm" }, [
              _c("div", { staticClass: "row" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.confirmed,
                      expression: "confirmed",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "product_quantity_confirm",
                    id: "product_quantity_confirm",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.confirmed)
                      ? _vm._i(_vm.confirmed, null) > -1
                      : _vm.confirmed,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.confirmed,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.confirmed = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.confirmed = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.confirmed = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "product_quantity_confirm" } }, [
                  _vm._v(
                    "\n                        I confirm that I have enough spare tablets that are within\n                        their use-by-date to cover the duration of my trip.\n                    "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("footer", [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: {
                  type: "button",
                  disabled: !_vm.confirmed || _vm.quantity < 1,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitQuantity.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                Confirm adjusted quantity\n            "
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wysiwyg" }, [
      _c("p", [
        _vm._v(
          "\n                    Want to adjust the amount prescribed?  Do you have a few tablets spare from a previous trip, \n                    or want to purchase a few extras just in case? No problem, just increase or decrease the \n                    number below:\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }