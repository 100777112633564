var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "travelDestinationsWrap" }, [
    _vm.destinationList.length
      ? _c("div", { staticClass: "travelDestinations" }, [
          _c(
            "ul",
            { staticClass: "destinationList" },
            [
              _vm._l(_vm.destinationList, function (dest, index) {
                return [
                  _c(
                    "li",
                    {
                      key: "travel_destination_" + index,
                      staticClass: "destinationRow",
                    },
                    [
                      _c("span", {
                        staticClass: "destinationText",
                        domProps: {
                          innerHTML: _vm._s(_vm.getCountryName(dest)),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "removeIcon",
                          attrs: {
                            role: "button",
                            title: "Remove destination",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeDestination(index)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "xmark"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: _vm.question.name + "[]",
                        },
                        domProps: { value: dest },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "textRow" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.destination,
              expression: "destination",
            },
          ],
          staticClass: "no-chosen",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.destination = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [
            _vm._v("Select destination"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.countries, function (option, i) {
            return [
              _c("option", {
                key: "travel_destination_option_" + i,
                domProps: {
                  value: option.value,
                  innerHTML: _vm._s(option.title),
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    this.destinations.length < 14
      ? _c("div", { staticClass: "buttonWrap" }, [
          _c(
            "button",
            {
              staticClass: "button buttonText addButton",
              attrs: { type: "button", title: "Add user" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addDestination()
                },
              },
            },
            [
              _c("span", { staticClass: "svgIcon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "10",
                      height: "10",
                      viewBox: "0 0 10 10",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        y: "4",
                        width: "10",
                        height: "2",
                        rx: "1",
                        fill: "currentColor",
                      },
                    }),
                    _vm._v(" "),
                    _c("rect", {
                      attrs: {
                        x: "6",
                        width: "10",
                        height: "2",
                        rx: "1",
                        transform: "rotate(90 6 0)",
                        fill: "currentColor",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "buttonText" }, [
                _vm._v("\n                add destination\n            "),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }