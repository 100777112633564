var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "miniBasket" }, [
    _c(
      "a",
      {
        staticClass: "basketButton",
        attrs: { href: _vm.basketUrl, title: "View basket" },
        on: {
          click: function ($event) {
            return _vm.openMiniBasket($event)
          },
        },
      },
      [
        _c("span", { staticClass: "visuallyHidden" }, [_vm._v("View Basket")]),
        _vm._v(" "),
        _c("i", { staticClass: "svgIcon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "18",
                height: "15",
                viewBox: "0 0 18 15",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "Vector",
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M17.9743 2.80963L16.0436 9.55986C15.936 9.96144 15.5233 10.1996 15.1215 10.092L15.0555 10.0743C15.7551 10.5637 16.2139 11.3741 16.2139 12.2927C16.2139 13.7878 15.0016 14.9998 13.5063 14.9998C12.011 14.9998 10.7989 13.7879 10.7989 12.2927C10.7989 11.4439 11.1903 10.6869 11.8017 10.1904H8.01797C8.62936 10.6868 9.02076 11.4438 9.02076 12.2927C9.02076 13.7878 7.80871 15 6.31334 15C4.81778 15 3.60591 13.7881 3.60591 12.2927C3.60591 11.4439 3.99731 10.6869 4.60871 10.1904H4.37366C4.16415 10.1904 3.97527 10.1035 3.83967 9.96441C3.70056 9.82881 3.61366 9.63997 3.61366 9.43048L3.61358 3.16453C3.61358 3.12706 3.62179 3.0921 3.6278 3.05641C3.60935 2.99097 3.59634 2.92334 3.59634 2.85213V2.55249C3.59634 2.49404 3.60431 2.43787 3.61707 2.3834C3.60512 2.06302 3.34333 1.80632 3.01991 1.80632H2.28436C2.27688 1.80656 2.26989 1.80859 2.26241 1.80859H0.754574C0.33797 1.80859 0 1.47075 0 1.05411V0.754478C0 0.337928 0.337887 0 0.754574 0H2.26241C2.26688 0 2.27086 0.00121939 2.27517 0.00121939H3.91058C4.73833 0.00121939 5.40915 0.671955 5.40915 1.4996V1.79803H5.42639V3.60654H5.40663V8.39846H14.499L15.8695 3.60654L7.82358 3.60662V3.59516C7.81512 3.5954 7.80732 3.59768 7.79886 3.59768C7.30259 3.59768 6.90047 3.19536 6.90047 2.69915C6.90047 2.20293 7.30284 1.80062 7.79886 1.80062C7.80732 1.80062 7.81512 1.80289 7.82358 1.80314V1.79818H16.8275C16.9326 1.77948 17.0428 1.78094 17.1527 1.81045L17.4422 1.88784C17.8438 1.99531 18.0818 2.40787 17.9741 2.80971L17.9743 2.80963ZM6.31311 11.3943C5.81683 11.3943 5.41446 11.7967 5.41446 12.2929C5.41446 12.7891 5.81683 13.1914 6.31311 13.1914C6.80939 13.1914 7.21151 12.7891 7.21151 12.2929C7.21151 11.7967 6.80947 11.3943 6.31311 11.3943ZM13.5065 11.3943C13.0102 11.3943 12.6078 11.7967 12.6078 12.2929C12.6078 12.7891 13.0102 13.1914 13.5065 13.1914C14.0028 13.1914 14.4049 12.7891 14.4049 12.2929C14.4049 11.7967 14.0025 11.3943 13.5065 11.3943Z",
                  fill: "url(#paint0_linear_148_11603)",
                },
              }),
              _vm._v(" "),
              _c(
                "defs",
                [
                  _c(
                    "linearGradient",
                    {
                      attrs: {
                        id: "paint0_linear_148_11603",
                        x1: "0",
                        y1: "0",
                        x2: "8.92446",
                        y2: "19.2151",
                        gradientUnits: "userSpaceOnUse",
                      },
                    },
                    [
                      _c("stop", { attrs: { "stop-color": "#444C4E" } }),
                      _vm._v(" "),
                      _c("stop", {
                        attrs: { offset: "1", "stop-color": "#313638" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.itemCount > 0
          ? _c("span", {
              staticClass: "totalQty",
              domProps: { innerHTML: _vm._s(_vm.itemCount) },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.minibasketEnabled
      ? _c(
          "div",
          {
            staticClass: "miniBasketFlyout",
            class: [_vm.open ? "isOpen" : ""],
          },
          [
            _c("div", { staticClass: "innerMinibasket" }, [
              _c("header", { staticClass: "minibasketHeader" }, [
                _c("h3", [_vm._v("Basket")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button closeButton",
                    attrs: {
                      type: "button",
                      "aria-label": "Close mini basket",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeMiniBasket()
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "svgIcon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-xmark"] },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "minibasketContent" }, [
                _vm.basket.items.length
                  ? _c(
                      "ul",
                      { staticClass: "miniBasketItems" },
                      [
                        _vm._l(
                          _vm.basket.items,
                          function (product, productKey) {
                            return [
                              _c("li", { key: product.id }, [
                                _c("div", { staticClass: "basketCard" }, [
                                  _c("div", { staticClass: "cardImage" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "imageWrap",
                                        attrs: {
                                          href: product.item_url,
                                          title: "View " + product.item_name,
                                        },
                                      },
                                      [
                                        product.item_image.length
                                          ? _c("img", {
                                              attrs: {
                                                src: product.item_image,
                                                width: "100",
                                                height: "100",
                                                alt:
                                                  "Image of:" +
                                                  product.item_name,
                                              },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: "/sites/default/images/no-image-items-thumb.png",
                                                width: "100",
                                                height: "100",
                                                alt: "No image",
                                              },
                                            }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cardContent" }, [
                                    product.item_name
                                      ? _c("h4", {
                                          staticClass: "productTitle",
                                          domProps: {
                                            textContent: _vm._s(
                                              product.item_name
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    product.size
                                      ? _c(
                                          "div",
                                          { staticClass: "productSize" },
                                          [
                                            _c("span", {
                                              staticClass: "sizeValue",
                                              domProps: {
                                                innerHTML: _vm._s(product.size),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "quantityWrap" }, [
                                      _c(
                                        "span",
                                        { staticClass: "productQtyLabel" },
                                        [_vm._v("Quantity: ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "productQty",
                                          domProps: {
                                            innerHTML: _vm._s(product.quantity),
                                          },
                                        },
                                        [_vm._v("1")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cardRight" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button deleteButton",
                                        attrs: {
                                          type: "button",
                                          "aria-label":
                                            "Remove" +
                                            product.item_name +
                                            " from basket",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeItem(productKey)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "svgIcon" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "trash"] },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "productPrice",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formatPrice(
                                            product.item_price,
                                            product.sale_price
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "noItemsBasket" }, [
                      _c("h5", [_vm._v("Your basket is currently empty")]),
                    ]),
              ]),
              _vm._v(" "),
              _vm.basket.items.length
                ? _c("footer", { staticClass: "minibasketFooter" }, [
                    _c("div", { staticClass: "basketFooterTop" }, [
                      _c("span", { staticClass: "footerLabel" }, [
                        _vm._v("Total:"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "footerTotal",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPrice(_vm.basket.cost_subtotal)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "basketFooterBottom" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button",
                          attrs: {
                            href: _vm.basketUrl,
                            title: "Continue to checkout",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Checkout\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "loadingBox",
                class: [_vm.loading ? "isLoading" : ""],
              },
              [
                _c("loading-spinner", {
                  attrs: {
                    loading: _vm.loading,
                    "loading-text": "Updating Basket",
                    "show-loading-text": true,
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }