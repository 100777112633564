<template>

    <div class="travelUsersWrap">


        <div class="travelUsers" v-if="userList.length">

            <ul class="userList">

                <template v-for="(user, index) in userList">

                    <li class="userRow" :key="'travel_user_' + index">

                        <span v-html="user" class="userNameText"></span>

                        <span
                                class="removeIcon"
                                role="button"
                                title="Remove user"
                                @click.prevent="removeUser(index)"
                        >
                            <font-awesome-icon :icon="['far', 'xmark']" />
                        </span>

                        <input
                                type="hidden"
                                :name="question.name + '[]'"
                                :value="user"
                        />

                    </li> <!-- .userRow -->

                </template>

            </ul>

        </div> <!-- .currentTravelUsers -->
        
        <div class="textRow">

            <input type="text"
                   :name="question.name"
                   :id="'travel_q_id_' + question.id"
                   v-model="userName"
                   :placeholder="placeholder"
                   autocomplete="off"
            />
            
        </div> <!-- .row -->

        <div class="travelUserButtonWrap" v-if="this.users.length < 8">
            <button 
                    type="button" 
                    class="button buttonText addUserButton" 
                    title="Add user"
                    @click.prevent="addUser()"
            >
                <span class="svgIcon">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="4" width="10" height="2" rx="1" fill="currentColor"/>
                        <rect x="6" width="10" height="2" rx="1" transform="rotate(90 6 0)" fill="currentColor"/>
                    </svg>
                </span>
                <span class="buttonText">
                    add traveller
                </span>
            </button>
        </div> <!-- .travelUserButtonWrap -->
        
        
    </div> <!-- .travelUsers -->
   
    
</template>

<script>

import _ from 'lodash';

export default {
    name: "travelUsers",
    components: {
       
    },
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        placeholder: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            userName: '',
            users: [],
            usernameError: false
        }
    },
    computed: {
        userList() {
            return this.users;
        }
    },
    created() {
        this.users = this.question.answer;
        
        if(this.users.length) {
            this.updateQuestion(this.question);
        }
    },
    methods: {

        addUser() {

            this.question.can_proceed = false;
            this.usernameError = true;
            
            if(this.userName.length) {
                let name = this.userName.trim();
                
                if(this.userNameIsValid(name)) {
                    this.users.push(name);
                    this.userName = '';
                    this.usernameError = false
                    this.question.can_proceed = true;
                }
            }
            this.updateQuestion(this.question);
            
        },
        
        removeUser(index) {
            this.users = this.users.filter((user, i) => i != index );
            this.updateQuestion(this.question);
        },
        
        updateQuestion: _.debounce(function(question, inputEvent) {

            question = this.validate(question);
            this.$emit('update-question', question);
            
        }, 200),
        
        validate(question) {

            let canProceed = true;
            
            if (this.users.length && !this.usernameError) {
                canProceed = true;
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }
            
            question.can_proceed = canProceed;
            
            return question;
            
        },
        
        userNameIsValid(name) {

            let nameRegEx = /^[a-zA-Z]{1,30} [a-zA-Z]{1,30}(.*?)?$/;
            return nameRegEx.test(name);
            
        },

        externallyValidate() {
            let question = this.question;
            
            if(this.userName.length) {
                this.addUser();
            }
            
            question = this.validate(question);
            question.externalUpdate = true;
            this.$emit('update-question', question);
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .travelUsersWrap {

        
        .travelUsers {
            padding: 0 0 15px;
        }

        .userList {

            margin: 0;
            padding: 0;

            &:before, &:after {
                display: none;
            }

            & > li {
                list-style: none;
                display: flex;
                padding: 7px 0 7px 20px;
                border: 1.5px solid rgba(102, 102, 102, 0.5);;
                border-radius: 5px;
                margin: 0 0 15px;
                position: relative;

                &:last-child {
                    margin: 0;
                }
            }

            .userNameText {
                flex: 1 1 100%;
                padding: 0 56px 0 0;
            }

            .removeIcon {
                flex: 0 0 48px;
                width: 48px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                color: @text_color;
                transition: color 200ms linear;
                cursor: pointer;
                display: block;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    color: @alert_color;
                    transition: color 200ms linear;
                }
            }
        }
        

        .textRow {
            input {
                @media @mobile {
                    text-align: center !important;
                }
            }
        }
        
        .travelUserButtonWrap {
            padding: 15px 0 0;
        }
        
        .addUserButton {
            letter-spacing: 0.1em;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;
            overflow: visible;
            width: 100%;
            text-align: left;
            
            @media @mobile {
                text-align: center;
                display: flex;
                justify-content: center;
            }
            
            &:before, &:after {
                display: none;
            }
            
            
            .svgIcon {
                flex: 0 0 12px;
                padding: 0;
                position: relative;
                top: -1px;
                
                svg {
                    position: relative;
                    width: 10px;
                    height: 10px;
                    margin-top: 0;
                }
            }
            
            .buttonText {
                flex: 1 1 100%;
                padding-left: 8px;
                text-align: left;

                @media @mobile {
                    flex: 0 0 auto;
                }
            }
        }
        
    }
    

</style>
