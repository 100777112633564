/**
 * Slideshow
 */

function slideShow() {
    'use strict';

    if ($('.sliderWrap ul li').length > 1) {

        var autoplaySpeed = 4000,
            speed = (matchesMediaQuery('mobile', 0)) ? 1200 : 350;

        $('.slider').slick({
            dots: true,
            infinite: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: autoplaySpeed,
            speed: speed,
            touchThreshold: 65,
            touchMove: true,
            cssEase: 'ease-in-out',
            responsive: [
                {
                    breakpoint: window.breakpoints.mobile,
                    settings: {
                        arrows: false,
                        speed: speed
                    }
                }
            ]
        });
    }
}
