var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "priceRangeSlider" }, [
    _c("div", { staticClass: "priceRange" }, [
      _c("div", { staticClass: "ranges" }, [
        _c("div", { staticClass: "row" }, [
          _c("label", { attrs: { for: "minPrice" } }, [_vm._v("From")]),
          _vm._v(" "),
          _c("div", { staticClass: "innerInput" }, [
            _vm.currencySymbol
              ? _c("span", {
                  staticClass: "currencySymbol",
                  domProps: { innerHTML: _vm._s(_vm.currencySymbol) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.prices.price_min,
                  expression: "prices.price_min",
                },
              ],
              attrs: {
                id: "minPrice",
                type: "number",
                step: "1.00",
                min: 0,
                max: _vm.prices.price_max - 1,
              },
              domProps: { value: _vm.prices.price_min },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.prices, "price_min", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("label", { attrs: { for: "maxPrice" } }, [_vm._v("To")]),
          _vm._v(" "),
          _c("div", { staticClass: "innerInput" }, [
            _vm.currencySymbol
              ? _c("span", {
                  staticClass: "currencySymbol",
                  domProps: { innerHTML: _vm._s(_vm.currencySymbol) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.prices.price_max,
                  expression: "prices.price_max",
                },
              ],
              attrs: {
                id: "maxPrice",
                type: "number",
                step: "1.00",
                min: _vm.prices.price_min + 1,
                max: 9999999,
              },
              domProps: { value: _vm.prices.price_max },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.prices, "price_max", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttonWrap" }, [
        _c(
          "button",
          {
            staticClass: "button buttonOutlined",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.updateAjax(_vm.priceURL)
              },
            },
          },
          [_vm._v("\n                Update Prices\n            ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }