var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasBenefits(_vm.orders)
    ? _c(
        "section",
        { staticClass: "ordersSlider" },
        [
          _c(
            "Splide",
            { ref: "ordersSlider", attrs: { options: _vm.sliderOptions } },
            _vm._l(_vm.orders, function (order, index) {
              return _c("SplideSlide", { key: "order" + index }, [
                _c("div", { staticClass: "orderSlideWrap" }, [
                  _c("div", { staticClass: "slideHeader" }, [
                    _c("div", { staticClass: "slideDate" }, [
                      _c("span", { staticClass: "headTitle" }, [
                        _vm._v("Order Placed"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "headValue",
                        domProps: { innerHTML: _vm._s(order.date) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "slidePrice" }, [
                      _c("span", { staticClass: "headTitle" }, [
                        _vm._v("Total"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "headValue" },
                        [
                          _c("multi-currency-price", {
                            attrs: { price: order.cost_total },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "slideButton" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button buttonOutlined",
                          attrs: {
                            href: "/members/view_order.php?id=" + order.id,
                          },
                        },
                        [
                          _vm._v(
                            "\n                            View order\n                            "
                          ),
                          _c("i", { staticClass: "svgIcon" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 10 9",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "slideBody" },
                    _vm._l(order.items.slice(0, 3), function (item, index) {
                      return _c(
                        "div",
                        { key: "item" + index, staticClass: "slideItem" },
                        [
                          _c("div", { staticClass: "imageWrap" }, [
                            item.image !== null && item.image !== ""
                              ? _c("img", {
                                  attrs: {
                                    src: item.image,
                                    alt: item.item_name,
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: "/sites/default/images/no-image.jpg",
                                    alt: item.item_name,
                                  },
                                }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "itemDetails" }, [
                            _c("div", {
                              staticClass: "itemTitle",
                              domProps: {
                                innerHTML: _vm._s("Product: " + item.item_name),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "itemPrice" },
                            [
                              _c("span", {
                                staticClass: "itemQty",
                                domProps: {
                                  innerHTML: _vm._s("Qty: " + item.quantity),
                                },
                              }),
                              _vm._v(" "),
                              _c("multi-currency-price", {
                                attrs: { price: item.price_paid },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }