var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title.length
    ? _c("div", { staticClass: "sidebarCard" }, [
        _c("div", { staticClass: "innerBox" }, [
          _vm.image.src.length
            ? _c("div", { staticClass: "imageBox" }, [
                _c("div", { staticClass: "imageWrap" }, [
                  _c("img", {
                    attrs: { src: _vm.image.src, alt: _vm.image.alt },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "contentBox" }, [
            _c("h1", { staticClass: "cardTitle" }, [
              _c("div", { staticClass: "titleSub" }, [
                _vm._v("Consultation for:"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "titlePrimary",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }