<template>

    <input type="number"
           :name="questionName"
           :id="'id_' + question.id"
           v-model="question.answer"
           @blur="updateQuestion(question)"
           @input="updateQuestion(question, true)"
           autocomplete="off"
    >
    
</template>

<script>

import _ from 'lodash';

export default {
    name: "numberField",
    components: {
       
    },
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    computed: {
        questionName () {
            if (this.multiuserId) {
                return 'questions[' + this.multiuserId + '][' + this.question.original_id + ']';
            }
            return 'questions[' + this.question.id + ']';
        },
    },
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true,
                    number = parseInt(question.answer);

            if(isNaN(number) && question.is_required) {
                canProceed = false;
            }
            
            question.can_proceed = canProceed;

            return question;

        },

        externallyValidate() {
            let question = this.question;
            question = this.validate(question);
            question.externalUpdate = true;
            this.$emit('update-question', question);
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    

</style>
