(function($) {
    'use strict';
    // Vue delimiters as [% %] due to conflict with Twig template tags
    Vue.config.delimiters = ['[%', '%]'];
    Vue.config.unsafeDelimiters = ['[%%', '%%]'];

    /**
     * Vue integration with the mtc overlay plugin
     * Usage v-overlay
     */
    Vue.directive('overlay', {
        bind: function () {
            var el = $(this.el);
            el.mtcOverlay();
            var overlayClass = 'standardOverlay';

            if(el.hasClass('transparentOverlay')) {
                overlayClass = 'transparentOverlay';
            }

            el.mtcOverlay({
                content_class: overlayClass
            });
        }
    });


    /**
     * Vue integration with tinyMCE
     * usage v-tinymce="target_field"
     */
    Vue.directive('tinymce', {
        twoWay: true,
        deep: true,
        bind: function () {
            var selector = $(this.el),
                handle = this;
            tinymce.init({
                selector: '#' + selector.attr('id'),
                theme: "modern",
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste "
                ],
                menubar: false,
                statusbar: false,
                convert_fonts_to_spans: true,
                paste_as_text: true,
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                body_class: "wysiwygContent",
                width: 476,
                height: 150,
                resize: "both",
                advlist_bullet_styles: 'Default',
                advlist_number_styles: 'Default',
                toolbar: "bold italic | bullist numlist | link unlink",
                contextmenu: "link image inserttable | cell row column deletetable code",
                setup: function (editor) {

                    editor.on('change', function (event) {
                        handle.set(event.level.content);
                    });
                }
            });
        }
    });

    /**
     * Vue integration for date picker.
     * Adds date picker functionality for input field
     * usage:
     * <input v-model="field"
     *        v-date-picker/>
     */
    Vue.directive('date-picker', {
        twoWay: true,
        deep: true,
        bind: function () {
            var handle = this,
                min_date = false;
            if ($(handle.el).data('mindate') !== 'undefined') {
                min_date = $(handle.el).data('mindate');
            }
            $(this.el).datepicker({
                showButtonPanel: true,
                dateFormat: 'dd/mm/yy',
                minDate: min_date
            });
        }
    });

    /**
     * Vue integration for number arrows.
     * Adds number arrows to quantity tel inputs
     * usage:
     * <input v-model="quantity"
     *        v-number-arrows/>
     */
    Vue.directive('number-arrows', {
        twoWay: true,
        deep: true,
        bind: function () {
            $(this.el).mtcNumberArrows();
        }
    });
}(jQuery));
