<template>

    <div class="datepickerWrap">

        <datepicker
                :name="questionName"
                :id="'id_' + question.id"
                v-model="question.answer"
                :data-question-id="question.id"
                @input="updateQuestion(question)"
                autocomplete="off"
        ></datepicker>


    </div><!-- .datepickerWrap -->
    
</template>

<script>

import _ from 'lodash';

export default {
    name: "dateField",
    components: {
       
    },
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        multiuserId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    computed: {
        questionName() {
            if(this.multiuserId) {
                return 'questions[' + this.multiuserId + '][' + this.question.original_id + ']';
            }
            return 'questions[' + this.question.id + ']';
        }
    },
    methods: {

        updateQuestion: _.debounce(function(question, inputEvent) {

            if(typeof inputEvent == 'undefined' || this.allowInputEvent) {
                question = this.validate(question);
                this.$emit('update-question', question);
            }

        }, 200),

        validate(question) {

            let canProceed = true;

            if (question.answer.length) {
                
                let answer = question.answer.trim();
                let re = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                canProceed = re.test(answer);
                
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }

            question.can_proceed = canProceed;

            return question;

        },

        externallyValidate() {
            let question = this.question;
            question = this.validate(question);
            question.externalUpdate = true;
            this.$emit('update-question', question);
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    

</style>
