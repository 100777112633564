<template>

    <section 
            class="collapseArea" 
            :class="[hasError ? 'withError' : '', disabled ? 'inactive' : '']" 
            :id="sectionId"
    >

        <h4 
                class="listSectionTitle" 
                @click.prevent="collapse()" 
                :class="[!sectionOpen ? 'collapsed' : '']"
        >
            <span 
                    class="titleText" 
                    v-html="title"
            ></span>

            <div class="toggleIcon" v-if="!disabled">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="currentColor"/>
                    <rect x="8" y="14" width="2" height="14" rx="1" transform="rotate(-180 8 14)" fill="currentColor" v-if="!sectionOpen"/>
                </svg>
            </div> <!-- .toggleIcon -->
        </h4>

        <div class="listSectionBody">

            <div class="innerListArea">
                
                <slot></slot>
                
            </div> <!-- .innerListArea -->

        </div>

    </section>

</template>

<script>
export default {
    name: 'collapseArea',
    props: {
        sectionId: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        open: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        disabled: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        title: {
            type: [String],
            default (rawProps) {
                return 'Section';
            }
        },
        hasError: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            sectionOpen: false
        }
    },
    mounted() {
        this.sectionOpen = this.open;
    },
    methods: {
        collapse() {
            if(this.disabled) {
                return false;
            }
            
            this.sectionOpen = !this.sectionOpen;

            this.validateChildQuestions(this.sectionOpen);
   
        },
        
        validateChildQuestions(sectionOpen) {

            if(!sectionOpen) {
                for (const prop in this.$children) {
                    if (!_.isUndefined(this.$children[prop].externalValidation)) {
                        this.$children[prop].externalValidation();
                    }
                }
            }
            
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .collapseArea {
        display: block;
        width: 100%;
        margin: 0 0 25px;
        
        .innerListArea {
            padding: 35px;
            
            @media @mobile {
                padding: 25px 15px;
            }
        }

        .listSectionTitle {
            font-style: normal;
            font-weight: 700;
            .rems(18);
            line-height: 1.2em;
            padding: 16px 25px;
            background: @assessment_dark_color;
            border-radius: 10px 10px 0 0;
            margin: 0;
            width: 100%;
            color: #fff;
            display: flex;
            cursor: pointer;
            transition: background 200ms linear;

            @media @mobile {
                align-items: center;
            }

            &:hover, &:active, &:focus {
                background: darken(@assessment_dark_color, 8%);
                transition: background 200ms linear;
            }

            .titleText {
                flex: 1 1 100%;
                padding-right: 12px;
                
                @media @mobile {
                    text-align: left;
                }
            }

            .toggleIcon {
                width: 22.5px;
                height: 22.5px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-left: 8px;

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            &.collapsed {
                


                & + .listSectionBody {
                    height: auto;
                    overflow: hidden;
                }

                &.collapsed {

                    border-radius: 10px;

                    & + .listSectionBody {
                        height: 0;
                    }

                }



            }
        }

        .listSectionBody {
            border-radius: 0 0 10px 10px;
            background: #fff;
            width: 100%;
        }

        &.inactive {

            .listSectionTitle {
                background: @assessment_dark_color !important;
                cursor: default;
            }
        }
        
        &.withError {

            .listSectionTitle {
                background: @alert_color !important;
            }
            
        }
        
    }
    

</style>
