<template>

    <div class="sidebarCard" v-if="title.length">

        <div class="innerBox">
            
            <div class="imageBox" v-if="image.src.length">
    
                <div class="imageWrap">
                    <img 
                            :src="image.src" 
                            :alt="image.alt"
                    >
                </div> <!-- .imageWrap -->
                
            </div> <!-- .imageBox -->
    
            <div class="contentBox">
                
                <h1 class="cardTitle">
                    
                    <div class="titleSub">Consultation for:</div>
                    <div class="titlePrimary" v-html="title"></div>
                    
                </h1>
                
            </div> <!-- .contentBox -->

        </div> <!-- .innerBox -->
        
    </div> <!-- .sidebarCard -->
    
</template>

<script>


export default {
    name: 'sidebarCard',
    props: {
        image: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    src: '',
                    alt: ''
                };
            }
        },
        title: {
            type: [String],
            default (rawProps) {
                return '';
            }
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .sidebarCard {
        
        display: block;
        width: 100%;
        margin: 0 0 50px;
        
        @media all and (max-width: 1020px) {
            margin: 0 0 25px;
        }
        
        .innerBox {
            display: flex;
            background: @assessment_dark_color;
            border-radius: 10px;
            width: 100%;
            overflow: hidden;
        }
        
        .imageBox {
            flex: 0 0 100px;
        }
        
        .contentBox {
            flex: 1 1 100%;
            padding: 12px 30px;
            min-height: 100px;
            display: flex;
            align-items: center;
        }
        
        .imageWrap {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .cardTitle {
            font-style: normal;
            color: #fff;
            margin: 0;
            text-align: left;

            .titleSub {
                color: inherit;
                font-weight: inherit;
                .rems(14);
                line-height: 1.2em;
                letter-spacing: 0.1em;
                margin: 0 0 3px;
            }

            .titlePrimary {
                color: inherit;
                font-weight: 700;
                .rems(18);
                line-height: 1.2em;
            }
        }
        
        
    }

</style>
