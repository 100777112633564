$(document).ready(function () {

    'use strict';

    /*
     * Cross sell tabs
     */

    if ($('.js_crossSellTabs').length) {

        $('.js_crossSellTabs').mtcTabs({
            onChange: function () {

                if ($('.slick-initialized').length) {
                    $('.slick-initialized').slick('setPosition');
                }

            },
            responsive: [
                {
                    breakpoint: (window.breakpoints.mobile + 1),
                    settings: {
                        mode: 'accordion',
                        onChange: null,
                        afterChange: function () {

                            if ($('.slick-initialized').length) {
                                $('.slick-initialized').slick('setPosition');
                            }

                        },
                    }
                }
            ]
        });

    }

}); // document ready
