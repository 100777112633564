$(document).ready(function () {

    'use strict';

    if($('.js_mobileNav').length) {
        showHideMenus('.js_mobileNav .sidebarTitle', window.breakpoints.flyout);
    }

    if($('ul.faqs').length) {
        
        $('ul.faqs').each(function () {

            var $list = $(this);

            if ($list.hasClass('faqClosed')) {
                $list.find('li .answer').hide();
            } else {
                $list.find('li .answer:not(:first)').hide();
                $list.find('li:first h4').addClass('open');
            }

        });

        $('html').on('click', 'ul.faqs>li>h4', function(e) {
            var $btn = $(this);
            $btn.toggleClass('open').next('.answer').stop(true,true).slideToggle();
        });
        
    }
    

    if($('.faqSelector').length) {
        
        $('.faqSelector .option1').click(function () {
            $('.faqSelector .option2').removeClass('open');
            $('.faqSelector .option3').removeClass('open');
            $('.faqSelector .option4').removeClass('open');
            $('.faqs').hide();
            $('.faqOption1').show();
            $(this).addClass('open');
            $('.faqTitles').hide();
            $('.faqTitles.optionTitle1').show();
        });

        $('.faqSelector .option2').click(function () {
            $('.faqSelector .option1').removeClass('open');
            $('.faqSelector .option3').removeClass('open');
            $('.faqSelector .option4').removeClass('open');
            $('.faqs').hide();
            $('.faqOption2').show();
            $(this).addClass('open');
            $('.faqTitles').hide();
            $('.faqTitles.optionTitle2').show();
        });

        $('.faqSelector .option3').click(function () {
            $('.faqSelector .option1').removeClass('open');
            $('.faqSelector .option2').removeClass('open');
            $('.faqSelector .option4').removeClass('open');
            $('.faqs').hide();
            $('.faqOption3').show();
            $(this).addClass('open');
            $('.faqTitles').hide();
            $('.faqTitles.optionTitle3').show();
        });

        $('.faqSelector .option4').click(function () {
            $('.faqSelector .option1').removeClass('open');
            $('.faqSelector .option2').removeClass('open');
            $('.faqSelector .option3').removeClass('open');
            $('.faqs').hide();
            $('.faqOption4').show();
            $(this).addClass('open');
            $('.faqTitles').hide();
            $('.faqTitles.optionTitle4').show();
        });
        
    }

    if($('.js_faqsCatTitle').length) {
        showHideMenus('.js_faqsCatTitle', window.breakpoints.mobile);
    }

}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';

    if($('.js_faqsCatTitle').length) {
        showHideMenus('.js_faqsCatTitle', window.breakpoints.mobile);
    }

    if($('.js_mobileNav').length) {
        showHideMenus('.js_mobileNav .sidebarTitle', window.breakpoints.flyout);
    }

})); // debounce
