<template>

    <div class="datePickerBoxes">

        <label class="dateBox dateFrom" :for="'travel_departure_date_id_' + question.id">
            
            <h4>Departure date</h4>

            <div class="valueBox">

                <div class="icon">
                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7082 1.20267H15.7684V0.824053C15.7684 0.378619 15.412 0 14.9443 0C14.4766 0 14.1425 0.378619 14.1425 0.824053V1.20267H7.01559V0.824053C7.01559 0.378619 6.65924 0 6.19154 0C5.72383 0 5.36748 0.356348 5.36748 0.824053V1.20267H2.44989C1.11359 1.20267 0 2.29399 0 3.65256V17.5501C0 18.8864 1.09131 20 2.44989 20H18.7305C20.0668 20 21.1804 18.9087 21.1804 17.5501V3.65256C21.1581 2.31626 20.0668 1.20267 18.7082 1.20267ZM2.44989 2.85078H5.38975V3.2294C5.38975 3.67483 5.7461 4.05345 6.21381 4.05345C6.68151 4.05345 7.03786 3.6971 7.03786 3.2294V2.85078H14.1648V3.2294C14.1648 3.67483 14.5212 4.05345 14.9889 4.05345C15.4566 4.05345 15.8129 3.6971 15.8129 3.2294V2.85078H18.7528C19.1982 2.85078 19.5768 3.20713 19.5768 3.67483V5.32294H1.62584V3.67483C1.62584 3.20713 1.98218 2.85078 2.44989 2.85078ZM18.7082 18.3519H2.44989C2.00445 18.3519 1.62584 17.9955 1.62584 17.5278V6.9265H19.5323V17.5501C19.5323 17.9955 19.1759 18.3519 18.7082 18.3519Z" fill="currentColor"/>
                        <path d="M6.25901 8.57458H4.12093C3.67549 8.57458 3.29688 8.93093 3.29688 9.39864C3.29688 9.84407 3.65322 10.2227 4.12093 10.2227H6.28129C6.72672 10.2227 7.10534 9.86634 7.10534 9.39864C7.08307 8.93093 6.72672 8.57458 6.25901 8.57458Z" fill="currentColor"/>
                        <path d="M11.6485 8.57458H9.48812C9.04268 8.57458 8.66406 8.93093 8.66406 9.39864C8.66406 9.84407 9.02041 10.2227 9.48812 10.2227H11.6485C12.0939 10.2227 12.4725 9.86634 12.4725 9.39864C12.4725 8.93093 12.1162 8.57458 11.6485 8.57458Z" fill="currentColor"/>
                        <path d="M17.0391 8.57458H14.8787C14.4333 8.57458 14.0547 8.93093 14.0547 9.39864C14.0547 9.84407 14.411 10.2227 14.8787 10.2227H17.0391C17.4845 10.2227 17.8632 9.86634 17.8632 9.39864C17.8632 8.93093 17.5068 8.57458 17.0391 8.57458Z" fill="currentColor"/>
                        <path d="M6.25901 11.8263H4.12093C3.67549 11.8263 3.29688 12.1826 3.29688 12.6503C3.29688 13.0958 3.65322 13.4744 4.12093 13.4744H6.28129C6.72672 13.4744 7.10534 13.1181 7.10534 12.6503C7.08307 12.2049 6.72672 11.8263 6.25901 11.8263Z" fill="currentColor"/>
                        <path d="M11.6485 11.8263H9.48812C9.04268 11.8263 8.66406 12.1826 8.66406 12.6503C8.66406 13.0958 9.02041 13.4744 9.48812 13.4744H11.6485C12.0939 13.4744 12.4725 13.1181 12.4725 12.6503C12.4725 12.2049 12.1162 11.8263 11.6485 11.8263Z" fill="currentColor"/>
                        <path d="M17.0391 11.8263H14.8787C14.4333 11.8263 14.0547 12.1826 14.0547 12.6503C14.0547 13.0958 14.411 13.4744 14.8787 13.4744H17.0391C17.4845 13.4744 17.8632 13.1181 17.8632 12.6503C17.8632 12.2049 17.5068 11.8263 17.0391 11.8263Z" fill="currentColor"/>
                        <path d="M6.25901 15.1002H4.12093C3.67549 15.1002 3.29688 15.4566 3.29688 15.9243C3.29688 16.392 3.65322 16.7483 4.12093 16.7483H6.28129C6.72672 16.7483 7.10534 16.392 7.10534 15.9243C7.10534 15.4566 6.72672 15.1002 6.25901 15.1002Z" fill="currentColor"/>
                        <path d="M11.6485 15.1002H9.48812C9.04268 15.1002 8.66406 15.4566 8.66406 15.9243C8.66406 16.392 9.02041 16.7483 9.48812 16.7483H11.6485C12.0939 16.7483 12.4725 16.392 12.4725 15.9243C12.4725 15.4566 12.1162 15.1002 11.6485 15.1002Z" fill="currentColor"/>
                        <path d="M17.0391 15.1002H14.8787C14.4333 15.1002 14.0547 15.4566 14.0547 15.9243C14.0547 16.392 14.411 16.7483 14.8787 16.7483H17.0391C17.4845 16.7483 17.8632 16.392 17.8632 15.9243C17.8632 15.4566 17.5068 15.1002 17.0391 15.1002Z" fill="currentColor"/>
                    </svg>
                </div> <!-- .icon -->

                <div class="text" v-html="departureDateText"></div> <!-- .text -->

            </div> <!-- .valueBox -->
            
            <input type="hidden" v-model="question.answer.departure_date" id="travel_departure_date">
            <datepicker
                    :name="question.name + '[departure_date]'"
                    :id="'travel_departure_date_id_' + question.id"
                    v-model="question.answer.departure_date"
                    :data-question-id="question.id"
                    @input="dateUpdated()"
            ></datepicker>
            
        </label> <!-- .dateFrom -->

        <label class="dateBox dateTo" :for="'travel_return_date_id_' + question.id">

            <h4>Return date</h4>

            <div class="valueBox">

                <div class="icon">
                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7082 1.20267H15.7684V0.824053C15.7684 0.378619 15.412 0 14.9443 0C14.4766 0 14.1425 0.378619 14.1425 0.824053V1.20267H7.01559V0.824053C7.01559 0.378619 6.65924 0 6.19154 0C5.72383 0 5.36748 0.356348 5.36748 0.824053V1.20267H2.44989C1.11359 1.20267 0 2.29399 0 3.65256V17.5501C0 18.8864 1.09131 20 2.44989 20H18.7305C20.0668 20 21.1804 18.9087 21.1804 17.5501V3.65256C21.1581 2.31626 20.0668 1.20267 18.7082 1.20267ZM2.44989 2.85078H5.38975V3.2294C5.38975 3.67483 5.7461 4.05345 6.21381 4.05345C6.68151 4.05345 7.03786 3.6971 7.03786 3.2294V2.85078H14.1648V3.2294C14.1648 3.67483 14.5212 4.05345 14.9889 4.05345C15.4566 4.05345 15.8129 3.6971 15.8129 3.2294V2.85078H18.7528C19.1982 2.85078 19.5768 3.20713 19.5768 3.67483V5.32294H1.62584V3.67483C1.62584 3.20713 1.98218 2.85078 2.44989 2.85078ZM18.7082 18.3519H2.44989C2.00445 18.3519 1.62584 17.9955 1.62584 17.5278V6.9265H19.5323V17.5501C19.5323 17.9955 19.1759 18.3519 18.7082 18.3519Z" fill="currentColor"/>
                        <path d="M6.25901 8.57458H4.12093C3.67549 8.57458 3.29688 8.93093 3.29688 9.39864C3.29688 9.84407 3.65322 10.2227 4.12093 10.2227H6.28129C6.72672 10.2227 7.10534 9.86634 7.10534 9.39864C7.08307 8.93093 6.72672 8.57458 6.25901 8.57458Z" fill="currentColor"/>
                        <path d="M11.6485 8.57458H9.48812C9.04268 8.57458 8.66406 8.93093 8.66406 9.39864C8.66406 9.84407 9.02041 10.2227 9.48812 10.2227H11.6485C12.0939 10.2227 12.4725 9.86634 12.4725 9.39864C12.4725 8.93093 12.1162 8.57458 11.6485 8.57458Z" fill="currentColor"/>
                        <path d="M17.0391 8.57458H14.8787C14.4333 8.57458 14.0547 8.93093 14.0547 9.39864C14.0547 9.84407 14.411 10.2227 14.8787 10.2227H17.0391C17.4845 10.2227 17.8632 9.86634 17.8632 9.39864C17.8632 8.93093 17.5068 8.57458 17.0391 8.57458Z" fill="currentColor"/>
                        <path d="M6.25901 11.8263H4.12093C3.67549 11.8263 3.29688 12.1826 3.29688 12.6503C3.29688 13.0958 3.65322 13.4744 4.12093 13.4744H6.28129C6.72672 13.4744 7.10534 13.1181 7.10534 12.6503C7.08307 12.2049 6.72672 11.8263 6.25901 11.8263Z" fill="currentColor"/>
                        <path d="M11.6485 11.8263H9.48812C9.04268 11.8263 8.66406 12.1826 8.66406 12.6503C8.66406 13.0958 9.02041 13.4744 9.48812 13.4744H11.6485C12.0939 13.4744 12.4725 13.1181 12.4725 12.6503C12.4725 12.2049 12.1162 11.8263 11.6485 11.8263Z" fill="currentColor"/>
                        <path d="M17.0391 11.8263H14.8787C14.4333 11.8263 14.0547 12.1826 14.0547 12.6503C14.0547 13.0958 14.411 13.4744 14.8787 13.4744H17.0391C17.4845 13.4744 17.8632 13.1181 17.8632 12.6503C17.8632 12.2049 17.5068 11.8263 17.0391 11.8263Z" fill="currentColor"/>
                        <path d="M6.25901 15.1002H4.12093C3.67549 15.1002 3.29688 15.4566 3.29688 15.9243C3.29688 16.392 3.65322 16.7483 4.12093 16.7483H6.28129C6.72672 16.7483 7.10534 16.392 7.10534 15.9243C7.10534 15.4566 6.72672 15.1002 6.25901 15.1002Z" fill="currentColor"/>
                        <path d="M11.6485 15.1002H9.48812C9.04268 15.1002 8.66406 15.4566 8.66406 15.9243C8.66406 16.392 9.02041 16.7483 9.48812 16.7483H11.6485C12.0939 16.7483 12.4725 16.392 12.4725 15.9243C12.4725 15.4566 12.1162 15.1002 11.6485 15.1002Z" fill="currentColor"/>
                        <path d="M17.0391 15.1002H14.8787C14.4333 15.1002 14.0547 15.4566 14.0547 15.9243C14.0547 16.392 14.411 16.7483 14.8787 16.7483H17.0391C17.4845 16.7483 17.8632 16.392 17.8632 15.9243C17.8632 15.4566 17.5068 15.1002 17.0391 15.1002Z" fill="currentColor"/>
                    </svg>
                </div> <!-- .icon -->

                <div class="text" v-html="returnDateText"></div> <!-- .text -->

            </div> <!-- .valueBox -->
            
            <datepicker
                    :name="question.name + '[return_date]'"
                    :id="'travel_return_date_id_' + question.id"
                    v-model="question.answer.return_date"
                    :data-question-id="question.id"
                    @input="dateUpdated()"
            ></datepicker>

        </label> <!-- .dateFrom -->


        <input 
                type="hidden"
                :name="question.name + '[trip_duration_days]'"
                v-model="tripDurationDays"
        />
        
    </div> <!-- .datePickerBoxes -->
    
</template>

<script>

import _ from 'lodash';
import moment from "moment";

export default {
    name: "travelDates",
    components: {

    },
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        placeholder: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        allowInputEvent: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            tripDurationDays: 0
        }
    },
    computed: {
        departureDateText() {
            return this.setDateText('departure_date');
        },
        returnDateText() {
            return this.setDateText('return_date');
        }
    },
    created() {
        
        if(this.question.answer?.departure_date?.length && this.question.answer?.return_date?.length) {
            this.dateUpdated();
        }
    },
    methods: {

        dateUpdated() {

            this.tripDurationDays = this.calculateTripDays();
     
            
            if(this.tripDurationDays > 0) {
                this.updateQuestion(this.question);
            }
            
        },
        
        calculateTripDays() {

            let departureDate = 0,
                    returnDate = 0;

            let departureDateSet = this.question.answer.departure_date;
            
            if(departureDateSet.length) {
                
                departureDate = moment(departureDateSet);

            }

            let returnDateSet = this.question.answer.return_date;

          
            if(returnDateSet.length) {

                returnDate = moment(returnDateSet);

            }

            


            if(departureDate && returnDate) {

                let duration = returnDate.add(1, 'd').diff(departureDate, 'days', true);
                
                if(duration > 0) {
                    return duration;
                }

            }

            return 0;
            
        },
        
        setDateText(key) {
            
            if(!_.isUndefined(this.question.answer[key])) {
                
                let answer = this.question.answer[key];

                if (answer.length) {
                    return this.formatDisplayDate(answer, 'ddd DD MMM YYYY');
                }
                
            }

            return 'Click to select';
        },
        
        formatDisplayDate(dateStr, format) {
            let date = moment(dateStr).format(format);
            return date;
        },
        
        updateQuestion: _.debounce(function(question, inputEvent) {

            question = this.validate(question);
            this.$emit('update-question', question);

        }, 200),

        validate(question) {

            let canProceed = true;

            if (this.tripDurationDays > 0) {
                canProceed = true;
            }else{
                if(question.is_required) {
                    canProceed = false;
                }
            }

            question.can_proceed = canProceed;

            return question;

        },

        externallyValidate() {
            let question = this.question;
            question = this.validate(question);
            question.externalUpdate = true;
            this.$emit('update-question', question);
        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .datePickerBoxes {
        
        display: flex;
        gap: 0 15px;

        @media @mobile {
            gap: 0 10px;
        }

        @media all and (max-width: 490px) {
            flex-wrap: wrap;
        }
        
        label.dateBox {

            position: relative;
            display: block;
            background: #fff;
            border: 1.5px solid @primary_color;
            border-radius: 5px;
            padding: 15px 20px;
            flex: 0 0 200px;
            cursor: pointer;
            transition: border-color 200ms linear;
            float: none;
            clear: both;
            margin: 0;

            @media @mobile {
                flex: 0 0 calc(50% - 5px);
            }
            
            @media all and (max-width: 490px) {
                flex: 0 0 100%;
                margin-bottom: 12px;
                
                &:last-of-type {
                    margin: 0;
                }
            }
            
            &:hover, &:active, &:focus {
                border-color: darken(@primary_color, 10%);
                transition: border-color 200ms linear;
            }
            
            & > h4 {
                font-weight: 700;
                .rems(14);
                line-height: 1em;
                letter-spacing: 0.1em;
                color: @text_color;
                margin: 0 0 10px;
                
                @media @mobile {
                    .rems(12);
                }
            }
            
            .valueBox {
                
                display: flex;
                color: @dark_color;

                @media @mobile {
                    justify-content: center;
                }
                
                .icon {
                    flex: 0 0 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    
                    svg {
                        height: 21.18px;
                        width: 20px;
                    }
                }
                
                .text {
                    padding: 0 0 0 8px;
                    flex: 1 1 100%;
                    font-style: normal;
                    font-weight: 700;
                    .rems(12);
                    line-height: 1em;
                    letter-spacing: 0.1em;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    white-space: nowrap;

                    @media @mobile {
                        flex: 0 0 auto;
                    }
                }
                
            }
            
            .datePicker {
                border: none !important;
                background: red !important;
                color: #fff !important;
                padding: 0 !important;
                margin: 0 !important;
                height: 0 !important;
                min-height: 0 !important;
                width: 100% !important;
                max-width: 100% !important;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        
    }

</style>
