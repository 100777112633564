$(document).ready(function () {

    'use strict';

    if($('.js_readMoreContent').length) {

        $('.js_readMoreContent').on('click', function(e){

            e.preventDefault();

            var target = $(this).attr('href'),
                openText = $(this).attr('data-open-text'),
                closedText = $(this).attr('data-closed-text');


            //Open/close read more
            var $target = $(target);

            if($target.length) {


                $(this).toggleClass('readMoreOpen');

                if($(this).hasClass('readMoreOpen')) {
                    $(this).text(openText);
                }else{
                    $(this).text(closedText);
                }

                $target.slideFadeToggle();
            }

        });
    }


}); // document ready

