var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title
    ? _c(
        "div",
        {
          staticClass: "titleBanner standard",
          class: _vm.cssClass,
          attrs: { id: "heroTitle" },
        },
        [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "innerTitleBanner" }, [
              _c("div", { staticClass: "bannerContentWrap" }, [
                _c("div", { staticClass: "innerContentWrapper" }, [
                  _c("div", { staticClass: "titleWrap" }, [
                    _c("div", { staticClass: "innerText" }, [
                      _c("h1", { domProps: { innerHTML: _vm._s(_vm.title) } }),
                      _vm._v(" "),
                      _vm.content
                        ? _c("div", {
                            staticClass: "titleContent wysiwyg",
                            domProps: { innerHTML: _vm._s(_vm.content) },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }