$(document).ready(function () {
    //stripe payment form
    $('#payWithStripe').on('click', function(event) {
        event.preventDefault();
        var $button = $(this),
            $form = $button.parents('form'),
            opts = $.extend({}, $button.data(), {
                token: function(result) {
                    $form.append($('<input>').attr({
                        type: 'hidden',
                        name: 'stripeToken',
                        value: result.id
                    })).submit();
                }
            });
        StripeCheckout.open(opts);
    });
});
