<template>

    <div class="productSizes" :class="{ visible: 'open'}">

        <template v-if="options.length > 1">
            <a class="toggleSizes" @click.prevent="visible = !visible" v-if="collapse">
                <span>Select Size</span>
                <i class="svgIcon">
                    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!visible">
                        <path d="M0 6C0 5.44772 0.447715 5 1 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H1C0.447715 7 0 6.55228 0 6Z" fill="#8497B5"/>
                        <path d="M6 -4.37114e-08C6.55228 -1.95703e-08 7 0.447715 7 1L7 11C7 11.5523 6.55228 12 6 12C5.44771 12 5 11.5523 5 11L5 1C5 0.447715 5.44772 -6.78525e-08 6 -4.37114e-08Z" fill="#8497B5"/>
                    </svg>
                    <svg viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                        <path d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z" fill="#8497B5"/>
                    </svg>
                </i>
            </a>
            <div class="multiSize" v-if="multiSelect === true" v-show="visible || !collapse">
                <div class="productSize" v-if="opts.packSize.length > 1">
                    <label for="sizePackSize">Pack Size:</label>
                    <div class="sizeSelect">
                        <chosen
                            :options="opts.packSize"
                            v-model="selected.packSize"
                            @input="setSelected()"
                            :name="'packSize'"
                            placeholder=""
                        ></chosen>
                    </div>
                </div>
                <div class="productSize" v-if="opts.strength.length">
                    <label for="sizeStrength">Strength:</label>
                    <div class="sizeSelect">
                        <chosen
                            :options="opts.strength"
                            v-model="selected.strength"
                            @input="setSelected()"
                            :name="'strength'"
                            placeholder=""
                        ></chosen>
                    </div>
                </div>
            </div>
            <div class="singleSize" v-else v-show="visible || !collapse">
                <div class="productSize">
                    <label for="sizeStrength">Size:</label>
                    <div class="sizeSelect">
                        <chosen
                            :options="options"
                            v-model="selectedID"
                            :name="'size'"
                            @input="triggerChange()"
                            label="display_value"
                            trackBy="id"
                            placeholder=""
                        ></chosen>
                    </div>
                </div>
            </div> <!-- .singleSize -->

            <input
                type="hidden"
                name="size"
                v-model="selectedID"
                @input="triggerChange()"
            >
        </template>

        <template v-else>
            <input
                    type="hidden"
                    name="size"
                    v-model="selectedID"
                    @input="triggerChange()"
            >
        </template>

    </div> <!-- .productSizes -->

</template>

<script>

import collect from "collect.js";

export default {
    name: 'productSizeSelect',
    props: {
        sizes: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        collapse: {
            type: Boolean,
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            selectedID: Number(0),
            multiSelect: false,
            selected: {
                strength: '',
                packSize: '',
            },
            opts: {
                strength: [],
                packSize: [],
            },
            visible: false,
        }
    },
    computed: {
        options() {
            return this.sortSizes();
        }
    },
    created() {
        this.setDefault();
        this.triggerChange();
        this.getPackSizes();
        this.getStrength();
        this.setSelected();
    },
    mounted() {
        this.setDefault();
        this.triggerChange();
        this.setSelected();
    },
    methods: {
        setDefault() {
            if(this.sizes) {
                if (this.sizes.length) {
                    const sizes = this.sortSizes();
                    this.selectedID = Number(sizes[0].id);
                }
            }
        },
        sortSizes() {
            const _this = this;

            let sizes = collect(this.sizes);

            sizes = sizes.map(size => {
                size.actual_price = _this.getPrice(size);
                size.display_price = _this.getCurrencySymbol() + size.actual_price;
                size.display_value = size.size + ' (' + size.display_price + ')';
                return size;
            });

            let sorted = sizes.sortBy('actual_price');

            return sorted.toArray();
        },
        getPrice(option) {

            let price = parseFloat(option.price),
                    salePrice = parseFloat(option.sale_price);

            let outputPrice = price;

            if(salePrice > 0 && salePrice < price) {
                outputPrice = salePrice;
            }

            return Number(outputPrice.toFixed(2));

        },
        getCurrencySymbol() {
            //TODO Extend this to get currency from a global object or cookie
            return '£';
        },
        triggerChange() {
            this.$emit('change', this.selectedID);
        },
        getPackSizes() {
            let sizes = this.sizes,
                strength = this.selected.strength;

            if(sizes.filter(e => e.pack_size !== "0").length === this.sizes.length) {
                if(strength !== '') {
                    this.opts.strength = [...new Set(sizes.filter(e => e.strength === strength).map(a => a.pack_size))];
                    if(!this.opts.packSize.includes(this.selected.packSize)) {
                        this.selected.packSize = this.opts.packSize[0];
                    }
                } else {
                    this.opts.packSize = [...new Set(sizes.map(a => a.pack_size))];
                    this.selected.packSize = this.opts.packSize[0];
                    this.multiSelect = true;
                }
            }
        },
        getStrength() {
            let sizes = this.sizes,
                packSize = this.selected.packSize;

            if(sizes.filter(e => e.strength !== '').length === this.sizes.length) {
                if(packSize !== "0") {
                    this.opts.strength = [...new Set(sizes.filter(e => e.pack_size === packSize).map(a => a.strength))];
                    if(!this.opts.strength.includes(this.selected.strength)) {
                        this.selected.strength = this.opts.strength[0];
                    }
                } else {
                    this.opts.strength = [...new Set(sizes.map(a => a.strength))];
                    this.selected.strength = this.opts.strength[0];
                    this.multiSelect = true;
                }
            }
        },
        setSelected() {
            if(this.multiSelect === true) {
                let sizes = this.sizes;
                this.getPackSizes();
                this.getStrength();

                if(this.selected.packSize !== '' && this.selected.strength !== '') {
                    this.selectedID = Number(sizes.filter(e => e.strength === this.selected.strength && e.pack_size === this.selected.packSize)[0].id);
                } else {
                    const sizes = this.sortSizes();
                    this.selected.packSize = sizes[0].pack_size;
                    this.selected.strength = sizes[0].strength;
                }

                this.triggerChange();
            }
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .productSizes {
        padding: 0 0 15px;

        .multiSize {
            display: flex;
            flex-flow: row nowrap;
            gap: 26px;

            .productSize {
                flex: 1 1;
            }
        }

        .productSize {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 11px;

            label {
                flex: 0 0;
                white-space: nowrap;
                .rems(10);
                line-height: 12px;
                letter-spacing: 0.2px;
                font-weight: 400;
                margin: 0;
            }

            .sizeSelect {
                flex: 1 1;

                .chosen-container {
                    .chosen-single {
                        border: 2px solid #ffffff;

                        span {
                            overflow: unset;
                            .rems(12);
                            line-height: 14px;
                            font-weight: 400;
                            text-transform: unset;
                            letter-spacing: 0.24px;
                        }
                    }

                    .chosen-drop {
                        border: 2px solid #ffffff;
                        border-top: none;
                        box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.05);

                        .chosen-results {
                            li {
                                overflow: unset;
                                .rems(12);
                                line-height: 14px;
                                font-weight: 400;
                                text-transform: unset;
                                letter-spacing: 0.24px;
                                border-radius: 8px;
                            }
                        }
                    }

                    &.chosen-container-active {
                        box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.05);

                        .chosen-single {
                            border-color: #ffffff;
                            border-bottom: 2px solid #ffffff;
                        }
                    }
                }
            }
        }
    }

</style>
