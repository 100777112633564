<template>

    <div class="markupWrap">

        <div
                class="markupBox wysiwyg"
                v-html="markup.question_text"
        ></div> 
        
    </div>
    
</template>

<script>

export default {
    name: "markupWrap",
    components: {
       
    },
    props: {
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return false;
            }
        },
        multiuserQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserId: {
            type: [Number, String],
            default (rawProps) {
                return 0;
            }
        },
    },
    computed: {

        markup() {
            return this.getQuestion();
        }
        
    },
    methods: {

        getQuestion() {

            if(this.multiuserQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'questions', this.multiuserId);

            }else{

                return this.getQuestionByKey('id', this.questionId);

            }

        },
        getQuestionByKey(key, value, multiple, type, multiUserID) {


            const _this = this;

            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }

            if(_.isUndefined(multiUserID)) {
                multiUserID = false;
            }


            let question = false,
                    questions = [];


            if(multiUserID) {


                for (const userIndex in _this.$store.state.assessment.multiusers) {

                    const user = _this.$store.state.assessment.multiusers[userIndex];

                    if(user.id == multiUserID) {

                        for (const questionIndex in user.questions) {

                            let q = _this.$store.state.assessment.multiusers[userIndex].questions[questionIndex];

                            if (q.hasOwnProperty(key)) {

                                if (q[key] == value) {
                                    question = q;
                                    questions.push(q);
                                }

                            }

                        }

                    }

                }


            }else {

                for (let sIndex in _this.$store.state.assessment.sections) {

                    for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                        let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                        if (q.hasOwnProperty(key)) {

                            if (q[key] == value) {
                                question = q;
                                questions.push(q);
                            }

                        }

                    }

                }

            }

            return multiple ? questions : question;

        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .markupWrap {

        background: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        margin: 0 0 25px;
        
    }
    
    .markupBox {
        
        p {
            margin: 0 0 16px;
            
            &:last-child {
                margin: 0;
            }
        }
        
        img {
            position: relative;
            object-fit: contain;
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
        
    }
    
    

</style>
