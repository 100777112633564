<template>

    <nav class="sidebarNavigation" v-if="sections">

        <ul class="navigationBox">
            
            <template v-for="section in sections">
                
                <li :key="'section_nav_' + section.id" :data-section-id="section.id">
                    <div 
                            role="button"
                            class="navButton"
                            aria-disabled="false"
                            :class="[section.visible ? 'isEditing' : '', section.can_proceed ? 'isComplete' : '']"
                    >
                        
                        <div 
                                class="text" 
                                v-html="'Step ' + section.section_number + ': ' + section.title"
                        ></div>
                        
                        <span class="icon iconComplete">
                            <svg 
                                    width="20" 
                                    height="20" 
                                    viewBox="0 0 20 20" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                        d="M6.39498 9.40277C5.88736 8.89516 5.06418 8.89197 4.55664 9.39958C4.04883 9.9074 4.04883 10.7242 4.55664 11.2321L7.58164 14.2447C7.82566 14.4885 8.15626 14.6003 8.50084 14.6003H8.52324C8.87584 14.6003 9.21087 14.4701 9.45124 14.2123L17.8269 5.26143C18.3167 4.73643 18.2885 3.91365 17.7635 3.42377C17.2387 2.93438 16.4161 2.96198 15.9262 3.48698L8.4685 11.4778L6.39498 9.40277Z" 
                                        fill="currentColor"/>
                                <path 
                                        d="M19.1032 5.86215L17.5818 7.44795C17.8516 8.24977 18 9.10738 18 10.0001C18 14.4183 14.4182 18.0001 10 18.0001C5.58183 18.0001 2 14.4183 2 10.0001C2 5.58195 5.58183 2.00012 10 2.00012C11.5228 2.00012 12.9462 2.6259 14.1578 3.3645L15.5688 1.69367C13.9772 0.624467 12.0617 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 8.52357 19.6774 7.12333 19.1032 5.862V5.86215Z" 
                                        fill="currentColor"/>
                            </svg>
                        </span>

                        <span class="icon iconEditing">
                            <svg 
                                    width="20" 
                                    height="20" 
                                    viewBox="0 0 20 20" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                        d="M18.7979 1.12559C17.2952 -0.375197 14.6906 -0.375197 13.0877 1.12559L2.66922 11.5311C2.56905 11.6311 2.46887 11.8312 2.46887 11.9313L0.0645993 18.6348C-0.235934 19.4352 0.565489 20.2356 1.36691 19.9355L7.97865 17.4342C8.07883 17.4342 8.27919 17.2341 8.37936 17.2341L18.7979 6.72854C20.4007 5.1277 20.4007 2.62638 18.7979 1.12559ZM2.66922 17.2341L3.77118 14.0324L5.77474 16.0334L2.66922 17.2341ZM17.3954 5.3278L7.57794 15.133L4.77296 12.3315L14.5904 2.52633C15.3918 1.72591 16.6941 1.72591 17.3954 2.52633C18.1968 3.2267 18.1968 4.52738 17.3954 5.3278Z" 
                                        fill="currentColor"/>
                            </svg>

                        </span>
                        
                    </div>
                </li>
                
            </template>
            
            
        </ul>

    </nav>
    
</template>

<script>

import { mapGetters } from 'vuex';


export default {
    name: 'sectionNavigation',
    components: {
       
    },
    props: {
        sections: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
    },
    computed: {
        
        sectionCount() {
            const keyCount = Object.keys(this.sections);
            return keyCount.length;
        },
        confirmStepNumber() {
            const keyCount = Object.keys(this.sections);
            return keyCount.length + 1;
        }
        
    },
    methods: {
        
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .sidebarNavigation {
        
        
        .navigationBox {
            
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            
            &:before, &:after {
                display: none;
            }
            
            
            @media all and (max-width: 1020px) {
                flex-wrap: nowrap;
                gap: 0 5px;
            }
            
            
        }
        
        li {
            
            display: block;
            flex: 0 0 100%;
            margin: 0 0 15px;

            @media all and (max-width: 1020px) {
                margin: 0;
                flex: 1 1 100%;
            }
            
            &:last-child {
                margin: 0;
            }
            
            .navButton {
                text-decoration: none;
                font-weight: 700;
                .rems(14);
                line-height: 1.4em;
                letter-spacing: 0.1em;
                color: @text_color;
                display: flex;
                gap: 0 10px;
                padding: 10px 15px;
                background: #fff;
                border-radius: 10px;
                transition: background 200ms linear, color 200ms linear;

                @media all and (max-width: 1020px) {
                    padding: 0;
                    width: 100%;
                    height: 10px;
                    border-radius: 2px;
                }

                &.isComplete {

                    background: @primary_color;
                    color: #fff;
                    transition: background 200ms linear, color 200ms linear;


                    .iconEditing {
                        display: none;
                    }

                    .iconComplete {
                        display: flex;
                    }

                }
                
                &.isEditing {
                    
                    background: @assessment_dark_color;
                    color: #fff;
                    transition: background 200ms linear, color 200ms linear;
                    
                    .iconEditing {
                        display: flex;
                    }

                    .iconComplete {
                        display: none;
                    }
                    
                }
                
            }
        }
        
        .text {
            flex: 1 1 100%;

            @media all and (max-width: 1020px) {
                display: none;
            }
        }
        
        .icon {
            flex: 0 0 20px;
            display: none;
            justify-content: center;
            align-items: center;

            @media all and (max-width: 1020px) {
                display: none !important;
            }
            
            svg {
                width: 20px;
                height: 20px;
            }
        }
        
    }
    

</style>
