import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            surgery: {
                practice_name: '',
                address_1: '',
                address_2: '',
                city: '',
                postcode: '',
                phone: '',
                id: ''
            },
            healthProfile: {
                medications: '',
                allergies: '',
                conditions: ''
            }
        },
        assessment: {
            type: 'form',
            travel_form: false,
            category_id: false,
            product_id: false,
            sections: [],
            multiusers: [],
            products: [],
            selected_products: {},
            progress: 0,
            prescribingDoctorName: false,
            recommendations_template: {} //used to copy the recommended products sections and generate multiple versions of this section
        },
        medicalConditionList: [],
        medicationsList: [],
        createdAssessments: [],
        minibasket: {
            enabled: false,
            productCardsQuickAdd: false
        }
    },
    mutations: {
        
        SET_ASSESSMENT_MULTIUSER_ERRORS: (state, payload) => {

            let multiusers = { ...state.assessment.multiusers }

            for(let uIndex in multiusers) {
             
                if(multiusers[uIndex].id == payload.multiuserId) {
                    
                    let questionErrors = false;
                    
                    for(const qIndex in multiusers[uIndex].questions) {
                        
                        let question = multiusers[uIndex].questions[qIndex];
                        
                        if(!question.can_proceed) {
                            questionErrors = true;
                        }
                        
                    }

                    multiusers[uIndex].can_proceed = !questionErrors;

                }
                

            }

            state.assessment.multiusers = { ...state.assessment.multiusers, ...multiusers }
        },
        SET_ASSESSMENT_MULTIUSERS: (state, payload) => {
            
            state.assessment.multiusers = { ...state.assessment.multiusers, ...payload }

            //Generate recommended sections for multiusers
            if(state.assessment.travel_form) {
                
                let setProp = 99,
                    lastSectionProp = 0;
                
                //Remove last section
                for(const sProp in state.assessment.sections) {
                    lastSectionProp = sProp;
                }

               let lastSection = {...{}, ...state.assessment.sections[lastSectionProp]};
                
                delete state.assessment.sections[lastSectionProp];
                
                //Build dynamic sections
                for(const sProp in state.assessment.sections) {

                    if(state.assessment.sections[sProp].product_recommendations) {
                        state.assessment.recommendations_template = {...{}, ...state.assessment.sections[sProp]};
                        delete state.assessment.sections[sProp];
                        setProp = sProp;
                    }

                }

                let x = 0;

                const alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
                
                //Add sections
                for (const uProp in state.assessment.multiusers) {
                    
                    let user = state.assessment.multiusers[uProp];
                    let sectionTemplate = {...{}, ...state.assessment.recommendations_template};
                    
                    sectionTemplate.id = 9000 + x;
                    sectionTemplate.title = sectionTemplate.title + ' for ' + user.name;
                    sectionTemplate.multiuser_id = user.id;
                    
                    let letter = '';
                    
                    if(!_.isUndefined(alphabet[x])) {
                        letter = alphabet[x];
                    }
                    
                    sectionTemplate.section_number = sectionTemplate.section_number + letter;
                    
                    state.assessment.sections[setProp + '_' + (x + 1)] = sectionTemplate;
                    
                    x++;
                }
                
                if(lastSectionProp > 0) {
                    lastSection.id = lastSection.id + 1;
                    state.assessment.sections[parseInt(lastSectionProp) + 1] = lastSection;
                }
                
                let sectionsSorted = _.sortBy(state.assessment.sections, 'order');
                state.assessment.sections = sectionsSorted;

            }
            
        },
        SET_ASSESSMENT_PRODUCTS: (state, payload) => {

            let cleanPayload = {};
            
            for(const userID in payload) {
                
                let products = [];
                
                for(const productKey in payload[userID]) {
                    
                    let product = payload[userID][productKey];

                    if(_.isUndefined(product.selected)) {
                        product.selected = false;
                    }

                    if(!_.isUndefined(product.sizes)) {
                        let sizes = product.sizes.map(size => {

                            if(_.isUndefined(size.selected)) {
                                size.selected = false;
                            }
                            return size;
                        });

                        product.sizes = sizes;
                    }

                    products.push(product);
                    
                }

                cleanPayload[userID] = products;
                
            }
            
            
            state.assessment.products = cleanPayload;
        },
        SET_CREATED_ASSESSMENTS: (state, payload) => {
            state.createdAssessments = payload;
        },
        SET_ASSESSMENT_SELECTED_PRODUCTS: (state, payload) => {

            state.assessment.selected_products[payload.productProp] = [];
            
            if (payload.products?.length) {

                const selected = payload.products.filter(product => {

                    let selectedSizes = [];

                    if (product.sizes?.length) {

                        selectedSizes = product.sizes.filter(size => {
                            return size.selected;
                        });

                    }

                    return selectedSizes.length > 0 || product.selected;

                });

                let selectedObj = {};
                
                selected.forEach((val, i) => {
                    selectedObj[i] = val;
                });

                state.assessment.selected_products[payload.productProp] = {...state.assessment.selected_products[payload.productProp], ...selectedObj};
              

            }

            
        },
        SET_USER_SURGERY: (state, payload) => {
            state.user.surgery = { ...state.user.surgery, ...payload }
        },
        SET_MEDICAL_CONDITIONS_LIST: (state, payload) => {
            state.medicalConditionList = { ...state.medicalConditionList, ...payload }
        },
        SET_MEDICATIONS_LIST: (state, payload) => {
            state.medicationsList = { ...state.medicationsList, ...payload }
        },
        SET_USER_HEALTH_PROFILE: (state, payload) => {
            state.user.healthProfile = { ...state.user.healthProfile, ...payload }
        },
        SET_ASSESSMENT_SECTIONS: (state, payload) => {
            state.assessment.sections = { ...state.assessment.sections, ...payload }
        },
        SET_ASSESSMENT_QUESTION: (state, question) => {
            
            let sections = { ...state.assessment.sections }
            
            for(let sIndex in sections) {
                
                for (let qIndex in sections[sIndex].questions) {

                    if (question.id == sections[sIndex].questions[qIndex].id) {
                        sections[sIndex].questions[qIndex] = {...sections[sIndex].questions[qIndex], ...question}
                    }

                }
                
            }
            
            state.assessment.sections = { ...state.assessment.sections, ...sections }
            
        },
        SET_ASSESSMENT_MULTIUSER_QUESTION: (state, question, userID) => {

            let multiusers = { ...state.assessment.multiusers }

            for(const userIndex in multiusers) {

                let user = multiusers[userIndex];
                
                if(user.id == userID) {
                    
                    for (const qIndex in user.questions) {

                        let q = user.questions[qIndex];
                        
                        if (question.id == q.id) {
                            multiusers[userIndex].questions[qIndex] = {...multiusers[userIndex].questions[qIndex], ...question}
                        }

                    }

                }

            }

            state.assessment.multiusers = { ...state.assessment.multiusers, ...multiusers }

        },
        SET_ASSESSMENT_TRAVEL_QUESTION: (state, question) => {

            let sections = { ...state.assessment.sections }

            for(let sIndex in sections) {

                for (let qIndex in sections[sIndex].travel_questions) {

                    if (question.id == sections[sIndex].travel_questions[qIndex].id) {
                        sections[sIndex].travel_questions[qIndex] = {...sections[sIndex].travel_questions[qIndex], ...question}
                    }

                }

            }

            state.assessment.sections = { ...state.assessment.sections, ...sections }

        },
        SET_ASSESSMENT_TYPE: (state, type) => {

            const allowed = ['form', 'category', 'product'];

            if(allowed.indexOf(type) > -1) {
                state.assessment.type = type;
            }else{
                state.assessment.type = 'form';
            }
        },
        SET_ASSESSMENT_PRODUCT_ID: (state, ID) => {

            state.assessment.product_id = ID;
        },
        SET_ASSESSMENT_CATEGORY_ID: (state, ID) => {

            state.assessment.category_id = ID;
        },
        SET_ASSESSMENT_TRAVEL_FORM: (state, is_travel_form) => {
            state.assessment.travel_form = is_travel_form;
        },
        SHOW_ASSESSMENT_SECTION: (state, sectionID) => {

            let sections = { ...state.assessment.sections }
            
            for(const sIndex in sections) {
                
                if(sections[sIndex].id == sectionID) {
                    sections[sIndex].visible = true;
                }else{
                    sections[sIndex].visible = false;
                }
                
            }

            state.assessment.sections = { ...state.assessment.sections, ...sections }

        },
        UPDATE_PROGRESS: (state, sectionID) => {
            
            const totalSections = Object.keys(state.assessment.sections).length;

            let visibleSectionIndex = 0;


            let sections = { ...state.assessment.sections }

            for(const sIndex in sections) {
                
                if(sections[sIndex].visible) {
                    visibleSectionIndex = parseInt(sIndex);
                }

            }
         

            const currentPage = visibleSectionIndex + 1;
         
            let progress = 0;

            if(totalSections > 0 && currentPage > 0) {
                progress =  Math.ceil((currentPage / totalSections) * 100);
            }
            
            state.assessment.progress = progress;
        },
        SET_PRESCRIBING_DOCTOR_NAME: (state, name) => {
            
            if(name) {
                if(name.length) {
                    state.assessment.prescribingDoctorName = name;
                }
            }
            
        }
    },
    actions: {
        setAssessmentMultiuserErrors: (context, payload) => {
            context.commit('SET_ASSESSMENT_MULTIUSER_ERRORS', payload);
        },
        setAssessmentMultiusers: (context, payload) => {
            context.commit('SET_ASSESSMENT_MULTIUSERS', payload);
        },
        setUserSurgery: (context, payload) => {
            context.commit('SET_USER_SURGERY', payload);
        },
        setMedicalConditionList: (context, payload) => {
            context.commit('SET_MEDICAL_CONDITIONS_LIST', payload);
        },
        setMedicationsList: (context, payload) => {
            context.commit('SET_MEDICATIONS_LIST', payload);
        },
        setUserHealthProfile: (context, payload) => {
            context.commit('SET_USER_HEALTH_PROFILE', payload);
        },
        setAssessmentSection : (context, payload) => {
            payload = _.sortBy(payload, 'order')
            context.commit('SET_ASSESSMENT_SECTIONS', payload);
        },
        updateAssessmentQuestion: (context, question) => {
            context.commit('SET_ASSESSMENT_QUESTION', question);
        },
        updateAssessmentMultiuserQuestion: (context, question, userID) => {
            context.commit('SET_ASSESSMENT_MULTIUSER_QUESTION', question, userID);
        },
        updateAssessmentTravelQuestion: (context, question) => {
            context.commit('SET_ASSESSMENT_TRAVEL_QUESTION', question);
        },
        setAssessmentType: (context, type) => {
            context.commit('SET_ASSESSMENT_TYPE', type);
        },
        setAssessmentProductID: (context, payload) => {
            context.commit('SET_ASSESSMENT_PRODUCT_ID', payload);
        },
        setAssessmentCategoryID: (context, payload) => {
            context.commit('SET_ASSESSMENT_CATEGORY_ID', payload);
        },
        setAssessmentTravelForm: (context, is_travel_form) => {
            context.commit('SET_ASSESSMENT_TRAVEL_FORM', is_travel_form);
        },
        showAssessmentSection: (context, sectionID) => {
            context.commit('SHOW_ASSESSMENT_SECTION', sectionID);
        },
        setAssessmentSelectedProducts: (context, payload) => {
            context.commit('SET_ASSESSMENT_SELECTED_PRODUCTS', payload);
        },
        setAssessmentProducts: (context, payload) => {
            context.commit('SET_ASSESSMENT_PRODUCTS', payload);
        },
        setCreatedAssessments: (context, payload) => {
            context.commit('SET_CREATED_ASSESSMENTS', payload);
        },
        updateProgress: (context, payload) => {

            context.commit('UPDATE_PROGRESS', payload);
        },
        setPrescribingDoctorName: (context, payload) => {
            context.commit('SET_PRESCRIBING_DOCTOR_NAME', payload);
        }
   
    },
    getters: {

        getProgress: state => {
            
            return state.assessment.progress;
            
        },
        getAssessmentSectionNextStepText: state => {

            let current = false,
                sectionNumber = '2';
            
            for(const prop in state.assessment.sections) {

                const section = state.assessment.sections[prop];
                
                if(current) {
                    sectionNumber = section.section_number;
                    current = false;
                }
                
                if(section.visible) {
                    current = true;
                }
                
            }
            return 'Next';
            
        },
        getAssessmentMultiusers: state => {
            return state.assessment.multiusers;
        },
        getAssessmentProducts: state => {
            return state.assessment.products;
        },
        getAssessmentSelectedProducts: state => {
            return state.assessment.selected_products;
        },
        getUserSurgery: state => {
            return state.user.surgery;
        },
        getMedicalConditionList: state => {
            return state.medicalConditionList;
        },
        getMedicationsList: state => {
            return state.medicationsList;
        },
        getUserHealthProfile: state => {
            return state.user.healthProfile;
        },
        getAssessmentType: state => {
            return state.assessment.type;
        },
        getAssessmentSections: state => {
            return _.orderBy(state.assessment.sections, 'order');
        },
        getPrescribingDoctorname: state => {
            
            return state.assessment.prescribingDoctorName;
            
        }
    }
});
