var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "collapseArea",
      class: [_vm.hasError ? "withError" : "", _vm.disabled ? "inactive" : ""],
      attrs: { id: _vm.sectionId },
    },
    [
      _c(
        "h4",
        {
          staticClass: "listSectionTitle",
          class: [!_vm.sectionOpen ? "collapsed" : ""],
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.collapse()
            },
          },
        },
        [
          _c("span", {
            staticClass: "titleText",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          !_vm.disabled
            ? _c("div", { staticClass: "toggleIcon" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "14",
                      height: "14",
                      viewBox: "0 0 14 14",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("rect", {
                      attrs: {
                        x: "14",
                        y: "6",
                        width: "2",
                        height: "14",
                        rx: "1",
                        transform: "rotate(90 14 6)",
                        fill: "currentColor",
                      },
                    }),
                    _vm._v(" "),
                    !_vm.sectionOpen
                      ? _c("rect", {
                          attrs: {
                            x: "8",
                            y: "14",
                            width: "2",
                            height: "14",
                            rx: "1",
                            transform: "rotate(-180 8 14)",
                            fill: "currentColor",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "listSectionBody" }, [
        _c("div", { staticClass: "innerListArea" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }