var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productListings" },
    [
      [
        [
          _c("h2", {
            staticClass: "productListings__title",
            domProps: { innerHTML: _vm._s(_vm.recommendedTreatmentsTitle) },
          }),
          _vm._v(" "),
          _vm._t("timer"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "productListings__products",
              class: [_vm.hasSelectionError ? "hasError" : ""],
            },
            [
              _c(
                "ul",
                { staticClass: "productList" },
                [
                  _vm._l(_vm.products, function (product) {
                    return product.recommended || _vm.groupAllProducts
                      ? [
                          product.sizes.length > 0 && _vm.individualSizes
                            ? _vm._l(product.sizes, function (size) {
                                return _c(
                                  "li",
                                  { key: "size_id_" + size.id },
                                  [
                                    _c("product-card", {
                                      key: "product_size_" + size.id,
                                      attrs: {
                                        product: product,
                                        "use-trustpilot": "true",
                                        "in-stock": _vm.sizeHasStock(size),
                                        "from-price": _vm.sizePrice(size),
                                        "preview-only": false,
                                        minimal: false,
                                        "show-description": true,
                                        "option-card": true,
                                        "option-quantity-adjust":
                                          _vm.$store.state.assessment
                                            .travel_form,
                                        "option-size": size,
                                        "option-show-size": true,
                                        "option-enabled":
                                          _vm.isSizeEnabled(size),
                                        "option-button-text":
                                          "Select this treatment",
                                        "option-deselect-button-text":
                                          "Remove selection",
                                      },
                                      on: {
                                        "option-selected":
                                          _vm.optionSizeSelected,
                                        "option-deselected":
                                          _vm.optionSizeDeselected,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              })
                            : [
                                _c(
                                  "li",
                                  { key: "product_id_" + product.id },
                                  [
                                    _c("product-card-large", {
                                      attrs: {
                                        product: product,
                                        "in-stock": product.in_stock,
                                        "from-price": product.from_price,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                        ]
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        _vm._v(" "),
        _vm.hasOtherSuitableItems && !_vm.groupAllProducts
          ? [
              _c("h2", {
                staticClass: "productListings__title",
                domProps: { innerHTML: _vm._s(_vm.suitableTreatmentsTitle) },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "productListings__products",
                  class: [_vm.hasSelectionError ? "hasError" : ""],
                },
                [
                  _c(
                    "ul",
                    { staticClass: "productList" },
                    [
                      _vm._l(_vm.products, function (product) {
                        return !product.recommended
                          ? [
                              product?.sizes?.length
                                ? _vm._l(product.sizes, function (size) {
                                    return _c(
                                      "li",
                                      { key: "size_id_" + size.id },
                                      [
                                        _c("product-card", {
                                          key: "product_size_" + size.id,
                                          attrs: {
                                            product: product,
                                            "in-stock": _vm.sizeHasStock(size),
                                            "from-price": _vm.sizePrice(size),
                                            "preview-only": false,
                                            minimal: false,
                                            "show-description": true,
                                            "option-card": true,
                                            "option-size": size,
                                            "option-quantity-adjust":
                                              _vm.$store.state.assessment
                                                .travel_form,
                                            "option-show-size": true,
                                            "option-enabled":
                                              _vm.isSizeEnabled(size),
                                            "option-button-text":
                                              "Select this treatment",
                                            "option-deselect-button-text":
                                              "Remove selection",
                                          },
                                          on: {
                                            "option-selected":
                                              _vm.optionSizeSelected,
                                            "option-deselected":
                                              _vm.optionSizeDeselected,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  })
                                : [
                                    _c(
                                      "li",
                                      { key: "product_id_" + product.id },
                                      [
                                        _c("product-card", {
                                          attrs: {
                                            product: product,
                                            "in-stock": product.in_stock,
                                            "from-price": product.from_price,
                                            "preview-only": false,
                                            minimal: false,
                                            "option-card": true,
                                            "option-quantity-adjust":
                                              _vm.$store.state.assessment
                                                .travel_form,
                                            "option-button-text":
                                              "Select this treatment",
                                            "option-deselect-button-text":
                                              "Remove selection",
                                          },
                                          on: {
                                            "option-selected":
                                              _vm.optionSelected,
                                            "option-deselected":
                                              _vm.optionDeselected,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                            ]
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.products?.length
          ? [
              _c("h2", {
                staticClass: "productListings__title",
                domProps: { innerHTML: _vm._s(_vm.noProductsFoundTitle) },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]
          : _vm._e(),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "noTreatments" }, [
      _c("p", [
        _vm._v(
          "\n                    Unfortunately we cannot offer you any treatments for this condition. "
        ),
        _c("br"),
        _vm._v("\n                    Please feel free to "),
        _c("a", { attrs: { href: "/contact", title: "Contact us for help" } }, [
          _vm._v("contact us"),
        ]),
        _vm._v(" for more information.\n                "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }