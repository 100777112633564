<template>

  <div class="productFilter">


      <section class="filterFlyout" :class="[filterMenuVisible ? 'menuVisible' : '']">

          <form action="/" class="filterContainer">

              <div class="menuHeader">

                  <h2 class="headerTitle">
                      Filter medication

                      <button type="button" aria-label="Close filter menu" @click.prevent="closeFilterMenu()">

                          <span class="screenreader">Close filter menu</span>

                          <i class="svgIcon">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   viewBox="0 0 512 512"
                              >
                                  <path
                                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"
                                      fill="currentColor"
                                  />
                              </svg>
                          </i>

                      </button>

                  </h2>

              </div> <!-- .menuHeader -->

              <div class="innerFilterMenu" :class="[loading ? 'isLoading' : '']">

                  <div class="filterScroll">

                      <div class="filterBlock" v-if="filterNotEmpty('selections')">

                          <h3 class="filterBlockTitle">Current selection</h3>

                          <div class="filterForm">

                              <div class="currentSelections">

                                  <a
                                          href="/pharmacy/"
                                          class="selectionLink"
                                          @click.prevent="updateAjax('/pharmacy/')"
                                  >
                                      <span class="selectionText">Clear All</span>
                                      <i class="svgIcon">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" fill="currentColor"/></svg>
                                      </i>
                                  </a>

                                  <template v-for="(selection, n) in filters.selections">

                                      <a
                                              :href="selection.url"
                                              class="selectionLink"
                                              :key="'selection' + n"
                                              @click.prevent="updateAjax(selection.url)"
                                      >
                                          <span
                                                  class="selectionText"
                                                  v-html="selection.value"
                                          ></span>

                                          <i class="svgIcon">
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" fill="currentColor"/></svg>
                                          </i>

                                      </a>
                                  </template>

                              </div> <!-- .currentSelections -->


                          </div> <!-- .filterForm -->

                      </div> <!-- .filterBlock -->

                      <div class="filterBlock" v-if="filterNotEmpty('categories')">

                          <h3
                                  class="filterBlockTitle toggleOpener"
                                  :class="[menu.categories ? 'open' : '']"
                                  @click.prevent="menu.categories = !menu.categories"
                          >
                              Category

                              <i class="svgIcon iconOpen">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 448 512"
                                  >
                                      <path
                                              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                              fill="currentColor"
                                      />
                                  </svg>
                              </i>

                              <i class="svgIcon iconClose">
                                  <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512"
                                  >
                                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </h3>

                          <div class="filterForm">

                              <div class="innerFilterForm">

                                  <div class="row" v-for="(category, n) in filters.categories" :key="'cat' + category.id">
                                      <input
                                              type="checkbox"
                                              :id="'checkboxCat' + category.id"
                                              v-model="filters.categories[n].selected"
                                      >
                                      <label :for="'checkboxCat' + category.id" @click.prevent="updateAjax(category.url)">
                                          <a
                                                  :href="category.url"
                                                  class="filterLink"
                                                  v-html="category.name"
                                          ></a>
                                      </label>
                                  </div>
                              </div> <!-- .innerFilterForm -->

                          </div> <!-- .filterForm -->

                      </div> <!-- .filterBlock -->

                      <div class="filterBlock" v-if="filterNotEmpty('brands')">

                          <h3
                                  class="filterBlockTitle toggleOpener"
                                  :class="[menu.brands ? 'open' : '']"
                                  @click.prevent="menu.brands = !menu.brands"
                          >
                              Brands

                              <i class="svgIcon iconOpen">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 448 512"
                                  >
                                      <path
                                              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                              fill="currentColor"
                                      />
                                  </svg>
                              </i>

                              <i class="svgIcon iconClose">
                                  <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512"
                                  >
                                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </h3>

                          <div class="filterForm">

                              <div class="innerFilterForm">

                                  <div class="row" v-for="(brand, n) in filters.brands" :key="'cat' + brand.id">
                                      <input
                                              type="checkbox"
                                              :id="'checkboxBrand' + brand.id"
                                              v-model="filters.brands[n].selected"
                                      >
                                      <label :for="'checkboxBrand' + brand.id" @click.prevent="updateAjax(brand.url)">
                                          <a
                                                  :href="brand.url"
                                                  class="filterLink"
                                                  v-html="brand.name"
                                          ></a>
                                      </label>
                                  </div>
                              </div> <!-- .innerFilterForm -->

                          </div> <!-- .filterForm -->

                      </div> <!-- .filterBlock -->

                      <div class="filterBlock" v-if="filterNotEmpty('price', 'prices')">

                          <h3
                                  class="filterBlockTitle toggleOpener"
                                  :class="[menu.prices ? 'open' : '']"
                                  @click.prevent="menu.prices = !menu.prices"
                          >
                              Price

                              <i class="svgIcon iconOpen">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 448 512"
                                  >
                                      <path
                                              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                              fill="currentColor"
                                      />
                                  </svg>
                              </i>

                              <i class="svgIcon iconClose">
                                  <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512"
                                  >
                                      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </h3>

                          <div class="filterForm">

                              <price-range-slider
                                      :prices="filters.price.prices"
                                      :currency-symbol="filters.price.currency_data.symbol"
                                      @update-ajax="updateAjax"
                              ></price-range-slider>

                          </div> <!-- .filterForm -->

                      </div> <!-- .filterBlock -->


                  </div> <!-- .filterScroll -->

              </div> <!-- .innerFilterMenu -->

              <div class="menuFooter">
                  <button
                          type="button"
                          class="button"
                          @click.prevent="closeFilterMenu()"
                  >
                      Done filtering
                  </button>
              </div> <!-- .menuFooter -->

          </form>

      </section>


      <section class="filterTopBar">

          <div class="topBarLeft">

              <button
                      type="button"
                      class="button buttonOutlined filterButtonLink"
                      @click.prevent="toggleFilterMenu"
              >
                  Filter

                  <div class="svgIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                                  d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z"
                                  fill="currentColor"
                          />
                      </svg>
                  </div> <!-- .svgIcon -->

              </button>

          </div> <!-- .topBarLeft -->

          <div class="topBarRight">

              <div class="selectWrap">

                  <div class="row" v-if="filterNotEmpty('sort', 'options')">

                      <chosen
                              :options="filters.sort.options"
                              placeholder="Sort by"
                              v-model="filters.sort.option_selected"
                              class="no-chosen"
                              @input="updateSort()"
                              label="name"
                              track-by="value"
                      ></chosen>

                  </div> <!-- .row -->

              </div> <!-- .selectWrap -->

          </div> <!-- .topBarRight -->

      </section>


  </div>


</template>

<script>
import priceRangeSlider from './price_range_slider';

export default {
    name: "productFilter",
    components: {
        priceRangeSlider
    },
    props: {
        loading: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        items: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        filters: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            filter_items: [],
            filterMenuVisible: false,
            currentURL: window.location.href,
            menu: {
                categories: true,
                brands: false,
                prices: false
            }
        }
    },
    computed: {


    },
    methods: {

        updateAjax(url) {
            if(typeof url =='undefined') {
                url = this.currentURL;
            }

            url = this.resetPagination(url);

            this.$emit('update-ajax', url );
        },

        filterNotEmpty(key1, key2) {

            if(this.filters.hasOwnProperty(key1)) {

                if(typeof key2 != 'undefined') {
                    if (this.filters[key1].hasOwnProperty(key2)) {

                        if(typeof this.filters[key1][key2] != 'undefined') {
                            return true;
                        }
                    }
                }

                if(typeof this.filters[key1] != 'undefined') {
                    return this.filters[key1].length;
                }

            }

            return false;

        },

        updateSort() {
            let activeSort =  this.filters.sort.options.filter(item => {

                const selected = this.filters.sort.option_selected;

                if(selected == item.value) {
                    return item;
                }else{
                    return false;
                }
            });

            if(activeSort.length) {

                const sortOpt = activeSort[0];
                this.currentURL = this.resetPagination(sortOpt.url);
                this.updateAjax();

            }
        },

        resetPagination(url) {
            const regex = /page\=[0-9]{1,6}/i;
            url = url.replace(regex, 'page=1');
            return url;
        },

        toggleFilterMenu() {
            this.filterMenuVisible = !this.filterMenuVisible;
        },

        closeFilterMenu() {
            this.filterMenuVisible = false;
        },

        goURL(url) {
            window.location = url;
        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .filterTopBar {
        display: flex;
        padding: 16px 0 16px;

        .topBarLeft {
            flex: 1 1 100%;
            padding-right: 16px;
        }

        .topBarRight {
            flex: 0 0 50%;
            padding-left: 16px;
            display: flex;
            justify-content: flex-end;
        }

        .row {
            float: none;
            clear: both;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 0;
        }

        .selectWrap {
            width: 100%;
            max-width: 300px;

            .row {
                float: none;
                clear: both;

                &:before, &:after {
                    display: none;
                }
            }

            .chosen-container {

                width: auto !important;
                max-width: 100% !important;
                min-width: 1px !important;

                .chosen-single {
                    padding: 14px 56px 14px 20px;
                    border: 2px solid #ffffff;
                    background: #ffffff;

                    span {
                        .rems(12);
                        line-height: 14px;
                        font-weight: 400;
                        text-transform: unset;
                        letter-spacing: 0.24px;
                        overflow: unset;
                        //top: -1px;
                    }

                    & > div {
                        width: 44px;

                        b {

                            margin-top: 1px;

                            &:after {
                                color: #1D1D1D;
                            }
                        }
                    }
                }

                .chosen-drop {
                    border: 2px solid #ffffff;
                    border-top: none;
                    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.05);

                    .chosen-results {
                        li {
                            overflow: unset;
                            .rems(12);
                            line-height: 14px;
                            font-weight: 400;
                            text-transform: unset;
                            letter-spacing: 0.24px;
                            border-radius: 8px;
                        }
                    }
                }
                
                &.chosen-container-active {
                    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.05);

                    .chosen-single {
                        border-color: #ffffff;
                        border-bottom: 2px solid #ffffff;
                    }
                }

                //&.chosen-with-drop {
                //    .chosen-drop {
                //        min-width: 196px;
                //        right: 0;
                //        left: auto;
                //        border: 1.5px solid #5ABF01 !important;
                //        border-radius: 5px !important;
                //        transition: none;
                //    }
                //}

            }
        }

        .filterButtonLink {

            .rems(12);
            line-height: 12px;
            font-weight: 400;
            letter-spacing: 0.1em;
            position: relative;
            margin: 0;
            //padding: 15px 24px 15px 0;

            &:after {
                display: none !important;
            }

            .svgIcon {

                svg {
                    width: 14px;
                    height: 14px;
                    margin-top: -0.1px;
                }
            }

        }
    }

    .filterFlyout {

        display: block;
        float: none;
        clear: both;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        z-index: 9999;
        background: rgba(255,255,255,0.9);
        backdrop-filter: blur(10px);
        border-right: 1.5px solid @primary_color;
        width: 80vw;
        max-width: 400px;
        color: @text_color;
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: transform 400ms, opacity 400ms, visibility 400ms;


        &.menuVisible {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: transform 400ms, opacity 400ms, visibility 400ms;
        }

        .filterContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .menuHeader, .menuFooter {
            padding: 24px;
            flex: 0 0 auto;
        }

        .menuHeader{
            border-bottom: 1px solid rgba(0,0,0,0.2);
        }

        .headerTitle {
            .rems(18);
            line-height: 24px;
            margin: 0;
            position: relative;
            padding-right: 56px;

            button {
                width: 48px;
                height: 48px;
                background: transparent;
                border: none;
                color: @primary_color;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: color 200ms linear;
                cursor: pointer;

                &:hover, &:active, &:focus {
                    color: darken(@primary_color, 8%);
                    transition: color 200ms linear;
                }

                .svgIcon {
                    width: 100%;
                    height: 100%;

                    svg {
                        width: 20px;
                        height: 20px;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .innerFilterMenu {
            flex: 1 1 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;

            opacity: 1;
            pointer-events: auto;
            transition: opacity 300ms;

            &.isLoading {
                opacity: 0.5;
                pointer-events: none;
                transition: opacity 300ms;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 80px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,0.97) 0%, rgba(255,255,255,0.49) 60%, rgba(255,255,255,0) 100%);
                pointer-events: none;
            }
        }

        .filterScroll {
            padding: 24px 48px 80px 24px;
            width: ~"calc(100% + 24px)";
            overflow-y: auto;
            height: 100%;
        }

        .menuFooter {
            border-top: 1px solid rgba(0,0,0,0.2);
            position: relative;
            z-index: 2;

            .button {
                margin: 0;
                width: 100%;
            }
        }

        .filterBlock {
            padding: 0;
            margin: 0;
        }

        .filterBlockTitle {
            .rems(16);
            line-height: 24px;
            margin: 0;
            padding: 8px 0;
            color: inherit;
            position: relative;

            .svgIcon {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;

                svg {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &:not(.toggleOpener ) {


                .iconOpen {
                    display: none !important;
                }

                .iconClose {
                    display: none !important;
                }

            }

            &.toggleOpener {

                padding-right: 32px;
                cursor: pointer;
                color: @text_color;

                .svgIcon {
                    color: @text_color;
                    transition: color 200ms linear;
                }

                &:hover, &:active, &:focus {

                    .svgIcon {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }

                }

                & + .filterForm {
                    display: none;
                }

                &.open + .filterForm {
                    display: block;
                }

                .iconOpen {
                    display: inline-block;
                }

                .iconClose {
                    display: none;
                }

                &.open {
                    .iconOpen {
                        display: none;
                    }

                    .iconClose {
                        display: inline-block;
                    }
                }

            }

        }

        .filterForm {
            float: none;
            clear: both;
            padding: 8px 0 24px;
            overflow: hidden;
            position: relative;

            .innerFilterForm {
                width: ~"calc(100% + 24px)";
                padding: 0;
                overflow-y: auto;
                max-height: 500px;
                position: relative;
            }

            a {
                color: @text_color;
                transition: color 200ms linear;

                &:hover, &:active {
                    color: @highlight_color;
                    transition: color 200ms linear;
                }
            }

            .row {
                float: none;
                clear: both;
                margin: 0;
            }
        }

        .currentSelections {

            .selectionLink {
                display: flex;
                width: 100%;
                padding: 6px 0;

                .selectionText {
                    flex: 1 1 auto;
                }

                i {
                    flex: 0 0 auto;
                    width: 24px;
                    height: 24px;
                }
            }
        }

    }



</style>
