<template>

    <div class="productSidebar">

        <div class="itemTitle">

            <stock-badge
                :has-stock="inStock"
                :price="fromPrice"
                :show-price="false"
                :currency-symbol="'£'"
                :small="false"
                :icon="true"
                :bubble="true"
                :css-class="['productPage']"
            ></stock-badge>

            <h1
                    class="mainTitle"
                    v-if="product.name"
                    v-html="product.name"
            ></h1>


        </div> <!-- .itemTitle -->


<!--        <stock-badge-->
<!--                :has-stock="inStock"-->
<!--                :price="fromPrice"-->
<!--                :show-price="true"-->
<!--                :currency-symbol="'£'"-->
<!--                :small="false"-->
<!--        ></stock-badge>-->

<!--        <div class="itemDescription wysiwyg" v-if="product.description">-->

<!--            <p v-html="product.description"></p>-->

<!--            <div class="descriptionLink" v-if="hasFullDescription">-->
<!--                <a href="#productFullDescription" class="moreDetails">-->
<!--                    View full product description-->
<!--                </a>-->
<!--            </div> &lt;!&ndash; .descriptionLink &ndash;&gt;-->

<!--        </div> &lt;!&ndash; .itemDescription &ndash;&gt;-->


        <add-to-basket
                :basket-button-data="basketButtonData"
                :product="product"
                :show-description="true"
        ></add-to-basket>

        <out-of-stock-notify
            v-if="!inStock"
            :url-submit="outOfStockUrl"
            button-label="Notify me when back in stock"
            :shop-item-id="product.id"
        ></out-of-stock-notify>

    </div> <!-- .productSidebar -->

</template>

<script>
import addToBasket from "./partials/add_to_basket";
import outOfStockNotify from "../../plugins/mtc_outofstocknotify/out_of_stock_notify.vue";

export default {
    name: 'productSidebar',
    components: {
        addToBasket,
        outOfStockNotify
    },
    props: {
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        outOfStockUrl: {
            type: [String],
            default (rawProps) {
                return '/no-stock-url';
            }
        },
        fromPrice: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        hasFullDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .productSidebar {
        margin: 0 0 35px;

        .itemTitle {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            margin: 0;

            .stockWrap {
                padding: 0;
                flex: 0 0;
            }

            .mainTitle {
                padding: 0;
                flex: 1 1;
                margin: 0;
            }

            @media @product_breakpoint {
                justify-content: center;
                
                .mainTitle {
                    flex: unset;
                }
            }

        }

        .itemDescription {

            p, ul, ol {
                margin-bottom: 24px;

                &:last-child {
                    margin: 0;
                }
            }

        }

        .basketActions {

            padding-top: 25px;

        }

        .doctorWarnings {
            padding-top: 50px;
        }

        .basketActions {

            @media @product_breakpoint {
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
            }


            .basketButtonRow {

                @media @product_breakpoint {
                    width: 100%;
                }

                .rowInput {
                    //flex: 0 0 80px;

                    .mNumberInput input {
                        min-height: 44px !important;
                        height: 44px !important;
                    }
                }

                .rowButton {
                    padding-left: 20px;

                    .addToBagButton {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        text-align: left;
                        padding: 16px 20px 14px 20px;
                        background: @primary_gradient;
                        .rems(12);
                        line-height: 14px;
                        letter-spacing: 0.24px;
                        font-weight: 400;
                        transition: opacity .3s ease;

                        &:hover {
                            opacity: 0.7;
                        }

                        @media @product_breakpoint {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .productSizes {

            padding-bottom: 25px;

            .chosen-container {

                .chosen-single {

                    padding: 14px 56px 14px 20px;

                    span {
                        .rems(12);
                        line-height: 1em;
                        letter-spacing: 0.1em;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    & > div {
                        width: 44px;

                        b {

                            margin-top: 1px;

                            &:after {
                                color: #1D1D1D;
                            }
                        }
                    }

                }

                .chosen-results {
                    li {
                        .rems(12);
                        line-height: 1em;
                        letter-spacing: 0.1em;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 13px 15px;
                    }
                }

            }

        }


    }

</style>
