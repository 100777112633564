var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assessmentBasketActions" }, [
    _vm.errors.length
      ? _c("div", { staticClass: "basketErrors" }, [
          _c("div", { staticClass: "error basketAlert" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.errors[0]) } }),
          ]),
        ])
      : _vm.success
      ? _c("div", { staticClass: "basketSuccess" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "basketButtons" }, [
      _c("button", {
        staticClass: "button",
        attrs: { type: "button", disabled: _vm.buttonDisabled },
        domProps: { innerHTML: _vm._s(_vm.label) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.addToBasket()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "success basketAlert" }, [
      _c("p", [
        _vm._v(
          "\n                Your item(s) have been successfully added to your\n                "
        ),
        _c("a", { attrs: { href: "/shop/checkout/basket.php" } }, [
          _vm._v("basket"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }