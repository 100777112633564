function checkBillingSelect() {
    'use strict';

    if ($('select[name="billing_country"]').val() === 'US') {
        $('#bill-state').show();
    } else {
        $('#bill-state').hide();
    }

}

function toggleStates() {
    'use strict';

    $.ajax({
        url: "ajax/state_list.php",
        data: {
            id: $('#member_id').val(),
            country: $('#country').val()
        },
        type: "GET",
        success: function (result) {
            $('#state_row').html(result);
        }
    });

}

$(document).ready(function () {
    'use strict';


    if ($('#country').length > 0 && $('#state_row').length > 0) {
        toggleStates();

        $('#country').on('change', function () {
            toggleStates();
        });
    }

    /*
     * Hide select box for states initially if country is not US
     * and on any change of country do the check again.
     */
    if ($('.membersWrap, #memberRegister').length) {
        checkBillingSelect();

        $('select[name="billing_country"]').on('change', function () {
            checkBillingSelect();
        });
    }

    /*
     * Ajax for members forms
     *
     * Form function can be found in core/js/functions.js
     */
    if ($('.js_memberSignin').length) {
        submitForm('.js_memberSignin');
    }

    if ($('.js_registerMember').length) {
        submitForm('.js_registerMember');
    }

    if ($('.js_reminder').length) {
        submitForm('.js_reminder');
    }

    if ($('.js_resetPassword').length) {
        submitForm('.js_resetPassword');
    }

    if ($('.js_memberContactDetails').length) {
        submitForm('.js_memberContactDetails');
    }

    window.all_medications = [];
    window.all_medical_conditions = [];

    if($('.js_meds').length) {

        window.all_medications = JSON.parse($('.js_meds').attr('data-all-medications'));
        window.all_medical_conditions = JSON.parse($('.js_meds').attr('data-all-medical-conditions'));

    }

    $("input.js_medications_tagit").each(function (key, input) {
        $(input).tagit({
            allowSpaces: true,
            availableTags: window.all_medications
        });
    });

    $("input.js_medical_conditions_tagit").each(function (key, input) {
        $(input).tagit({
            allowSpaces: true,
            availableTags: window.all_medical_conditions
        });
    });

    $("input.js_allergies_tagit").each(function (key, input) {
        $(input).tagit({
            allowSpaces: true
        });
    });

    if ($('#chat-log').length) {
        $('.satisfactoryOption').on('change', function() {
            var selected = $('input[name=satisfactory]:checked').val();
            var message_id = $(this).data('message_id');
            if (selected === '3') {
                $('#satisfactoryComment-' + message_id).show();
            } else {
                $('#satisfactoryComment-' + message_id).hide();
            }
        });
        $('.satisfactoryOption').trigger('change');

        $('.sastisfactoryForm').on('submit', function (e) {
            e.preventDefault();
            var data = $(this).serialize();
            data += '&action=satisfactory';
            $.ajax({
                type: "POST",
                url: "/members/messages/view_thread.php",
                data: data,
                success: function(data) {
                    var response = $.parseJSON(data);
                    $('#satisfactoryContainer-' + response.message_id).html(response.success);
                }
            });
        });
    }

    if($('.js_rateReply').length) {

        $('.js_rateReply').on('click', function(e) {

            e.preventDefault();
            var $target = $(this).parent().parent().find('.sastisfactoryForm');

            $target.slideFadeToggle('fast');

        });


    }


    /*
    * NHS Login screen points
     */
    slickLoginPoints();




}); // document ready


$(window).on('resize', debouncer(function (e) {

    'use strict';

    /*
    * NHS Login screen points
     */
    slickLoginPoints();

})); // debounce



/*
* Init slick login points slider
 */
function slickLoginPoints() {

    'use strict';

    if ($('.nhsIconGrid').length) {

        slickIt('.nhsIconGrid', 1200, {
            dots : true,
            infinite : true,
            arrows : false,
            autoplay : true,
            autoplaySpeed : 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1200,
            fade: true,
            cssEase: 'linear',
            lazyLoad: 'ondemand',
            pauseOnFocus: false,
            pauseOnHover: false
        });
    }

}
