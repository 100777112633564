var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasProducts(_vm.products)
    ? _c(
        "section",
        { staticClass: "productSlider" },
        [
          _c(
            "Splide",
            { ref: "productSlider", attrs: { options: _vm.sliderOptions } },
            _vm._l(_vm.products, function (product) {
              return _c(
                "SplideSlide",
                { key: "img" + product.id },
                [
                  _c("product-card", {
                    attrs: {
                      product: product,
                      "in-stock": product.in_stock,
                      "from-price": product.from_price,
                      "product-url": product.url,
                      "product-image": product.image,
                      "basket-button-data": product.basket_button_data,
                      category: product.main_category,
                      "preview-only": true,
                      slideshow: false,
                      minimal: true,
                      classes: ["largeCard"],
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }