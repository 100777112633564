const molliePay = {
    init: function(method, data) {
        return $.post('/shop/checkout/mollie/payment/', {
            method,
            data,
        })
            .then(function(res) {
                return res.redirect || '/shop/checkout/mollie/process/';
            })
    },
};

Vue.component('mollie-pay-form', {
    props: {
        basket: {
            type: [Boolean, Object, Array],
            default: false
        }
    },
    data: function() {
        return {
            w: null,
            isBusy: false,
        };
    },
    mounted: function () {
        this.w = this.$refs.mollieframe.contentWindow;
    },
    methods: {
        handleSubmit: function () {
            const vm = this;

            if(vm.isBusy) return;

            this.w.mollie.createToken()
                .then(function(res) {
                    if(res.token) {
                        vm.isBusy = true;
                    } else {
                        return;
                    }

                    return molliePay.init('form', res.token)
                        .done(url => window.location.assign(url))
                        .fail(function() {
                            window.location.assign('/shop/checkout/mollie/process/');
                        });
                });
        }
    }
});

Vue.component('mollie-apay-btn', {
    props: ['basket'],
    data: function() {
        return {
            ready: false,
            config: null,
        };
    },
    mounted: function() {
        this.initBtn();
    },
    methods: {
        initBtn: function() {
            const vm = this;

            if (!window.ApplePaySession
                || !ApplePaySession.canMakePayments()) {
                return;
            }

            $.get('/shop/checkout/mollie/apay/init/')
                .done(function (res) {
                    vm.config = res;
                    vm.ready = true;

                    vm.$nextTick(function () {
                        $('#mollieAPayBtn').on('click', function(e) {
                            e.preventDefault();
                            vm.handleClick();
                        });
                    })
                });
        },
        handleClick: function() {
            const session = new window.ApplePaySession(3, this.config);

            session.onvalidatemerchant = e => {
                $.get('/shop/checkout/mollie/apay/validate/')
                    .done(res => {
                        session.completeMerchantValidation(res);
                    })
                    .fail(err => {
                        session.abort();
                        alert("Error fetching merchant session", err);
                    });
            };

            // session.onshippingmethodselected = e => {
            //     $.post('/shop/checkout/mollie/apay/update/', {
            //         shippingMethod: e.shippingMethod,
            //     })
            //         .done(function(res) {
            //             if(res.errors) {
            //                 res.errors = res.errors.map(o => new window.ApplePayError(o.code, o.field, o.message));
            //             }

            //             session.completeShippingMethodSelection(res);
            //         })
            //         .fail(function(e) {
            //             session.abort();
            //         });
            // };
            // session.onshippingcontactselected = e => {
            //     $.post('/shop/checkout/mollie/apay/update/', {
            //         shippingContact: e.shippingContact,
            //     })
            //         .done(function(res) {
            //             if(res.errors) {
            //                 res.errors = res.errors.map(o => new window.ApplePayError(o.code, o.field, o.message));
            //             }

            //             session.completeShippingContactSelection(res);
            //         })
            //         .fail(function() {
            //             session.abort();
            //         });
            // };

            session.onpaymentauthorized = e => {
                return molliePay
                    .init('apay', JSON.stringify(e.payment.token))
                    .done(function (url) {
                        session.completePayment({
                            status: session.STATUS_SUCCESS,
                        });

                        window.location.assign(url);
                    })
                    .fail(function(err) {
                        const res = {};

                        if(err.status == 400 || err.status == 401) {
                            try {
                                Object.assign(res, JSON.parse(err.responseText));
                            } catch (error) {

                            }
                        }

                        if(!res.errors || !res.errors.length) {
                            res.errors = [
                                {
                                    code: 'unknown',
                                    field: 'name',
                                    message: 'Something went wrong, please try again!'
                                }
                            ];
                        }

                        session.completePayment({
                            status: session.STATUS_FAILURE,
                            errors: res.errors.map(o => new window.ApplePayError(o.code, o.field, o.message)),
                        });
                    });
            };

            session.begin();
        }
    },
});
