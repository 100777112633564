$(document).ready(function() {

    'use strict';


    if($('.videoTabs').length) {
        videoTabs();
    }

});


function videoTabs() {

    'use strict';

    const $parent = $('.videoTabs'),
          $tabsNavItems = $parent.find('.tabsNavigation ul li a'),
          $sections = $parent.find('.tabSection'),
          $videos = $parent.find('.tabSection video');


    $videos.trigger('pause');

    $tabsNavItems.on('click', function(e){
        e.preventDefault();

        let $button = $(this);


        if(!$button.hasClass('animActive')) {

            let target = $button.attr('href'),
                $target = $(target);

            if($target.length) {

                $button.addClass('animActive');

                //Remove active
                $sections.removeClass('active');
                $tabsNavItems.removeClass('active');

                setTimeout(function(){

                    //Stop all videos
                    $videos.each(function () {
                        let $v = $(this);
                        $videos.trigger('pause');
                        $v[0].currentTime = 0;
                    });

                    //Add active
                    $target.addClass('active');
                    $button.addClass('active');

                    //Reset
                    $button.removeClass('animActive');

                    let $vdo = $target.find('video');
                    $vdo.trigger('play');

                }, 200);


            }


        }

    });


    //Event to start the first animation
    $(window).on('videoTabsStart', function(){

        if(!$parent.hasClass('hasLoaded')) {

            var $first = $parent.find('.tabSection.active').eq(0);

            if ($first.length) {
                $first.find('video').trigger('play');
            }

            $parent.addClass('hasLoaded');

        }

    });





    const videoTabsWaypoint = $('.videoTabs').eq(0).find('.sections').waypoint(function(direction) {
        $(window).trigger('videoTabsStart');
    }, {
        offset: '20%'
    });


}
