Vue.component('search', {

    created() {

        let vm = this;
        this.results = this.defaultResults;
    },
    mounted() {

        let vm = this;

        $(document).ready(() => {

            $(window).click(function () {
                vm.term = '';
            });

            $('.searchForm').click(function (event) {
                event.stopPropagation();
            });

            $('.searchForm').submit(function(e) {
                e.preventDefault();
            });

        });



    },
    props: [
        'action'
    ],
    data: function () {
        return {
            searching: false,
            defaultResults: {
                products: [],
                categories: [],
                pages: [],
                brands: [],
                doctor_surgeries: []
            },
            term: '',
            results: [],
            pageResultsEnabled: false,
            brandResultsEnabled: false,
        };
    },
    methods: {
        search: function () {

            this.searching = true;
            this.$http.get(this.action + '?term=' + this.term, {
                before: function (request) {
                    // abort previous request, if exists
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }
                    // set previous request on Vue instance
                    this.previousRequest = request;
                }
            }).then(function (response) {
                if (response.data.status === 'ok') {
                    this.results = response.data;
                    this.searching = false;
                }
            }, function (response) {
                //Catch failed request
            });

            //Close menu menu to avoid overlap
            this.closeMainMenu();

        },
        clearSearch: function () {
            this.term = '';
        },
        selectItem: function (selected_item, type) {
            selected_item.type = type;
            this.results = this.defaultResults;
            this.$dispatch('searchSelection', selected_item);
        },
        closeMainMenu: function () {

        },

        formatPrice: function(price) {

            const formattedPrice = parseFloat(price);

            if(isNaN(formattedPrice)) {
                return '0.00';
            }

            return formattedPrice.toFixed(2);

        }
    }

});



$(document).ready(function() {



    if($('.js_toggleSearch').length) {

        $('.js_toggleSearch').on('click', function(e){

            e.preventDefault();


            $(this).toggleClass('active');

        });

        if($('#closeSearch').length) {
            $('#closeSearch').on('click', function(e){

                e.preventDefault();
                $('.js_toggleSearch').removeClass('active');

            });
        }

    }
});
