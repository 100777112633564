<template>

    <div
            class="questionWrap"
            :class="validationClass"
            v-if="question.visible"
            :id="'question_wrap_' + question.id"
    >

        <div class="row">

            <label
                    :for="'question_' + question.id"
                    v-html="question.question_text"
                    class="questionText"
            ></label>

            <div class="inputWrap">

                <div class="subLabel" v-if="matchType('yes_no') || matchType('variations')">
                    Please select one
                </div> <!-- .subLabel -->

                <div class="subLabel" v-else-if="question.is_multiple === 1">
                    Please select all that apply
                </div> <!-- .subLabel -->

                <yes-no
                        v-if="matchType('yes_no')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="yesNo"
                        :multiuser-id="multiuserId"
                />

                <variations
                        v-else-if="matchType('variations')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="variations"
                        :multiuser-id="multiuserId"
                />

                <confirmation
                        v-else-if="matchType('confirmation')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="confirmation"
                        :multiuser-id="multiuserId"
                />

                <date-field
                        v-else-if="matchType('date')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="dateField"
                        :multiuser-id="multiuserId"
                />

                <number-field
                        v-else-if="matchType('number')"
                        :question="question"
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="number"
                        :multiuser-id="multiuserId"
                />

                <email-field
                        v-else-if="matchType('email')"
                        :question="question"
                        placeholder="example@website.com"
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="email"
                        :multiuser-id="multiuserId"
                />

                <image-upload
                        v-else-if="matchType('image')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="image"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('allergies')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.allergies"
                        placeholder="Add allergy"
                        :autocomplete-items="[]"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('medications')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.medications"
                        placeholder="Add medication"
                        :autocomplete-items="$store.state.medicationsList"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('conditions')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.conditions"
                        placeholder="Add condition"
                        :autocomplete-items="$store.state.medicalConditionList"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <textarea-field
                        v-else-if="matchType('textarea')"
                        :question="question"
                        placeholder=""
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="text"
                        :multiuser-id="multiuserId"
                />

                <notify-gp
                        v-else-if="matchType('notify_gp')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="notifyGP"
                        :multiuser-id="multiuserId"
                />

                <bmi-calculator
                        v-else-if="matchType('bmi')"
                        :question="question"
                        :set-type="question.bmi_field_values.type"
                        :set-height-cm="question.bmi_field_values.height_cm"
                        :set-weight-kg="question.bmi_field_values.weight_kg"
                        :set-height-feet="question.bmi_field_values.height_feet"
                        :set-height-inches="question.bmi_field_values.height_inches"
                        :set-weight-stone="question.bmi_field_values.weight_stone"
                        :set-weight-pounds="question.bmi_field_values.weight_pounds"
                        @update-question="updateQuestion"
                        ref="bmi"
                        :multiuser-id="multiuserId"
                />

                <weight-field
                        v-else-if="matchType('weight')"
                        :question="question"
                        :set-type="question.weight_field_values.type"
                        :set-weight-kg="question.weight_field_values.weight_kg"
                        :set-weight-stone="question.weight_field_values.weight_stone"
                        :set-weight-pounds="question.weight_field_values.weight_pounds"
                        @update-question="updateQuestion"
                        ref="weight"
                        :multiuser-id="multiuserId"
                />

                <travel-users
                        v-else-if="matchType('travel_users')"
                        :question="question"
                        placeholder="Enter traveller's full name"
                        ref="travel_users"
                        @update-question="updateQuestion"
                />

                <travel-dates
                        v-else-if="matchType('travel_dates')"
                        :question="question"
                        ref="travel_dates"
                        @update-question="updateQuestion"
                />

                <travel-destinations
                        v-else-if="matchType('travel_destinations')"
                        :question="question"
                        ref="travel_destinations"
                        @update-question="updateQuestion"
                />

                <template v-if="!matchType('travel_destinations') && !matchType('travel_dates') && !matchType('travel_users')">

                    <template v-if="multiuserId">

                        <input type="hidden"
                               :name="'change_count[' + multiuserId + '][' + question.original_id + ']'"
                               v-model="question.answer_change_count"
                        />
                    </template>

                    <template v-else>
                        <input type="hidden"
                               :name="'change_count[' + question.id + ']'"
                               v-model="question.answer_change_count"
                        />
                    </template>

                </template>

            </div> <!-- .inputWrap -->

            <div class="formError" v-if="hasError">
                <p v-html="errorMessage"></p>
            </div> <!-- .tester -->

        </div> <!-- .row -->

    </div> <!-- .questionWrap -->

</template>

<script>


import _ from 'lodash';
import yesNo from '../fields/yes_no';
import variations from '../fields/variations';
import confirmation from '../fields/confirmation';
import dateField from '../fields/date_field';
import numberField from '../fields/number_field';
import weightField from '../fields/weight_field';
import emailField from '../fields/email_field';
import imageUpload from '../fields/image_upload';
import tagInput from '../fields/tag_input';
import textareaField from '../fields/textarea_field';
import notifyGp from '../fields/notify_gp';
import bmiCalculator from '../fields/bmi_calculator';

import travelUsers from '../fields/travel_users';
import travelDates from '../fields/travel_dates';
import travelDestinations from '../fields/travel_destinations';

export default {
    name: 'questionWrap',
    components: {
        yesNo,
        variations,
        confirmation,
        dateField,
        numberField,
        weightField,
        emailField,
        imageUpload,
        tagInput,
        textareaField,
        notifyGp,
        bmiCalculator,
        travelUsers,
        travelDates,
        travelDestinations
    },
    props: {
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return 0;
            }
        },
        travelQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserId: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            changed: false,
            defaultErrorMessages: {
                email: 'To continue, please enter a valid email address',
                yes_no: 'To continue, please select an option',
                textarea: 'To continue, please fill out this text box',
                number: 'To continue, please enter a number',
                weight: 'To continue, please enter a number',
                conditions: 'To continue, please enter your conditions into the box provided',
                medications: 'To continue, please enter your current medications into the box provided',
                allergies: 'To continue, please enter any allergies you have in the box provided',
                notify_gp: 'To continue, please search and add your GP\s address or enter it manually',
                image: 'To continue, please upload an image',
                variations: 'To continue, please select an option',
                confirmation: 'To continue, please select an option from below',
                bmi: 'To continue, please enter your height and weight',
                date: 'To continue, please select a valid date',
                travel_users: 'To continue, please add travellers using their full name(s)',
                travel_dates: 'To continue, please enter your travel departure and return dates',
                travel_destinations: 'To continue, please enter your travel destinations'
            }
        }
    },

    computed: {

        hasError() {
            return this.question.has_error || (this.question.question_type == 'image' && this.question?.file_error?.length);
        },

        errorMessage() {

            let error = this.defaultErrorMessages[this.question.question_type];

            if(this.question.question_type == 'image') {

                if(this.question?.file_error?.length) {
                    error = this.question.file_error;
                }

            }else if(!_.isUndefined(this.question.error_message)) {

                if(this.question.error_message.length) {
                    error = this.question.error_message;
                }
            }

            return error;

        },

        question() {

            return this.getQuestion();

        },

        validationClass() {

            let question = this.getQuestion();

            let cssClass = [];

            if(question.has_error) {
                cssClass.push('hasError');
            }else if(question.can_proceed && this.changed) {
                cssClass.push('isValid');
            }

            return cssClass;
        }

    },
    methods: {

        getQuestion() {

            if(this.multiuserQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'questions', this.multiuserId);

            }else if(this.travelQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'travel_questions');

            }else{

                return this.getQuestionByKey('id', this.questionId);

            }

        },

        matchType(type) {

            let question = this.getQuestion();

            if(!_.isUndefined(question.question_type)) {
                return question.question_type == type;
            }else{
                return false;
            }
        },

        updateQuestion(question) {

            this.changed = true;

            question = this.setErrors(question);

            //Define the external update if required
            if(typeof question.externalUpdate == 'undefined') {
                question.externalUpdate = false;
            }

            //Only update if the call comes from outside of a field external update
            if(!question.externalUpdate) {
                question.answer_change_count++;
            }

            //Reset external update
            question.externalUpdate = false;


            if(this.multiuserQuestion) {

                this.$store.dispatch('updateAssessmentMultiuserQuestion', question, this.multiuserId);

            }else if(this.travelQuestion) {

                this.$store.dispatch('updateAssessmentTravelQuestion', question);

            }else{

                this.$store.dispatch('updateAssessmentQuestion', question);

            }

            this.updateVisibility(question);

            if(this.multiuserQuestion) {
                this.$emit('update-errors', {
                    multiuserId: this.multiuserId
                });
            }

        },

        setErrors(question) {

            if(question.can_proceed) {
                question.has_error = false;
            }else{
                question.has_error = true;
            }

            return question;
        },

        updateVisibility(question) {


            let externalQs;

            if(this.multiuserQuestion) {
                externalQs = this.getQuestionByKey('show_if_question', question.id, true, 'questions', this.multiuserId);
            }else{
                externalQs = this.getQuestionByKey('show_if_question', question.id, true);
            }

            if(externalQs.length) {

                externalQs.forEach(externalQ => {

                    if((externalQ.show_if_answer == question.answer) || (question.is_multiple && question.answers.indexOf(externalQ.show_if_answer) > -1) ) {
                        externalQ.visible = true;
                    }else{
                        externalQ.visible = false;
                    }

                    if(this.multiuserQuestion) {
                        this.$store.dispatch('updateAssessmentMultiuserQuestion', question, this.multiuserId);
                    }else {
                        this.$store.dispatch('updateAssessmentQuestion', externalQ);
                    }

                });


            }


        },

        getQuestionByKey(key, value, multiple, type, multiUserID) {


            const _this = this;

            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }

            if(_.isUndefined(multiUserID)) {
                multiUserID = false;
            }


            let question = false,
                questions = [];


            if(multiUserID) {


                for (const userIndex in _this.$store.state.assessment.multiusers) {

                    const user = _this.$store.state.assessment.multiusers[userIndex];

                    if(user.id == multiUserID) {

                        for (const questionIndex in user[type]) {

                            let q = _this.$store.state.assessment.multiusers[userIndex][type][questionIndex];

                            if (q.hasOwnProperty(key)) {

                                if (q[key] == value) {
                                    question = q;
                                    questions.push(q);
                                }

                            }

                        }

                    }

                }


            }else {

                for (let sIndex in _this.$store.state.assessment.sections) {

                    for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                        let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                        if (q.hasOwnProperty(key)) {

                            if (q[key] == value) {
                                question = q;
                                questions.push(q);
                            }

                        }

                    }

                }

            }

            return multiple ? questions : question;

        },

        externalValidation() {

            const _this = this;

            if( !_.isEmpty(this.$refs) && !_.isUndefined(this.$refs)) {

                for(const prop in this.$refs) {

                    if(!_.isUndefined(_this.$refs[prop])) {
                        _this.$refs[prop].externallyValidate();
                    }

                }


            }

        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .questionWrap {

        background: transparent;
        text-align: center;
        padding: 0 0 50px;

        &.hasError {

        }

        &.isValid {

            .labelButton {

                &.isChecked {

                    position: relative;

                    &:after {
                        content: url('data:image/svg+xml,<svg id="5a14f085-89cd-4253-8007-37227588e93f" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 13 13"><defs><linearGradient id="876adf48-c7f7-4116-a69e-f4186c64a8d0" x1="2.2" y1="15.22" x2="10.8" y2="-0.22" gradientTransform="matrix(1, 0, 0, -1, 0, 14)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23ea9087"/><stop offset="1" stop-color="%23d9776d"/></linearGradient></defs><title>tick-reduced</title><path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.5,6.5,0,0,0,6.5,0ZM8.72,4.7a.5.5,0,0,1,.71,0,.51.51,0,0,1,0,.71L5.88,9a.51.51,0,0,1-.36.15A.5.5,0,0,1,5.17,9L3.4,7.18a.5.5,0,1,1,.71-.71L5.53,7.89Z" style="fill:url(%23876adf48-c7f7-4116-a69e-f4186c64a8d0)"/></svg>') !important;
                        width: 13px;
                        height: 13px;
                        display: inline-block;
                        position: absolute;
                        top: 9px;
                        right: 14px;
                        z-index: 4;
                    }
                }
            }

        }

        .row {
            margin: 0;
            position: relative;
            float: none;
            clear: both;
        }

        .inputWrap {
            margin: 0;
            padding: 48px 0 0;
            position: relative;
            float: none;
            clear: both;
        }

        .subLabel {
            .rems(12);
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.24px;
            color: @text_color;
            opacity: 0.5;
            padding: 0 0 20px;
        }

        .questionText {

            color: inherit;
            margin: 0;
            position: relative;
            float: none;
            clear: both;

            .rems(20);
            font-style: normal;
            font-weight: 400;
            line-height: 30px;

            & > p {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
                margin: 0 0 12px;

                &:first-of-type {
                    transition: color 200ms linear;
                }
            }

            p {
                &:last-of-type {
                    margin: 0;
                }
            }

            ul, ol {

                text-transform: none;
                margin: 0;
                padding: 24px 0 0 30px;
                text-align: left !important;

                &:before, &:after {
                    display: none;
                }

                li {
                    .rems(14);
                    line-height: 1.2em;
                    font-weight: 500;
                    padding: 0 0 12px 5px;
                }
            }

            ul {

                padding: 24px 0 0 14px;

                li {
                    list-style: none;
                    position: relative;
                    .rems(14);
                    line-height: 1.2em;
                    font-weight: 500;
                    padding: 0 0 12px 22px;

                    @svg_color: escape(@primary_color);

                    &:before {
                        content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' %3E%3Cpath d='M11.3671 3.75949H8.24051V0.632911C8.24051 0.278481 7.94939 0 7.60759 0H4.39241C4.03797 0 3.75949 0.291117 3.75949 0.632911V3.75949H0.632911C0.278481 3.75949 0 4.05061 0 4.39241V7.60759C0 7.96203 0.291117 8.24051 0.632911 8.24051H3.75949V11.3671C3.75949 11.7215 4.05061 12 4.39241 12H7.60759C7.96203 12 8.24051 11.7089 8.24051 11.3671V8.26582H11.3671C11.7215 8.26582 12 7.97471 12 7.63291V4.41772C12 4.05059 11.7089 3.75947 11.3671 3.75947V3.75949Z' fill='@{svg_color}'/%3E%3C/svg%3E");
                        color: @primary_color;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 0;
                        top: 0;
                        margin-top: 1.5px;
                        display: inline-block;
                    }
                }
            }
        }

        .formError {
            color: @alert_color;
            padding: 4px 0 8px 0;
            text-transform: none;

            p {
                margin: 0;
            }
        }

        .row {

            .radioButtons {

                display: flex;
                flex-direction: column;
                position: relative;
                float: none;
                clear: both;
                backface-visibility: hidden;

                .labelButton {
                    padding: 0 0 15px;

                    &:last-of-type {
                        padding: 0;
                    }
                }

                .labelButton > input[type="radio"], input[type="radio"], .labelButton > input[type="checkbox"], input[type="checkbox"] {

                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    left: -9999px;

                    & + label {
                        padding: 8px 42px;
                        .rems(14) !important;
                        line-height: 22px !important;
                        font-weight: 400;
                        text-align: center;
                        float: none;
                        clear: both;
                        backface-visibility: hidden;
                        border-radius: 10px;
                        width: auto;
                        min-width: 41px;
                        transition: all 200ms linear;
                        display: block;
                        color: @text_color;
                        border: 1.5px solid #fff;
                        background: #fff !important;

                        &:hover, &:active, &:focus {
                            color: @text_color;
                            border-color: @primary_color;
                            background: #fff !important;
                        }


                        &:before, &:after {
                            display: none !important;
                        }

                    }

                    &:checked {

                        & + label {

                            color: @text_color;
                            border-color: @primary_color;
                            background: #fff !important;
                            transition: all 200ms linear;

                            &:hover, &:active, &:focus {
                                color: @text_color;
                                border-color: @primary_color;
                                background: #fff !important;
                            }
                        }


                    }


                }



            }

            input:not([type='checkbox']), input:not([type='radio']), textarea, select, .chosen-search input {

                width: 100%;
                padding: 8px 42px;
                border: 1.5px solid @primary_color;
                .rems(14);
                line-height: 22px;
                border-radius: 10px;
                transition: all 200ms linear;
                background: #fff;
                .placeholder(fade(@text_color, 40%));
                color: @text_color;
                min-height: 41px;
                font-weight: 400;
                text-transform: none;
                letter-spacing: normal;
                text-align: center;

                &:focus {
                    border-color: @primary_color;
                    transition: all 200ms linear;
                }

            }

            select {
                min-height: 41px;
            }

            textarea {
                float: none;
                clear: both;
                min-height: 140px;
                text-align: left !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
            }

            .tagInput {

                .vue-tags-input {

                    width: 100%;
                    max-width: 100% !important;
                    background: transparent !important;

                    input[type='text'] {
                        border: none !important;
                        padding: 0 !important;
                        border-radius: 0 !important;
                        background: transparent !important;
                        text-align: left !important;
                    }

                    .ti-input {
                        border: 1.5px solid #fff;
                        border-radius: 10px;
                        padding: 3px 15px;
                        background: #fff;
                        min-height: 41px;
                        transition: all 200ms linear;

                        .ti-tags {

                            .ti-tag {
                                background: @primary_color;
                            }

                            .ti-tag-center {
                                span {
                                    position: relative;
                                    top: -1px;
                                }
                            }

                        }
                    }

                    &.ti-focus {
                        .ti-input {
                            border-color: @primary_color;
                            transition: all 200ms linear;
                        }
                    }


                }


            }

        }

    }



</style>
