var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fileUpload" }, [
    _vm.showPreview
      ? _c("div", { staticClass: "imagePreview" }, [
          _c(
            "a",
            {
              staticClass: "removeButton",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.removeImage()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle-xmark"] },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "imageWrap" }, [
            _c("img", {
              attrs: { src: _vm.question.answer_file, alt: "Preview Image" },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uploadField" }, [
      _c(
        "label",
        { attrs: { for: "id_" + _vm.question.id } },
        [
          _vm._v("\n            Upload Image "),
          _c("font-awesome-icon", { attrs: { icon: ["far", "upload"] } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.fieldType === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question.answer,
                expression: "question.answer",
              },
            ],
            attrs: {
              name: _vm.questionName,
              id: "id_" + _vm.question.id,
              accept: "image/*",
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.question.answer)
                ? _vm._i(_vm.question.answer, null) > -1
                : _vm.question.answer,
            },
            on: {
              input: function ($event) {
                return _vm.imagePreview($event)
              },
              change: [
                function ($event) {
                  var $$a = _vm.question.answer,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.question, "answer", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.question,
                          "answer",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.question, "answer", $$c)
                  }
                },
                function ($event) {
                  return _vm.updateQuestion(_vm.question)
                },
              ],
            },
          })
        : _vm.fieldType === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question.answer,
                expression: "question.answer",
              },
            ],
            attrs: {
              name: _vm.questionName,
              id: "id_" + _vm.question.id,
              accept: "image/*",
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.question.answer, null) },
            on: {
              input: function ($event) {
                return _vm.imagePreview($event)
              },
              change: [
                function ($event) {
                  return _vm.$set(_vm.question, "answer", null)
                },
                function ($event) {
                  return _vm.updateQuestion(_vm.question)
                },
              ],
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question.answer,
                expression: "question.answer",
              },
            ],
            attrs: {
              name: _vm.questionName,
              id: "id_" + _vm.question.id,
              accept: "image/*",
              type: _vm.fieldType,
            },
            domProps: { value: _vm.question.answer },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.question, "answer", $event.target.value)
                },
                function ($event) {
                  return _vm.imagePreview($event)
                },
              ],
              change: function ($event) {
                return _vm.updateQuestion(_vm.question)
              },
            },
          }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bypass_validation[]" },
        domProps: {
          value: _vm.question.file_already_set ? _vm.question.id : "",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }