var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.warning.visible,
          expression: "warning.visible",
        },
      ],
      staticClass: "warningWrap",
    },
    [
      _c("div", {
        staticClass: "warningBox",
        class: _vm.cssClass,
        domProps: { innerHTML: _vm._s(_vm.warning.question_text) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }