$(document).ready(function () {
    'use strict';


    if($('.statsList').length) {

        $('.statsList').waypoint({
            handler: function () {
                statsCounter();
            },
            offset: '80%'
        });

    }




}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';


})); // debounce



function statsCounter() {

    'use strict';


    if( $('.statsList > li .js_animateStats').length) {


        var $statsList = $('.statsList'),
            $stats = $('.statsList > li .js_animateStats'),
            animationDuration = $statsList.attr('data-anim-speed');

        if(!$statsList.hasClass('animated')) {

            $statsList.addClass('animated');

            if (!animationDuration) {
                animationDuration = 2000;
            }

            animationDuration = parseInt(animationDuration);

            if (!isNaN(animationDuration)) {

                $stats.each(function () {

                    var $this = $(this),
                        countTo = $this.attr('data-stat-value');

                    //Set widths
                    $this.text(countTo);
                    setTimeout(function () {

                        $this.parent().css('width', $this.parent().outerWidth());
                        $this.text('0');

                        $({countNum: $this.text()}).animate({
                                countNum: countTo
                            },
                            {
                                duration: animationDuration,
                                easing: 'linear',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    $this.parent().css('width', 'auto');
                                }

                            }
                        );
                    }, 100);
                });
            }

        }
    }

}
