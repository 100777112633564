<template>

    <section class="benefitsSlider" v-if="hasBenefits(benefits)">

        <Splide
            :options="sliderOptions"
            ref="benefitsSlider"
        >

            <SplideSlide
                v-for="(benefit, index) in benefits"
                :key="'img' + index"
            >
                <div class="benefitSlideWrap">
                    <a :href="benefit['Benefit URL'].value">
                        <div class="benefitImgWrap">
                            <img :src="'/' + imagePath + '/' + benefit.Image.value" :alt="benefit.Image.alt"/>
                        </div>
                        <span class="benefitTitleWrap">
                            {{ benefit.Benefit.value }}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 9" fill="none">
                              <path d="M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z" fill="currentColor"/>
                            </svg>
                        </span>
                    </a>
                </div>
            </SplideSlide>

        </Splide>

    </section>

</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: "BenefitsSlider",
    components: {
        Splide,
        SplideSlide
    },
    props: {
        benefits: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        imagePath: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            sliderOptions: {
                gap: 25,
                rewind: false,
                pagination: false,
                arrows: true,
                fixedWidth: 304,
                perMove: 1,
                mediaQuery: 'max',
                breakpoints : {
                    640: {
                        fixedWidth: 304,
                        perMove: 1,
                        gap: 16
                    },
                }
            },
        }
    },

    created() {

    },
    mounted() {

    },
    computed: {


    },
    methods: {


        hasBenefits(benefits) {

            if(typeof benefits != 'undefined') {
                return benefits.length;
            }

            return false;

        }

    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';
@import '@splidejs/splide/dist/css/splide.min.css';


.benefitsSlider {

    width: 100%;
    position: relative;
    float: none;
    clear: both;
    padding: 0 0 75px;


    .benefitSlideWrap {
        width: 100%;
        max-width: 304px;
        text-align: center;

        .benefitImgWrap {
            margin: 0 0 25px;
            border-radius: 15px;
            overflow: hidden;

            img {
                max-height: 200px;
                max-width: 304px;
                height: auto;
                width: auto;
            }
        }

        .benefitTitleWrap {
            background: #ffffff;
            border-radius: 50px;
            color: @text_color;
            .rems(12);
            line-height: 14px;
            padding: 10px 13px;

            svg {
                display: inline-block;
                height: 10px;
                width: 9px;
                margin: 0 0 0 12px;
            }
        }
    }

    .splide__track {
        overflow: visible;
    }

    .splide__arrows {
        //position: absolute;
        //bottom: auto;
        //top: 100%;
        //left: 0;
        //right: auto;
        //display: flex;
        //justify-content: flex-start;
        //align-items: center;
        //padding: 24px 0 0;
        //
        //@media @mobile {
        //    display: none;
        //}
    }

    .splide__arrow {
        width: 48px;
        height: 48px;
        background: transparent;
        border-radius: 0;
        //position: relative;
        //top: auto;
        //right: auto;
        //bottom: auto;
        //left: auto;
        //transform: none;
        display: flex;
        align-items: center;
        opacity: 1;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;

        &:after {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        &.splide__arrow--prev {
            //justify-content: flex-start;
            left: -15px;
        }

        &.splide__arrow--next {
            //margin-left: 1px;
            //justify-content: flex-end;
            right: -15px;
        }

        &[disabled] {
            opacity: 0;
            pointer-events: none;
        }
    }


}
</style>