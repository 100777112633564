var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.question.answer,
        expression: "question.answer",
      },
    ],
    attrs: {
      type: "number",
      name: _vm.questionName,
      id: "id_" + _vm.question.id,
      autocomplete: "off",
    },
    domProps: { value: _vm.question.answer },
    on: {
      blur: function ($event) {
        return _vm.updateQuestion(_vm.question)
      },
      input: [
        function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.question, "answer", $event.target.value)
        },
        function ($event) {
          return _vm.updateQuestion(_vm.question, true)
        },
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }