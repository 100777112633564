<template>

    <div 
            class="productListWrap" 
            :class="[loading ? 'isLoading' : '', ...parentClass]"
            :id="parentId"
    >

        <ul 
                class="productList" 
                :class="[...listClass]"
                v-if="hasProducts(products)"
        >

            <template v-for="product in products">

                <li>

                    <product-card
                            :product="product"
                            :in-stock="product.in_stock"
                            :from-price="product.from_price"
                            :product-url="product.url"
                            :product-image="product.image"
                            :basket-button-data="product.basket_button_data"
                            :category="product.main_category"
                            :preview-only="cardPreviews"
                            :slideshow="false"
                            :minimal="minimal"
                            :class="[...cardClass]"
                            :use-trustpilot="true"
                    ></product-card>

                </li>

            </template>

        </ul>

        <div class="noProducts" v-else>
            <h4 class="noProductsTitle">No products found</h4>
        </div> <!-- .noProducts -->

    </div> <!-- .browseProducts -->


</template>

<script>


export default {
    name: "ProductList",
    components: {
    },
    props: {
        parentId: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        parentClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        listClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        cardClass: {
            type: [Array],
            default (rawProps) {
                return [];
            }
        },
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        loading: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        minimal: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        cardPreviews: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
       
        }
    },
    methods: {

        hasProducts(products) {

            if(typeof products != 'undefined') {
                return products.length;
            }

            return false;

        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productList {
        .itemsList();
    }
    
    .noProducts {
        display: flex;
        justify-content: center;
        padding: 48px 0;

        .noProductsTitle {
            text-align: center;
            margin: 0;
            .rems(18);
            line-height: 24px;
        }
    }

</style>
