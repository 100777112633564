var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stockWrap" }, [
    _c("div", { staticClass: "stockBubble", class: _vm.cssClasses }, [
      _vm.icon
        ? _c("i", { staticClass: "svgIcon" }, [
            _vm.hasStock
              ? _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 13 13",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { id: "Group 1100" } }, [
                      _c("path", {
                        attrs: {
                          id: "Subtract",
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0ZM8.71708 4.6969C8.91298 4.50112 9.23067 4.50099 9.42632 4.6969C9.62222 4.89279 9.62222 5.21048 9.42632 5.40624L5.87963 8.95306C5.78174 9.05107 5.65325 9.10001 5.52488 9.10001C5.39651 9.10001 5.26816 9.05095 5.17027 8.95306L3.39693 7.17972C3.20102 6.98382 3.20102 6.66626 3.39693 6.47036C3.59283 6.27445 3.91038 6.27445 4.10629 6.47036L5.52502 7.88908L8.71708 4.6969Z",
                          fill: "white",
                        },
                      }),
                    ]),
                  ]
                )
              : _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 13 13",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", { attrs: { id: "Group 1576 1" } }, [
                      _c("path", {
                        attrs: {
                          id: "Subtract",
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M1.90381 1.90381C4.44222 -0.634602 8.55779 -0.634602 11.0962 1.90381C13.6346 4.44221 13.6346 8.55779 11.0962 11.0962C8.55779 13.6346 4.44222 13.6346 1.90381 11.0962C-0.634597 8.55779 -0.634597 4.44221 1.90381 1.90381ZM4.20191 5.12114C3.94807 4.8673 3.94807 4.45574 4.20191 4.2019C4.45576 3.94806 4.86731 3.94806 5.12115 4.2019L6.5 5.58076L7.87886 4.2019C8.13271 3.94806 8.54426 3.94806 8.7981 4.2019C9.05195 4.45575 9.05195 4.86729 8.7981 5.12114L7.41924 6.5L8.7981 7.87886C9.05195 8.13271 9.05195 8.54425 8.7981 8.7981C8.54426 9.05194 8.13271 9.05194 7.87886 8.7981L6.5 7.41924L5.12115 8.7981C4.86731 9.05194 4.45576 9.05194 4.20191 8.7981C3.94806 8.54425 3.94807 8.1327 4.20191 7.87886L5.58077 6.5L4.20191 5.12114Z",
                          fill: "white",
                        },
                      }),
                    ]),
                  ]
                ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasStock
        ? _c("span", { staticClass: "bubbleText" }, [_vm._v("In Stock")])
        : _c("span", { staticClass: "bubbleText" }, [_vm._v("Out of Stock")]),
      _vm._v(" "),
      _vm.showPrice && _vm.price
        ? _c(
            "span",
            { staticClass: "fromPrice" },
            [_c("multi-currency-price", { attrs: { price: _vm.price } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }