var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSidebar" },
    [
      _c(
        "div",
        { staticClass: "itemTitle" },
        [
          _c("stock-badge", {
            attrs: {
              "has-stock": _vm.inStock,
              price: _vm.fromPrice,
              "show-price": false,
              "currency-symbol": "£",
              small: false,
              icon: true,
              bubble: true,
              "css-class": ["productPage"],
            },
          }),
          _vm._v(" "),
          _vm.product.name
            ? _c("h1", {
                staticClass: "mainTitle",
                domProps: { innerHTML: _vm._s(_vm.product.name) },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-to-basket", {
        attrs: {
          "basket-button-data": _vm.basketButtonData,
          product: _vm.product,
          "show-description": true,
        },
      }),
      _vm._v(" "),
      !_vm.inStock
        ? _c("out-of-stock-notify", {
            attrs: {
              "url-submit": _vm.outOfStockUrl,
              "button-label": "Notify me when back in stock",
              "shop-item-id": _vm.product.id,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }