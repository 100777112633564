<template>

    <div class="optionButtons radioButtons">
        
        <div class="labelButton" :class="[question.answer == 'Yes' ? 'isChecked' : '']">

            <input type="radio"
                   :name="questionName"
                   :id="'radio_2_' + question.id"
                   value="Yes"
                   v-model="question.answer"
                   :checked="question.answer == 'Yes'"
                   @change="updateQuestion(question)"
            />

            <label :for="'radio_2_' + question.id" class="">Yes</label>

        </div> <!-- .labelButton -->

        <div class="labelButton" :class="[question.answer == 'No' ? 'isChecked' : '']">

            <input type="radio"
                   :name="questionName"
                   :id="'id_1_' + question.id"
                   value="No"
                   v-model="question.answer"
                   :checked="question.answer == 'No'"
                   @change="updateQuestion(question)"
            />

            <label :for="'id_1_' + question.id" class="">No</label>

        </div> <!-- .labelButton -->

    </div> <!-- .optionButtons -->
    
</template>

<script>

import _ from 'lodash';

export default {
    name: "yesNo",
    components: {
       
    },
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        multiuserId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    computed: {
        questionName() {
            if(this.multiuserId) {
                return 'questions[' + this.multiuserId + '][' + this.question.original_id + ']';
            }
            return 'questions[' + this.question.id + ']';
        }
    },
    methods: {
        
        updateQuestion: _.debounce(function(question) {
            question = this.validate(question);
            this.$emit('update-question', question);
        }, 200),

        validate(question) {

            let canProceed = true;
            
            if (!question.answer.length && question.is_required) {
                canProceed = false;
            }

            question.can_proceed = canProceed;
            question.can_proceed = this.canProceed(question, canProceed);

            return question;

        },

        canProceed(question) {

            if(question.cant_proceed_if.length) {

                let blocked = [];

                if(question.is_multiple === 1) {

                    blocked = question.answers.filter(answer => {

                        return question.cant_proceed_if.indexOf(answer) > -1 ? question : false;

                    });

                }else{

                    if(question.cant_proceed_if.indexOf(question.answer) > -1) {
                        blocked.push(question);
                    }

                }

                if(blocked.length) {
                    question.can_proceed = false;
                    question.error_message = 'Based on your answer, we cannot offer you medications for this condition.'
                }

            }

            return question.can_proceed;

        },

        externallyValidate() {
            let question = this.question;
            question = this.validate(question);
            question.externalUpdate = true;
            this.$emit('update-question', question);
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .assessmentFormContent {
        
        .optionButtons.radioButtons {
            @media @mobile {
                flex-wrap: wrap;
            }
            
            .labelButton {
                @media @mobile {
                    flex: 0 0 100%;
                    padding: 0 0 12px 0;
                }
                
                label {
                    @media @mobile {
                        width: 100%;
                    }
                }
            }
        }
        
    }
    

</style>
