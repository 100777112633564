/* ------------------------------ */
/* Zurbs Responsive Tables - all you need to do is add the class .responsive to your table  */
/* ------------------------------ */

function mtcResponsiveTables(options) {

    'use strict';

    var defaultOptions = {
        breakpoint: 640
    },
    yourOptions = $.extend({}, defaultOptions, options),
    switched = false,
    updateTables = function () {
        if (($(window).width() < yourOptions.breakpoint) && !switched) {
            switched = true;
            $('table.responsive').each(function (i, element) {
                splitTable($(element));
            });
            return true;
        } else if (switched && ($(window).width() > yourOptions.breakpoint)) {
            switched = false;
            $('table.responsive').each(function (i, element) {
                unsplitTable($(element));
            });
        }
    };

    $(window).on('load', updateTables);
    $(window).on('redraw', function () {
        switched = false;
        updateTables();
    }); // An event to listen for
    $(window).on('resize', updateTables);


    function splitTable(original) {
        original.wrap('<div class="tableWrapper" />');

        var copy = original.clone();
        copy.find('td:not(:first-child), th:not(:first-child)').css('display', 'none');
        copy.removeClass('responsive');

        original.closest('.tableWrapper').append(copy);
        copy.wrap('<div class="pinned" />');
        original.wrap('<div class="scrollable" />');

        setCellHeights(original, copy);
    }

    function unsplitTable(original) {
        original.closest('.tableWrapper').find('.pinned').remove();
        original.unwrap();
        original.unwrap();
    }

    function setCellHeights(original, copy) {
        var tr = original.find('tr'),
            tr_copy = copy.find('tr'),
            heights = [];

        tr.each(function (index) {
            var self = $(this),
                tx = self.find('th, td');

            tx.each(function () {
                var height = $(this).outerHeight(true);

                heights[index] = heights[index] || 0;

                if (height > heights[index]) {
                    heights[index] = height;
                }
            });

        });

        tr_copy.each(function (index) {
            $(this).height(heights[index]);
        });
    }
}
