var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "datePickerWrapper" }, [
    _c("input", {
      staticClass: "datePicker",
      class: _vm.inputClass,
      attrs: {
        type: "text",
        id: _vm.id,
        name: _vm.name,
        placeholder: _vm.placeholder ? _vm.placeholder : "dd/mm/yyyy",
      },
      on: {
        input: function ($event) {
          return _vm.updateDate()
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      staticClass: "datePickerVueAlt",
      staticStyle: { display: "none" },
      attrs: { type: "text", id: _vm.id_alt },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }