<template>
    
  <main 
          class="assessmentForm" 
          :class="[loading ? 'isLoading' : '']"
  >

      
      <form-content 
            :url-get-multiuser-questions="urlGetMultiuserQuestions"
            :url-submit-assessment="urlSubmitAssessment"
            :url-get-allowed-products="urlGetAllowedProducts"
            :url-add-to-basket="urlAddToBasket"
            :form-id="form.id"
            :loading="loading"
            :category-id="categoryId"
            :product-id="productId"
      >
          
          <template slot="timer">
              <slot name="timer"></slot>
          </template>
          
      </form-content>

      <div class="loading">

          <loading-spinner
                  :loading="loading"
                  :loading-text="loadingMessage"
                  :show-loading-text="true"
          ></loading-spinner>
          
      </div> <!-- .loading -->
      
      
  </main>
    
</template>

<script>

import formSidebar from './elements/form_sidebar';
import formContent from './elements/form_content';

export default {
    name: 'assessmentForm',
    components: {
        formSidebar,
        formContent
    },
    props: {
        prescribingDoctorName: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        setForm: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {};
            }
        },
        setSections: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        userSurgery: {
            type: [Object],
            default (rawProps) {
                return {
                    practice_name: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    postcode: '',
                    phone: '',
                    id: ''
                };
            }
        },
        userHealthProfile: {
            type: [Object, Array],
            default (rawProps) {
                return {
                    medications: '',
                    allergies: '',
                    conditions: ''
                };
            }
        },
        medicalConditionList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        medicationsList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        urlGetMultiuserQuestions: {
            type: String,
            default: '/missing-url-get-multiuser-questions',
        },
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },

    },
    data() {
        return {
            loading: false,
            form: {},
            defaultLoadingMessage: 'Loading',
            loadingMessage: 'Loading'
        }
    },
    created() {
        
        this.form = this.setForm;
        
        //Store prescribing doctor
        this.$store.dispatch('setPrescribingDoctorName', this.prescribingDoctorName);
        
        //Store user data
        this.$store.dispatch('setUserSurgery', this.userSurgery);

        //Store health profile
        this.$store.dispatch('setUserHealthProfile', this.userHealthProfile);

        //Store list of medications for predictive inputs
        this.$store.dispatch('setMedicationsList', this.medicationsList);

        //Store list of conditions for predictive inputs
        this.$store.dispatch('setMedicalConditionList', this.medicalConditionList);

        //Store form type and ids
        this.$store.dispatch('setAssessmentType', this.form.form_type);
        this.$store.dispatch('setAssessmentProductID', this.form.product_id);
        this.$store.dispatch('setAssessmentCategoryID', this.form.category_id);
        this.$store.dispatch('setAssessmentTravelForm', this.form.travel_form);
        
        
        //Store sections
        this.$store.dispatch('setAssessmentSection', this.setSections);


        //Test
        //this.$store.dispatch('showAssessmentSection', 998);

        //Set initial progress
        this.$store.dispatch('updateProgress', 0);
        
    },
    methods: {
        setLoading(loading, message) {
            
            if(_.isUndefined(message)) {
                message = this.defaultLoadingMessage;
            }
            
            this.loadingMessage = message;
            this.loading = loading;
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    
    .assessmentForm {
        display: flex;
        flex-wrap: wrap;
        min-height: 80vh;
        color: @text_color;
        position: relative;
        background-color: @light_color;
        background-image: url(/sites/default/images/backgrounds/assessment-blobs.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        .loading {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
            background: rgba(255,255,255,0.5);
            backdrop-filter: blur(8px);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 200ms linear, visibility 200ms linear;

            .loadingSpinner {
                position: sticky;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                display: none;
            }
            
            .loadingText {
                margin-top: -24px;
            }
        }
        

        &.isLoading {

            .loading {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 200ms linear, visibility 200ms linear;

                .loadingSpinner {
                    display: inline-block;
                }
            }

        }
    }
    

</style>
