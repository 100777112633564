var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasBenefits(_vm.benefits)
    ? _c(
        "section",
        { staticClass: "benefitsSlider" },
        [
          _c(
            "Splide",
            { ref: "benefitsSlider", attrs: { options: _vm.sliderOptions } },
            _vm._l(_vm.benefits, function (benefit, index) {
              return _c("SplideSlide", { key: "img" + index }, [
                _c("div", { staticClass: "benefitSlideWrap" }, [
                  _c("a", { attrs: { href: benefit["Benefit URL"].value } }, [
                    _c("div", { staticClass: "benefitImgWrap" }, [
                      _c("img", {
                        attrs: {
                          src: "/" + _vm.imagePath + "/" + benefit.Image.value,
                          alt: benefit.Image.alt,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "benefitTitleWrap" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(benefit.Benefit.value) +
                          "\n                        "
                      ),
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 10 9",
                            fill: "none",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z",
                              fill: "currentColor",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }