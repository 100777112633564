<template>

    <div class="warningWrap" v-show="warning.visible">

        <div 
                :class="cssClass"
                class="warningBox"
                v-html="warning.question_text"
        ></div> 
        
    </div>
    
</template>

<script>

export default {
    name: "warningWrap",
    components: {
       
    },
    props: {
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return false;
            }
        },
        multiuserQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserId: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return 0;
            }
        },
    },
    computed: {
        
        warning() {
            return this.getQuestion();
        },
        
        cssClass() {
            let warning = this.getQuestion();
            return [warning.question_type];
        }
        
    },
    methods: {
        getQuestion() {

            if(this.multiuserQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'questions', this.multiuserId);

            }else{

                return this.getQuestionByKey('id', this.questionId);

            }

        },
        
        getQuestionByKey(key, value, multiple, type, multiUserID) {


            const _this = this;

            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }

            if(_.isUndefined(multiUserID)) {
                multiUserID = false;
            }


            let question = false,
                    questions = [];


            if(multiUserID) {


                for (const userIndex in _this.$store.state.assessment.multiusers) {

                    const user = _this.$store.state.assessment.multiusers[userIndex];

                    if(user.id == multiUserID) {

                        for (const questionIndex in user.questions) {

                            let q = _this.$store.state.assessment.multiusers[userIndex].questions[questionIndex];

                            if (q.hasOwnProperty(key)) {

                                if (q[key] == value) {
                                    question = q;
                                    questions.push(q);
                                }

                            }

                        }

                    }

                }


            }else {

                for (let sIndex in _this.$store.state.assessment.sections) {

                    for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                        let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                        if (q.hasOwnProperty(key)) {

                            if (q[key] == value) {
                                question = q;
                                questions.push(q);
                            }

                        }

                    }

                }

            }

            return multiple ? questions : question;

        },
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .warningWrap {
        
        background: transparent;
        padding: 0;
        border: none;
        border-radius: 10px;
        margin: 0 0 25px;
        
        .warningBox {
            background: #fff !important;
        }

        .success, .error, .alert, .alertBlue, .info {
            padding: 15px 42px;
            text-align: center;
            border-radius: 10px;
        }
        
    }
    
    

</style>
