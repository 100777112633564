var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "outOfStockNotifyWrapper" }, [
    _c(
      "div",
      { staticClass: "outOfStockBox", class: [_vm.isBusy ? "loading" : ""] },
      [
        !_vm.didSubmit
          ? [
              _c("div", { staticClass: "innerStockBox" }, [
                _c("h4", { staticClass: "stockTitle" }, [
                  _vm._v("Get stock notifications"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "stockFormWrap" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("label", { attrs: { for: "stockEmail" } }, [
                      _vm._v("Email address:"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      attrs: {
                        name: "email",
                        type: "email",
                        placeholder: "Email address",
                        id: "stockEmail",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "button",
                    attrs: {
                      type: "submit",
                      disabled: !_vm.isEmailValid || _vm.isBusy,
                    },
                    domProps: { textContent: _vm._s(_vm.buttonLabel) },
                    on: { click: _vm.submitOutOfStockNotifyForm },
                  }),
                ]),
              ]),
            ]
          : [_vm._m(1)],
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "loadingOverlay" },
          [
            _c("loading-spinner", {
              attrs: {
                loading: _vm.isBusy,
                "loading-text": _vm.loadingMessage,
                "show-loading-text": false,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stockContent" }, [
      _c("p", [
        _vm._v(
          "Enter your email address below and we will notify you when this item is back in stock."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "innerStockBox" }, [
      _c("h4", { staticClass: "stockTitle" }, [_vm._v("Thank you.")]),
      _vm._v(" "),
      _c("div", { staticClass: "stockContent" }, [
        _c("p", [
          _vm._v(
            "Your email has been registered and we will email you when the product is back in stock."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }