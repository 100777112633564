<template>

    <div class="productListings">
        
        
        <template v-show="selectionEnabled">

            <template v-show="hasRecommendedItems">
                
                <h2 class="productListings__title" v-html="recommendedTreatmentsTitle"></h2>

                <slot name="timer"></slot>
                
                <div class="productListings__products" :class="[hasSelectionError ? 'hasError' : '']">

                    <ul class="productList">

                        <template v-for="product in products" v-if="product.recommended || groupAllProducts">

                            <template v-if="product.sizes.length > 0 && individualSizes">

                                <li v-for="size in product.sizes" :key="'size_id_' + size.id">
                                    <product-card
                                            :product="product"
                                            use-trustpilot="true"
                                            :in-stock="sizeHasStock(size)"
                                            :from-price="sizePrice(size)"
                                            :preview-only="false"
                                            :minimal="false"
                                            :show-description="true"
                                            :option-card="true"
                                            :option-quantity-adjust="$store.state.assessment.travel_form"
                                            :option-size="size"
                                            :option-show-size="true"
                                            :option-enabled="isSizeEnabled(size)"
                                            option-button-text="Select this treatment"
                                            option-deselect-button-text="Remove selection"
                                            @option-selected="optionSizeSelected"
                                            @option-deselected="optionSizeDeselected"
                                            :key="'product_size_' + size.id"
                                    ></product-card>

                                </li>

                            </template>

                            <template v-else>
                                <li :key="'product_id_' + product.id">


                                    <product-card-large
                                            :product="product"
                                            :in-stock="product.in_stock"
                                            :from-price="product.from_price"
                                    ></product-card-large>
                                    
                                </li>
                            </template>

                        </template>

                    </ul>
                    
                </div> <!-- .productListings__products -->

            </template>

            <template v-if="hasOtherSuitableItems && !groupAllProducts">

                <h2 class="productListings__title" v-html="suitableTreatmentsTitle"></h2>

                <div class="productListings__products" :class="[hasSelectionError ? 'hasError' : '']">

                    <ul class="productList">

                        <template v-for="product in products" v-if="!product.recommended">

                            <template v-if="product?.sizes?.length">

                                <li v-for="size in product.sizes" :key="'size_id_' + size.id">

                                    <product-card
                                            :product="product"
                                            :in-stock="sizeHasStock(size)"
                                            :from-price="sizePrice(size)"
                                            :preview-only="false"
                                            :minimal="false"
                                            :show-description="true"
                                            :option-card="true"
                                            :option-size="size"
                                            :option-quantity-adjust="$store.state.assessment.travel_form"
                                            :option-show-size="true"
                                            :option-enabled="isSizeEnabled(size)"
                                            option-button-text="Select this treatment"
                                            option-deselect-button-text="Remove selection"
                                            @option-selected="optionSizeSelected"
                                            @option-deselected="optionSizeDeselected"
                                            :key="'product_size_' + size.id"
                                    ></product-card>

                                </li>
                            </template>

                            <template v-else>
                                <li :key="'product_id_' + product.id">

                                    <product-card
                                            :product="product"
                                            :in-stock="product.in_stock"
                                            :from-price="product.from_price"
                                            :preview-only="false"
                                            :minimal="false"
                                            :option-card="true"
                                            :option-quantity-adjust="$store.state.assessment.travel_form"
                                            option-button-text="Select this treatment"
                                            option-deselect-button-text="Remove selection"
                                            @option-selected="optionSelected"
                                            @option-deselected="optionDeselected"
                                    ></product-card>
                                </li>
                            </template>

                        </template>

                    </ul>

                </div> <!-- .productListings__products -->


            </template>

            <template v-if="!products?.length">

                <h2 class="productListings__title" v-html="noProductsFoundTitle"></h2>

                <div class="noTreatments">
                    <p>
                        Unfortunately we cannot offer you any treatments for this condition. <br>
                        Please feel free to <a href="/contact" title="Contact us for help">contact us</a> for more information.
                    </p>
                </div> <!-- .noTreatments -->


            </template>
            
           
        </template>

      
        
    </div> <!-- .productList -->
    
</template>

<script>

import _ from 'lodash';
import collapseArea from './collapse_area';

export default {
    name: "productListings",
    components: {
        collapseArea
    },
    props: {
        selectionEnabled: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        multiuserId: {
            type: [String, Boolean, Number],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            sectionCollapsed: true,
            hasSelectionError: false,
            groupAllProducts: true,
            individualSizes: false
        }
    },
    computed: {
        hasRecommendedItems() {
            
            let exists = false;
            
            for(const prop in this.products) {

                if (this.products[prop].recommended === true || this.groupAllProducts) {
                    exists = true;
                }
                
            }
            
            return exists;
            
        },
        hasOtherSuitableItems() {
        
            let exists = false;

            for(const prop in this.products) {
                if (this.products[prop].recommended === false) {
                    exists = true;
                }
            }

            return exists;
        },
        recommendedTreatmentsTitle() {
            
            return 'Recommended treatments' + this.multiUserPostfix();
            
        },
        suitableTreatmentsTitle() {
            return 'Other suitable treatments' + this.multiUserPostfix();
        },
        selectionConfirmationTitle() {
            return 'Confirm your selection' + this.multiUserPostfix();
        },
        noProductsFoundTitle() {
            return 'No suitable treatments found' + this.multiUserPostfix();
        },
        noSuitableSelectedTitle() {
            return 'No suitable treatments selected' + this.multiUserPostfix();
        },
        productsAvailable() {
            return Object.keys(this.products).length;
        },
    },
    methods: {

        multiUserPostfix() {
            if(this.multiuserId) {
                const fullname = this.getMultiuserFullName(this.multiuserId);
                
                if(fullname) {
                    return ' for ' + fullname;
                }
            }
            
            return '';
        },
        
        getMultiuserFullName(multiuserID) {
            
            const multiusers = this.$store.state.assessment.multiusers;
            
            if(!_.isEmpty(multiusers)) {
                
                let userFullname = '';
                
                for(const prop in multiusers) {
                    
                    const user = multiusers[prop];
                    
                    if(user.id == multiuserID) {
                        userFullname = user.name;
                    }
                    
                }
                
                if(userFullname.length) {
                    return userFullname;
                }
                
            }
            
            return false;
            
        },
        
        collapse() {
            this.sectionCollapsed = !this.sectionCollapsed;
        },
        
        optionSelected(productID) {
            this.setProductSelected(productID, true);
        },

        optionDeselected(productID) {
            this.setProductSelected(productID, false);
        },
        
        setProductSelected(productID, selected) {
            
            const _this = this;
            
            for(const prop in _this.products) {
                
                if (_this.products[prop].id == productID) {

                    _this.products[prop].selected = selected;

                }
                
            }

            let productUserProp = 0;

            if(this.multiuserId) {
                productUserProp = this.multiuserId;
            }


            this.$store.dispatch('setAssessmentSelectedProducts', {
                productProp: productUserProp,
                products: _this.products
            });

            this.updateSelectedProductsError();
 
            this.$emit('option-changed');
            
        },

        optionSizeSelected(productID, sizeID) {
            this.setSizeSelected(productID, sizeID, true);
        },

        optionSizeDeselected(productID, sizeID) {
            this.setSizeSelected(productID, sizeID, false);
        },

        setSizeSelected(productID, sizeID, selected) {

            const _this = this;

            for(const prop in _this.products) {

                if(productID > 0 && _this.products[prop].id == productID) {
     
                        for(const sizeProp in _this.products[prop].sizes) {
                
                            if( (sizeID > 0) && (_this.products[prop].sizes[sizeProp].id == sizeID) ) {
                         
                                _this.products[prop].sizes[sizeProp].selected = selected;
                                
                            }

                        }
                        

                }

            }

            let productUserProp = 0;
            
            if(this.multiuserId) {
                productUserProp = this.multiuserId;
            }
            
            this.$store.dispatch('setAssessmentSelectedProducts', {
                productProp: productUserProp,
                products: _this.products
            });
        
            this.updateSelectedProductsError();
            
            this.$emit('option-changed');
        },

        sizeHasStock(size) {
            
            const stock = parseInt(size.stock);
            
            if(!isNaN(stock)) {
                
                return stock > 0
                
            }
            return false;
            
        },
        
        sizePrice(size) {
            
            let price = parseFloat(size.price),
                  salePrice = parseFloat(size.sale_price);

            price = !isNaN(price) ? price : 0;
            salePrice = !isNaN(salePrice) ? salePrice : 0;
            
            if(salePrice < price && salePrice > 0) {
                return salePrice;
            }

            return price;
            
        },

        isSizeEnabled(size) {
            
            const hasStock = this.sizeHasStock(size),
                  price = parseFloat(this.sizePrice(size));
            
            return hasStock && (price > 0);
            
        },

        updateSelectedProductsError() {
            
            if(!_.isUndefined(this.$store.state.assessment.selected_products[this.multiuserId])) {
                
                const selectedItems = this.$store.state.assessment.selected_products[this.multiuserId];

                if (Object.keys(selectedItems).length) {
                    this.hasSelectionError = false;
                    return false;
                }

            }

            this.hasSelectionError = true;
            return true;

        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .productListings {
        
        margin: 0 0 25px;

        .deliveryTimer {
            max-width: 527px;
            margin: 0 auto 42px auto;
            padding: 15px 25px;
            gap: 18px;
            
            @media @mobile {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            
            .timerLabel {
                @media @mobile {
                    flex: 0 0 100%;
                }
            }
            
            .timer {
                @media @mobile {
                    flex: 0 0 100%;
                    display: flex;
                    justify-content: center;
                }
            }
            
            .countdown-row  {
                
                .countdown-section {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    flex-direction: row;
                    
                    .countdown-amount {
                        margin-top: -4px;
                    }
                    .countdown-period {
                        
                    }
                }
            }
        }
        
        &__title {
            text-align: center;
            margin-bottom: 29px;
        }
        
        .productList {
            
            margin: 0;
            float: none;
            clear: both;
            display: block;
            width: 100%;
            
            &:before, &:after {
                display: none;
            }
            
            & > li {
                list-style: none;
                display: block;
                width: 100%;
                margin: 0 0 35px;
            }
            
            
        }
        
        .noTreatments {
            padding: 24px 0;
            text-align: center;
            .rems(16);
            line-height: 1.2em;
            font-weight: 700;
            
        }
        
        
    }
    
    

</style>
