<template>
    <div class="totalRow deliveryDate" v-if="show_datepicker">
        <span class="costTitle">Want your order delivered on a specific date?</span>
        <datepicker
            id="deliveryDateInput"
            name="delivery_date"
            v-model="selected_date"
            @input="setDeliveryDate()"
            :options="{
                minDate: start_date,
                maxDate: '+2M',
                showOtherMonths: true,
                selectOtherMonths: true,
                beforeShowDay: beforeShowDayHandler
            }"
        ></datepicker>
    </div>
</template>

<script>

import moment from 'moment';

export default {
    name: 'delivery-date',
    props: {
        basket: Object
    },
    data: function () {
        return {
            disabled_dates: [],
            selected_date: "",
            start_date: 3, // default
            time_cutoff: 18, // 24 hours
            delivery_method_id: 64,
            show_datepicker: true,
            existing_date: this.basket.delivery_date,
            today_date: moment().format('YYYY-MM-DD')
        }
    },
    methods: {
        dateFormat(d) {
          return moment(d).format('YYYY-MM-DD');
        },
        getDatepickerElement() {
            return this.$el.querySelector('#deliveryDateInput');
        },
        beforeShowDayHandler(date) {
            return [
                this.disabled_dates.indexOf( this.dateFormat(date) ) === -1,
                ''
            ];
        },
        setDateHandler(d) {
            const el = $(this.getDatepickerElement());
            el.datepicker("setDate", d);
            el.datepicker("option", {minDate: this.start_date});

            // check if it falls under disabled dates
            const getDefaultDate = el.datepicker("getDate");
            if ( this.isDisabledDate(getDefaultDate) ) {
                this.start_date = this.start_date+1;
                this.setDateHandler(this.start_date);
            } else {
                this.setDeliveryDate(this.dateFormat(getDefaultDate));
            }
        },
        setDeliveryDate(date = '') {
            if ( date !== '' ) {
                this.basket.delivery_date = date;
            } else {
                this.basket.delivery_date = this.selected_date;
            }
        },
        isDisabledDate(d) {
            return this.disabled_dates.indexOf(this.dateFormat(d)) !== -1;
        }
    },
    mounted() {
        let isTimerCutOff = false;
        let refreshMinDate = false;
        this.disabled_dates = this.basket.disabledDates;
        const vm = this;

        // visibility depending on method selected
        if ( vm.basket.delivery_selected === vm.delivery_method_id ) {

            vm.show_datepicker = false;
            vm.setDeliveryDate();

        } else {

            // logic for time cutoff
             if ( parseInt(moment().format("H")) >= vm.time_cutoff ) {
                 vm.start_date = vm.start_date+1; // plus 1 to start_date
                 isTimerCutOff = true;
                 refreshMinDate = true;
             }

            // select a default date on initial load to bypass required
            let defaultDate = vm.start_date;
            if ( vm.existing_date !== null && vm.existing_date !== '' && vm.existing_date !== '0000-00-00' ) {

                const a = moment(vm.today_date);
                const b = moment(vm.existing_date);
                const diff = parseInt(a.to(b, true));

                if ( isTimerCutOff && diff < vm.start_date ) {
                    defaultDate = moment(vm.existing_date, 'YYYY-MM-DD').add(1, 'days').format('DD/MM/YYYY');
                    refreshMinDate = true;
                } else {
                    defaultDate = moment(vm.existing_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    refreshMinDate = false;
                }

            }
            vm.setDateHandler(defaultDate);

        }
    }
}
</script>