var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "assessmentForm", class: [_vm.loading ? "isLoading" : ""] },
    [
      _c(
        "form-content",
        {
          attrs: {
            "url-get-multiuser-questions": _vm.urlGetMultiuserQuestions,
            "url-submit-assessment": _vm.urlSubmitAssessment,
            "url-get-allowed-products": _vm.urlGetAllowedProducts,
            "url-add-to-basket": _vm.urlAddToBasket,
            "form-id": _vm.form.id,
            loading: _vm.loading,
            "category-id": _vm.categoryId,
            "product-id": _vm.productId,
          },
        },
        [_c("template", { slot: "timer" }, [_vm._t("timer")], 2)],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "loading" },
        [
          _c("loading-spinner", {
            attrs: {
              loading: _vm.loading,
              "loading-text": _vm.loadingMessage,
              "show-loading-text": true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }