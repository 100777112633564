<template>
    
  <div class="assessmentSidebar">

      <div class="sidebarWrap">


          <form-card 
                  :image="formImage"
                  :title="formTitle"
          />
          
          <section-navigation 
                  :sections="sections"
          />
          
      </div> <!-- .sidebarWrap -->
    
  </div>
    
</template>

<script>

import { mapGetters } from 'vuex';
import sectionNavigation from './section_navigation';
import formCard from './form_card';

export default {
    name: "formSidebar",
    components: {
        sectionNavigation,
        formCard
    },
    props: {
        formImage: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    src: '',
                    alt: ''
                };
            }
        },
        formTitle: {
            type: [String],
            default (rawProps) {
                return '';
            }
        }
    },
    computed: {
        ...mapGetters({
            sections: 'getAssessmentSections'
        })
    },
    methods: {
        
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

   
    .assessmentSidebar {
        grid-area: sidebar;
        background-color: @light_color;
        display: flex;
        justify-content: flex-end;
        padding: 50px 75px 50px 0;

        @media all and (max-width: 1390px) {
            padding: 50px;
        }

        @media all and (max-width: 1020px) {
            width: 100%;
            flex: 0 0 100%;
            padding: 35px 50px;
        }

        @media @mobile {
            padding: 35px;
        }
        
        @media all and (max-width: 400px) {
            padding: 35px 25px;
        }

        .sidebarWrap {
            width: 100%;
            max-width: 337px;
            margin-left: auto;

            @media all and (max-width: 1390px) {
                max-width: 100%;
                width: 100%;
                margin: 0;
            }
        }
        
        .sidebarSectionNav {
            position: sticky;
            top: 24px;
        }
        
    }
    

</style>
