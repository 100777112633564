var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass: "loadingSpinner isVisible",
          attrs: { "aria-label": _vm.loadingText },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.showLoadingText && _vm.loadingText.length
            ? _c("div", { staticClass: "loadingText" }, [
                _c("div", {
                  staticClass: "loadingTextWrap",
                  domProps: { innerHTML: _vm._s(_vm.loadingText) },
                }),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "innerSpinner" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }