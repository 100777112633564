$(document).ready(function () {
    'use strict';

    /*
     * Featured Brands
     */

    if ($('.featuredBrands li').length) {

        $('.featuredBrands').slick({
            dots: false,
            infinite: true,
            arrows: true,
            autoplay: true,
            slidesToShow: 7,
            slidesToScroll: 7,
            appendArrows: '.featuredBrandsWrap .featuredBrandsTitle span',
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }]
        });
    }

}); // document ready
