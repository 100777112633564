<template>

    <li
            class="menus"
            :class="[(megaMenu && !isMobile) ? 'megaMenuItem' : 'menuItem', menuItem.subs.length ? 'hasChildren' : '']"
            v-if="menuItem.published"
            @mouseleave="onMouseLeave"
            @mouseenter="onMouseEnter"
    >

        <a
                :href="menuItem.url"
                :target="menuItem.target"
                class="menuItemTop"
                :class="[megaMenuActive ? 'menuTopItemActive' : '']"
                @click="toggleMegaMenu"
        >
            {{ menuItem.name }}

            <i class="menuIcon menuIconOpen" v-if="menuItem.subs?.length && (!megaMenuActive || isMobile)">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/></svg>


            </i>

            <i class="menuIcon menuIconClose" v-if="menuItem.subs?.length && (megaMenuActive && !isMobile)">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"/></svg>


            </i>

        </a>


        <div class="dropdownWrap level1" v-if="menuItems?.length" :class="[megaMenu ? 'flyoutOnly' : '']">

            <ul>

                <template v-for="menuListItem in menuItems">

                    <li :class="[menuListItem.css_class, menuListItem.subs?.length ? 'hasChildren' : '']">
                        <a
                                :href="menuListItem.url"
                                :target="menuListItem.target"
                        >
                            {{ menuListItem.name }}

                            <i class="menuIcon" v-if="menuListItem.subs?.length">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/></svg>

                            </i>

                        </a>

                        <div class="dropdownWrap level2" v-if="menuListItem.subs?.length">

                            <ul>

                                <template v-for="menuListItem2 in menuListItem.subs">

                                    <li :class="[menuListItem2.css_class, menuListItem2.subs?.length ? 'hasChildren' : '']">

                                        <a
                                                :href="menuListItem2.url"
                                                :target="menuListItem2.target"
                                        >

                                            {{ menuListItem2.name }}

                                            <i class="menuIcon" v-if="menuListItem2.subs?.length">

                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/></svg>

                                            </i>
                                        </a>

                                        <div class="dropdownWrap level3" v-if="menuListItem2.subs?.length">

                                            <ul>

                                                <template v-for="menuListItem3 in menuListItem2.subs">

                                                    <li :class="[menuListItem3.css_class]">
                                                        <a
                                                                :href="menuListItem3.target"
                                                                :target="menuListItem3.target"
                                                                v-html="menuListItem3.name">
                                                        </a>
                                                    </li>

                                                </template>

                                            </ul>

                                        </div> <!-- .subMenu -->

                                    </li>

                                </template>

                            </ul>

                        </div> <!-- .subMenu -->

                    </li>

                </template>

            </ul>

        </div> <!-- .subMenu -->

        <div class="megaMenuWrapper" v-if="!isMobile && megaMenu && menuItems?.length" :class="[megaMenuActive ? 'megaMenuVisible' : '']">

            <div class="wrapper">

                <div class="menuColumns">

                    <template v-for="(column, columnIndex) in menuColumns">

                        <div class="menuColumn" :key="'menuColumn' + columnIndex" v-if="column.length && hasVisibleMenus(columnIndex)">

                            <template v-for="menuItems in column">

                                <ul class="menuList" v-if="menuItems.published" v-show="menuItems.visible">

                                    <template v-for="menuItem in menuItems.menus">

                                        <li :key="'menuItem' + menuItem.id" v-if="menuItem.published" :class="[activeMenus[columnIndex] == menuItem.id ? 'active' : '']">

                                            <a
                                                    :href="menuItem.url"
                                                    :target="menuItem.target"
                                                    @click.prevent="showSubMenu(columnIndex, menuItem.id)"
                                                    v-if="menuItem.subs.length"
                                            >
                                                {{ menuItem.name }}
                                                <i class="menuIcon" v-if="menuItem.subs?.length">

                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="Vector" d="M6.00749 13.5L0 7.70778L0 5.52667L5.1985 10.5389L5.1985 0.5L6.8015 0.5L6.8015 10.5533L12 5.54111V7.72222L6.00749 13.5Z" fill="#8497B5"/>
                                                    </svg>

                                                </i>
                                            </a>

                                            <a
                                                    :href="menuItem.url"
                                                    :target="menuItem.target"
                                                    v-else
                                            >
                                                {{ menuItem.name }}
                                                <i class="menuIcon" v-if="menuItem.subs?.length">

                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="Vector" d="M6.00749 13.5L0 7.70778L0 5.52667L5.1985 10.5389L5.1985 0.5L6.8015 0.5L6.8015 10.5533L12 5.54111V7.72222L6.00749 13.5Z" fill="#8497B5"/>
                                                    </svg>

                                                </i>
                                            </a>

                                        </li>

                                    </template>

                                </ul>

                            </template>


                        </div> <!-- .menuColumn -->

                    </template>

                </div> <!-- .menuColumns -->


            </div> <!-- .wrapper -->

        </div> <!-- .megaMenu -->


    </li> <!-- .menuContainer -->

</template>

<script>

import _ from 'lodash';

export default {

    name: 'mtcMenu',
    props: {
        menuItem: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        cssClass: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
    },
    data() {
        return {
            menuItems: [],
            megaMenu: false,
            menuColumns: [],
            megaMenuActive: false,
            activeMenus: [],
            isMobile: false,
            mobileBreakpoint: 1120
        }
    },
    created() {

        const _this = this;

        if(this.menuItem.subs.length) {
            this.menuItems = this.menuItem.subs;
        }

        //console.log(this.menuItems);
        if(this.cssClass?.length) {
            const megaRegEx = new RegExp('.*?megaMenu.*?', 'i');
            this.megaMenu = megaRegEx.test(this.cssClass) > -1;
            this.menuColumns = this.buildMenuColumns();
            this.showSubMenu(0,0);
        }

    },
    mounted() {

        const _this = this;

        if(window.innerWidth <= this.mobileBreakpoint) {
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }

        window.addEventListener('resize', () => {

            if(window.innerWidth <= _this.mobileBreakpoint) {
                _this.isMobile = true;
            }else{
                _this.isMobile = false;
            }

        });
    },
    methods: {

        buildMenuColumns() {

            let columns1 = [],
                columns2 = [],
                columns3 = [],
                columns4 = [];

            columns1.push({
                id: 0,
                parentID: 0,
                published: 1,
                visible: true,
                menus: this.addParentLinks(false, [...[], ...this.menuItems])
            });

            this.menuItems.forEach((menuItem1, index) => {


                if(menuItem1.subs?.length) {

                    columns2.push({
                        id: parseInt(menuItem1.id),
                        parentID: parseInt(menuItem1.parent_id),
                        published: parseInt(menuItem1.published),
                        visible: false,
                        menus: this.addParentLinks(menuItem1, menuItem1.subs)
                    });

                    menuItem1.subs.forEach(menuItem2 => {

                        if(menuItem2.subs?.length) {

                            columns3.push({
                                id: parseInt(menuItem2.id),
                                parentID: parseInt(menuItem2.parent_id),
                                published: parseInt(menuItem2.published),
                                visible: false,
                                menus: this.addParentLinks(menuItem2, menuItem2.subs)
                            });

                            menuItem2.subs.forEach(menuItem3 => {

                                if(menuItem3.subs?.length) {
                                    columns4.push({
                                        id: parseInt(menuItem3.id),
                                        parentID: parseInt(menuItem3.parent_id),
                                        published: parseInt(menuItem3.published),
                                        visible: false,
                                        menus: this.addParentLinks(menuItem3, menuItem3.subs)
                                    });
                                }

                            });

                        }

                    });

                }

            });

            return [
                    columns1,
                    columns2,
                    columns3,
                    columns4
            ]

        },

        addParentLinks(menuItem, subs) {

            if(menuItem) {

                let title = 'View ' + menuItem.name;

                let catIDNum = parseInt(menuItem.category_id),
                        catID = !isNaN(catIDNum) ? catIDNum : 0;

                let catList = 0,
                    catListID = 0;

                if(!_.isUndefined(menuItem.category_list)) {
                    catList = parseInt(menuItem.category_list);
                    catListID = !isNaN(catList) ? catList : 0;
                }

                if(catID > 0 || catListID > 0) {
                    title = 'View all';
                }


                let parentLink = {

                    id: -2,
                    parent_id: 0,
                    published: 1,
                    subs: [],
                    target: '_self',
                    name: title,
                    url: menuItem.url

                };

                subs.push(parentLink);

            }

            return subs;
        },

        showSubMenu(currentColumnIndex, parentID) {

            this.activeMenus[currentColumnIndex] = parentID;

            for(const colIndex in this.activeMenus) {
                if(colIndex > currentColumnIndex) {
                    this.activeMenus[colIndex] = 0;
                }
            }

            const nextIndex = currentColumnIndex + 1;

            if(!_.isUndefined(this.menuColumns[nextIndex])) {

                for(const menuIndex in this.menuColumns[nextIndex]) {

                    //Clear sub menus
                    for(const allMenuIndex in this.menuColumns) {
                        if(allMenuIndex > nextIndex) {
                            for(const i in this.menuColumns[allMenuIndex]) {
                                this.menuColumns[allMenuIndex][i].visible = false;
                            }
                        }
                    }

                    //Mark current as visible
                    if( this.menuColumns[nextIndex][menuIndex].id == parentID ) {
                        this.menuColumns[nextIndex][menuIndex].visible = true;
                    }else{
                        this.menuColumns[nextIndex][menuIndex].visible = false;
                    }


                }

            }


        },

        hasVisibleMenus(columnIndex) {

            const hasVisible = this.menuColumns[columnIndex].filter(menu => menu.visible);
            return hasVisible.length;

        },

        toggleMegaMenu(e) {

            if(this.megaMenu && !this.isMobile) {
                e.preventDefault();
                this.megaMenuActive = !this.megaMenuActive;
            }

        },

        onMouseLeave() {
            if(this.megaMenu && !this.isMobile) {
                this.megaMenuActive = false;
            }
        },

        onMouseEnter() {
            if(this.megaMenu && !this.isMobile) {
                //this.megaMenuActive = true;
            }
        }


    }

}

</script>

<style lang="less" scoped>
    @import '@site/css/variables.less';

    .headerInnerWrap {

        .mainMenu {

            li {
                &:hover {
                    & > .dropdownWrap {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 200ms linear, visibility 200ms linear;
                    }
                }
            }
        }

        .menus {

            font-size: inherit;
            line-height: inherit;
            font-weight: 700;

            &.menuItem {
                position: relative;
            }

            .dropdownWrap {

                a {
                    font-size: inherit;
                    line-height: inherit;
                    color: @flyout_link_color;
                    white-space: nowrap;

                    &:hover, &:active, &:focus, &.active {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }

                }
            }

            @media @above_flyout {

                .menuTopItemActive {
                    color: @highlight_color;

                    &:hover, &:focus, &:active {
                        color: darken(@highlight_color, 8%);
                    }
                }

                .dropdownWrap {
                    position: absolute;
                    border: 1.5px solid @secondary_color;
                    padding: 10px 0;
                    border-radius: 10px;
                    background: #fff;
                    min-width: 180px;

                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 200ms linear, visibility 200ms linear;

                    a {
                        padding: 0.7em 16px;
                        display: flex;
                        align-items: center;
                    }


                    &.level1 {
                        top: 100%;
                        left: 0;
                        margin-top: 16px;

                        &:before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 16px;
                            position: absolute;
                            right: 0;
                            left: 0;
                            bottom: 100%;
                        }

                        &.flyoutOnly {
                            display: none;
                        }

                    }

                    &.level2 {
                        top: -1.5px;
                        left: 100%;
                    }

                    &.level1, &.level2 {
                        .menuIcon {
                            //width: 8px;
                            //height: 8px;
                            //display: flex;
                            //align-items: center;
                            //justify-content: center;
                            //margin-left: 7px;
                            //transform: rotate(-90deg);
                            //
                            //svg {
                            //    width: 100%;
                            //    height: auto;
                            //}

                        }
                    }

                    &.level3 {
                        top: -3px;
                        left: 100%;
                    }

                    &.level2, &.level3 {

                        margin-left: 8px;

                        &:before {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 100%;
                            position: absolute;
                            right: 100%;
                            top: 0;
                        }
                    }
                }

                .megaMenuWrapper {
                    display: flex;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    height: auto;
                    margin-top: 0;
                    min-height: 400px;
                    width: 100%;
                    background: #fff;
                    border-bottom: 4px solid @secondary_color;
                    padding: 40px 0;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 300ms, visibility 300ms;

                    &.megaMenuVisible {

                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 300ms, visibility 300ms;

                    }

                    &:before {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.16);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                    }


                    .wrapper {
                        width: 100%;
                        display: flex;
                        height: 100%;
                        padding: 0 !important;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    .menuColumns {
                        display: grid;
                        grid-template-columns: repeat(4, 25%);
                        grid-gap: 0;
                        height: 100%;
                        width: 100%;
                    }

                    .menuColumn {
                        height: 100%;
                        border-right: 1.5px solid @secondary_color;

                        &:first-child {
                            padding-left: 0;

                            .menuList {
                                a {
                                    padding-left: 0;
                                }
                            }
                        }

                        &:last-child {
                            border-right: 1.5px solid transparent;
                        }
                    }

                    .menuList {

                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                        float: none;
                        clear: both;
                        flex: 0 0 100%;
                        height: 100%;

                        &:before, &:after {
                            display: none;
                        }

                        a {
                            padding: 0.9em 48px 0.9em 25px;
                            display: flex;
                            align-items: center;
                            position: relative;
                        }

                        li {
                            list-style: none;

                            &.active {
                                a {
                                    color: @highlight_color !important;
                                    transition: color 200ms linear;
                                }
                            }
                        }

                        .menuIcon {
                            position: absolute;
                            top: 50%;
                            right: 25px;
                            transform: rotate(-90deg) translateY(-50%);
                            margin: -3px 0 0 0;
                        }

                    }
                }

                &.megaMenuItem {
                    & > a {
                        position: relative;

                        &:after {
                            content: "";
                            display:none;
                            position: absolute;
                            top: -16px;
                            left: -24px;
                            right: -24px;
                            height: 63px;
                            width: calc(100% + 48px);
                            background: transparent;
                        }

                        &.menuTopItemActive {
                            &:after {
                                display: block;
                            }
                        }
                    }
                }

            }

            @media all and (max-width: (@wrapper_width + @page_side_padding)) {
                .megaMenuWrapper {
                    .wrapper {
                        padding: 0 @page_side_padding !important;
                    }
                }
            }

            @media @flyout {

                .dropdownWrap {

                    &.level1 {
                        padding: 0;

                        > ul {
                            //padding: 8px 0 8px 30px;

                            > li {
                                position: relative;

                                //&:before {
                                //    content: '•';
                                //    position: absolute;
                                //    left: -20px;
                                //    top: 5px;
                                //    font-size: 20px;
                                //    color: @secondary_color_2;
                                //}
                            }
                        }

                        &.flyoutOnly {
                            display: block;
                        }
                    }

                    &.level2, &.level3 {
                        padding: 6px 12px;
                        border-radius: 12px;
                        background: rgba(@secondary_color_2, 0.1);
                    }


                }

                .megaMenuWrapper {
                    display: none;
                }

            }

        }

    }
</style>
