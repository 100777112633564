function siteWideMessage() {
    'use strict';

    var selector = $('.siteWideMessage');

    if (matchesMediaQuery('mobile')) {

        if (selector.hasClass('slick-initialized')) {
            selector.slick('unslick');
        }

    } else if (!selector.hasClass('slick-initialized')) {

        selector.slick({
            // initial slide (reviews)
            initialSlide : 1,
            infinite : true,
            arrows : false,
            dots: false,
            autoplay : true,
            autoplaySpeed: 3500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        });
    }
}

$(document).ready(function() {
    'use strict';

    // if site wide message wrap exists
    if ($('.siteWideMessageWrap').length) {
        siteWideMessage();
    }


});

$(window).on('resize', debouncer(function (e) {
    'use strict';

    // if site wide message wrap exists
    if ($('.siteWideMessageWrap').length) {
        siteWideMessage();
    }

})); // debounce
