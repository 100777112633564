<template>

  <section class="productSlider" v-if="hasProducts(products)">

      <Splide
              :options="sliderOptions"
              ref="productSlider"
      >

          <SplideSlide
                  v-for="product in products"
                  :key="'img' + product.id"
          >

              <product-card
                      :product="product"
                      :in-stock="product.in_stock"
                      :from-price="product.from_price"
                      :product-url="product.url"
                      :product-image="product.image"
                      :basket-button-data="product.basket_button_data"
                      :category="product.main_category"
                      :preview-only="true"
                      :slideshow="false"
                      :minimal="true"
                      :classes="['largeCard']"
              ></product-card>

          </SplideSlide>

      </Splide>

  </section>

</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: "ProductSlider",
    components: {
        Splide,
        SplideSlide
    },
    props: {
        products: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        }
    },
    data() {
        return {
            sliderOptions: {
                gap: 25,
                rewind: false,
                pagination: false,
                arrows: true,
                fixedWidth: 238,
                perMove: 1,
                mediaQuery: 'max',
                breakpoints : {
                    640: {
                        fixedWidth: 282,
                        perMove: 1,
                        gap: 16
                    },
                }
            },
        }
    },

created() {

    },
    mounted() {

    },
    computed: {


    },
    methods: {


        hasProducts(products) {

            if(typeof products != 'undefined') {
                return products.length;
            }

            return false;

        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    @import '@splidejs/splide/dist/css/splide.min.css';


    .productSlider {

        width: 100%;
        position: relative;
        float: none;
        clear: both;
        padding: 0 0 56px;

        @media @mobile {
            padding: 0;
        }


        .productCard {
            width: 100%;
            min-width: 238px;
        }

        .splide__track {
            overflow: visible;
        }

        .splide__arrows {
            position: absolute;
            bottom: auto;
            top: 100%;
            left: 0;
            right: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 24px 0 0;

            @media @mobile {
                display: none;
            }
        }

        .splide__arrow {
            width: 48px;
            height: 48px;
            background: transparent;
            border-radius: 0;
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            transform: none;
            display: flex;
            align-items: center;
            opacity: 1;

            &.splide__arrow--prev {
                justify-content: flex-start;
            }

            &.splide__arrow--next {
                margin-left: 1px;
                justify-content: flex-end;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }


    }




</style>
