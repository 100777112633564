<template>

    <div class="stockWrap">

        <div class="stockBubble" :class="cssClasses">

            <i class="svgIcon" v-if="icon">
                <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hasStock">
                    <g id="Group 1100">
                        <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0ZM8.71708 4.6969C8.91298 4.50112 9.23067 4.50099 9.42632 4.6969C9.62222 4.89279 9.62222 5.21048 9.42632 5.40624L5.87963 8.95306C5.78174 9.05107 5.65325 9.10001 5.52488 9.10001C5.39651 9.10001 5.26816 9.05095 5.17027 8.95306L3.39693 7.17972C3.20102 6.98382 3.20102 6.66626 3.39693 6.47036C3.59283 6.27445 3.91038 6.27445 4.10629 6.47036L5.52502 7.88908L8.71708 4.6969Z" fill="white"/>
                    </g>
                </svg>
                <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                    <g id="Group 1576 1">
                        <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M1.90381 1.90381C4.44222 -0.634602 8.55779 -0.634602 11.0962 1.90381C13.6346 4.44221 13.6346 8.55779 11.0962 11.0962C8.55779 13.6346 4.44222 13.6346 1.90381 11.0962C-0.634597 8.55779 -0.634597 4.44221 1.90381 1.90381ZM4.20191 5.12114C3.94807 4.8673 3.94807 4.45574 4.20191 4.2019C4.45576 3.94806 4.86731 3.94806 5.12115 4.2019L6.5 5.58076L7.87886 4.2019C8.13271 3.94806 8.54426 3.94806 8.7981 4.2019C9.05195 4.45575 9.05195 4.86729 8.7981 5.12114L7.41924 6.5L8.7981 7.87886C9.05195 8.13271 9.05195 8.54425 8.7981 8.7981C8.54426 9.05194 8.13271 9.05194 7.87886 8.7981L6.5 7.41924L5.12115 8.7981C4.86731 9.05194 4.45576 9.05194 4.20191 8.7981C3.94806 8.54425 3.94807 8.1327 4.20191 7.87886L5.58077 6.5L4.20191 5.12114Z" fill="white"/>
                    </g>
                </svg>
            </i>

            <span class="bubbleText" v-if="hasStock">In Stock</span>
            <span class="bubbleText" v-else>Out of Stock</span>
            <span class="fromPrice" v-if="showPrice && price">
                <multi-currency-price :price="price"/>
            </span>

        </div>

    </div> <!-- .stockWrap -->

</template>

<script>
export default {
    name: "stock-badge",
    props: {
        hasStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        price: {
            type: [Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        showPrice: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        small: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        icon: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        bubble: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        cssClass: {
            type: [Array, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    computed: {

        cssClasses() {
            let classes = [];

            if(this.hasStock) {
                classes.push('inStock');
            }else{
                classes.push('noStock');
            }

            if(this.icon) {
                classes.push('hasIcon');
            }

            if(this.bubble) {
                classes.push('pill');
            }

            if(this.small) {
                classes.push('smallBadge');
            }

            if(this.cssClass) {
                this.cssClass.forEach(() => {
                    classes.push(this);
                });
            }

            return classes;
        }

    }
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .stockBubble {
        .rems(12);
        line-height: 14px;
        font-weight: 400;
        background: transparent;
        margin: 0 0 15px;

        &:first-of-type {
            margin-left: 0;
        }

        &.smallBadge {
            .rems(10);
        }

        &.hasIcon {
            white-space: nowrap;
        }

        .svgIcon {
            width: auto;
            height: auto;
            display: inline-block;
            text-align: center;
            margin: 0 8px 0 0;

            svg {
                position: unset;
                top: unset;
                left: unset;
                transform: unset;
                height: 12px;
                width: 12px;
            }
        }

        &.pill {
            .rems(12);
            line-height: 12px;
            padding: 8px 10px 8px 8px;
            border-radius: 100px;
            color: @dark_text_color;
            margin: 0;

            &.hasIcon {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 8px;
                width: auto;
                margin: 0;

                svg {
                    height: 13px;
                    width: 13px;
                }
            }

            &.inStock {
                background: @stock_bubble_color;
            }

            &.noStock {
                background: @stock_bubble_color;
                opacity: 0.5;
            }

            &.onSale {
                background: @alert_color;
            }
        }

        .bubbleText {
            white-space: nowrap;
        }

        & > .fromPrice {
            display: inline-block;
            position: relative;
            padding-left: 22px;

            &:before {
                content: '|';
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-top: -2px;
                margin-left: -1px;


            }
        }

        &.smallBadge {

            & > .fromPrice {

                &:before {
                    margin-top: 0;
                    margin-left: 0;
                }

            }

        }
    }

</style>
