var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.question.is_multiple === 1
    ? _c(
        "div",
        { staticClass: "optionButtons radioButtons" },
        _vm._l(_vm.question.variations, function (variation, v) {
          return _c(
            "div",
            {
              staticClass: "labelButton",
              class: [
                _vm.question.answers.indexOf(variation.id) > -1
                  ? "isChecked"
                  : "",
              ],
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.answers,
                    expression: "question.answers",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  name: _vm.questionName + "[]",
                  id: "id_variation_" + _vm.question.id + "_" + v,
                },
                domProps: {
                  value: variation.id,
                  checked: _vm.question.answer === variation.id,
                  checked: Array.isArray(_vm.question.answers)
                    ? _vm._i(_vm.question.answers, variation.id) > -1
                    : _vm.question.answers,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.question.answers,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = variation.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.question, "answers", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.question,
                              "answers",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.question, "answers", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.updateQuestion(_vm.question)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", {
                attrs: { for: "id_variation_" + _vm.question.id + "_" + v },
                domProps: { innerHTML: _vm._s(variation.label) },
              }),
            ]
          )
        }),
        0
      )
    : _c(
        "div",
        { staticClass: "optionButtons radioButtons" },
        _vm._l(_vm.question.variations, function (variation, v) {
          return _c(
            "div",
            {
              staticClass: "labelButton",
              class: [_vm.question.answer == variation.id ? "isChecked" : ""],
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.answer,
                    expression: "question.answer",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: _vm.questionName,
                  id: "id_" + v + "_" + _vm.question.id,
                },
                domProps: {
                  value: variation.id,
                  checked: _vm.question.answer == variation.id,
                  checked: _vm._q(_vm.question.answer, variation.id),
                },
                on: {
                  change: [
                    function ($event) {
                      return _vm.$set(_vm.question, "answer", variation.id)
                    },
                    function ($event) {
                      return _vm.updateQuestion(_vm.question)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", {
                attrs: { for: "id_" + v + "_" + _vm.question.id },
                domProps: { innerHTML: _vm._s(variation.label) },
              }),
            ]
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }