<template>
    <span class="multiPrice" v-if="currency" v-html="price_with_symbol"></span>
</template>
<script>
import collect from "collect.js";
export default {
    props: [
        'price'
    ],
    data() {
        return {
            ratios: {},
            active_currency: 'GBP'
        }
    },
    mounted() {
        this.ratios = window.active_currencies;
        this.active_currency = window.active_currency;

        window.vueEventHub.$on('currency-change', event => {
            this.active_currency = window.active_currency;
        });
    },
    computed: {
        currency() {
            return collect(this.ratios)
                    .where('currency', this.active_currency)
                    .first();
        },
        price_in_currency() {
            return (parseFloat(this.currency.ratio) * this.price).toFixed(2);
        },
        price_with_symbol() {
            return this.currency.symbol + (parseFloat(this.currency.ratio) * this.price).toFixed(2);
        }
    }
}
</script>
