var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "productCard", class: _vm.cssClasses }, [
    _c("header", { staticClass: "productHeader" }, [
      _vm.optionCard
        ? _c("div", { staticClass: "productTitleLink" }, [
            _vm.product.name
              ? _c("h2", {
                  staticClass: "productTitle",
                  domProps: { textContent: _vm._s(_vm.product.name) },
                })
              : _vm._e(),
          ])
        : _c(
            "a",
            {
              staticClass: "productTitleLink",
              attrs: { href: _vm.productUrl },
            },
            [
              _vm.product.name
                ? _c("h2", {
                    staticClass: "productTitle",
                    domProps: { textContent: _vm._s(_vm.product.name) },
                  })
                : _vm._e(),
            ]
          ),
      _vm._v(" "),
      _vm.category.name
        ? _c("h4", {
            staticClass: "categoryTitle",
            domProps: { textContent: _vm._s(_vm.category.name) },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "productImageSection" }, [
      _vm.slideshow
        ? _c(
            "div",
            { staticClass: "productCardImage" },
            [
              _c("product-images", {
                attrs: {
                  images: _vm.product.images,
                  "lazy-load": true,
                  size: "thumbs",
                  slideshow: true,
                  "show-arrows": true,
                  "show-thumbs": false,
                  "allow-zoom": false,
                },
              }),
            ],
            1
          )
        : _c(
            "a",
            {
              staticClass: "productCardImage",
              attrs: { href: _vm.productUrl },
            },
            [
              _c("product-images", {
                attrs: {
                  images: _vm.product.images,
                  "lazy-load": true,
                  size: "thumbs",
                  slideshow: false,
                  "show-arrows": false,
                  "show-thumbs": false,
                  "allow-zoom": false,
                },
              }),
            ],
            1
          ),
    ]),
    _vm._v(" "),
    _vm.optionCard
      ? _c(
          "footer",
          {
            staticClass: "productFooter optionsCardFooter",
            class: [
              _vm.optionQuantityAdjust ? "optionsFooterQuantityAdjust" : "",
            ],
          },
          [
            _c("div", { staticClass: "footerSizes" }, [
              _c(
                "div",
                {
                  staticClass: "sizeOptions",
                  class: [_vm.previewOnly ? "fullWidth" : ""],
                },
                [
                  _vm.optionSize.strength?.length &&
                  _vm.optionShowSize &&
                  !_vm.optionQuantityAdjust
                    ? _c(
                        "div",
                        { staticClass: "sizeOption sizeOptionStrength" },
                        [
                          _c("span", { staticClass: "sizeTitle" }, [
                            _vm._v("Strength"),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "sizeValue",
                            domProps: {
                              innerHTML: _vm._s(_vm.optionSize.strength),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.optionSize.size?.length &&
                  _vm.optionShowSize &&
                  !_vm.optionQuantityAdjust
                    ? _c(
                        "div",
                        { staticClass: "sizeOption sizeOptionStrength" },
                        [
                          _c("span", { staticClass: "sizeTitle" }, [
                            _vm._v("Size"),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "sizeValue",
                            domProps: {
                              innerHTML: _vm._s(_vm.optionSize.size),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "sizeOption sizeOptionStrength" }, [
                    _c("span", {
                      staticClass: "sizeTitle",
                      domProps: {
                        innerHTML: _vm._s(_vm.previewOnly ? "Quantity" : "QTY"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.optionSize
                      ? _c("span", {
                          staticClass: "sizeValue",
                          domProps: {
                            innerHTML: _vm._s(_vm.optionSize?.quantity || 1),
                          },
                        })
                      : _c("span", {
                          staticClass: "sizeValue",
                          domProps: { innerHTML: _vm._s(_vm.product.quantity) },
                        }),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            !_vm.previewOnly
              ? _c("div", { staticClass: "footerButtons" }, [
                  !_vm.optionSelected
                    ? _c("a", {
                        staticClass: "button noMargin",
                        attrs: {
                          href: "#select-option",
                          disabled: !_vm.optionEnabled,
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.inStock
                              ? _vm.optionSelectButtonText
                              : "Out of stock"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.triggerSelected()
                          },
                        },
                      })
                    : _c("a", {
                        staticClass: "button buttonOptionSelected noMargin",
                        attrs: {
                          href: "#deselectselect-option",
                          disabled: !_vm.optionEnabled,
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.optionDeselectButtonText),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.triggerDeselected()
                          },
                        },
                      }),
                ])
              : _vm._e(),
          ]
        )
      : _c("footer", { staticClass: "productFooter" }, [
          _c(
            "div",
            { staticClass: "footerBottom" },
            [
              !_vm.previewOnly
                ? _c("add-to-basket", {
                    attrs: {
                      "ajax-add-to-basket":
                        _vm.$store.state.minibasket.productCardsQuickAdd,
                      "basket-button-data": _vm.basketButtonData,
                      "browse-page": true,
                      "collapse-size": true,
                      product: _vm.product,
                    },
                  })
                : _c("a", {
                    staticClass: "button noMargin",
                    attrs: { href: _vm.product.url, title: "View product" },
                    domProps: { innerHTML: _vm._s(_vm.viewProductText) },
                  }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }