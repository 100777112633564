$(document).ready(function () {

    'use strict';


    /*
    * Accordion Functionality
    */
    if($('.js_accordion').length) {

        $('.js_accordion >li .content').hide();
        $('.js_accordion > li:first-of-type > h4').addClass('open');
        $('.js_accordion > li:first-of-type .content').show();
        $('.js_accordion > li > h4').off('click');

        var accordionActive = false;

        $('.js_accordion').each(function () {

            var $accordion = $(this);

            $(this).find(' > li > h4').on('click', function () {

                var $btn = $(this);

                $accordion.find('.content').not($(this).next('.content')).slideUp();

                if ($(this).hasClass('open')) {
                    $(this).removeClass('open');
                    $(this).next('.content').stop(true,true).slideUp(function () {
                        $btn.addClass('closed');
                    });
                } else {
                    $accordion.find(' > li > h4').removeClass('open');
                    $(this).removeClass('closed');
                    $(this).toggleClass('open').next('.content').stop(true,true).slideToggle();
                }

            });

            $accordion.addClass('accordionReady');

        });
    }


}); // document ready
