$('document').ready(function () {
    'use strict';

    if ($('.staticBlockInstance ul li').length) {

        slickIt('.staticBlockInstance ul', window.breakpoints.tablet + 20, {
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    arrows: false,
                    dots: false
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    arrows: false,
                    dots: false
                }
            }]
        });
    }

}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';

    if ($('.staticBlockInstance ul li').length) {

        slickIt('.staticBlockInstance ul', window.breakpoints.tablet + 20, {
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    arrows: false,
                    dots: false
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    arrows: false,
                    dots: false
                }
            }]
        });
    }

})); // debounce

