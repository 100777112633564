$(window).on('resize', debouncer(function (e) {
    'use strict';

    boxSlider();

    mobileGridSlider();

}));

window.addEventListener('DOMContentLoaded', function(event) {

    'use strict';


    boxSlider();

    mobileGridSlider();

    /*
    * Product Image Slider
     */
    mainHeroSlider();


});

function boxSlider() {

    'use strict';

    if ($('.js_boxSlider').length) {


        slickIt('.js_boxSlider', 9999, {
            dots : true,
            infinite : true,
            arrows : true,
            autoplay : true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear',
            pauseOnFocus: false,
            pauseOnHover: false
        });

    }

}


function mobileGridSlider() {

    'use strict';

    if ($('.mobileGridSlider').length) {


        slickIt('.mobileGridSlider', 640, {
            dots : true,
            infinite : true,
            arrows : true,
            autoplay : true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear',
            pauseOnFocus: false,
            pauseOnHover: false
        });

    }
}


function mainHeroSlider() {
    'use strict';

    if($('.mainHeroSlider').length && $(window).width() > 640) {

        $('.mainHeroSlider').slick({
            infinite: true,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            lazyLoad: 'progressive',
        });

    }
}


