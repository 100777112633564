<template>

    <div 
            id="heroTitle"
            class="titleBanner standard"
            :class="cssClass"
            v-if="title"
    >
        <div class="wrapper">
            <div class="innerTitleBanner">
                <div class="bannerContentWrap">
                    <div class="innerContentWrapper">
                        <div class="titleWrap">
                            <div class="innerText">
                                <h1 v-html="title"></h1>

                                <div class="titleContent wysiwyg" v-if="content" v-html="content"></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'pageTitle',
    props: {
        title: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        content: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
        cssClass: {
            type: [String],
            default (rawProps) {
                return '';
            }
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
    
    .titleBanner {
        
        &.browseTitle {
            
            min-height: 24px;
            padding: 68px 0 56px;

            .innerTitleBanner {
                
                .titleWrap {
                    
                    .innerText {
                        
                        h1 {
                            margin: 0;
                        }
                        
                    }
                    
                }
                
            }

        }
        
    }

</style>
