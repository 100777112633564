$(document).ready(function () {

    'use strict';

    /*
    * Select Boxes
     */
    if (!$('#checkout').length) {

        Vue.directive('select', {
            twoWay: true,
            deep: true,
            bind: function (el, binding) {

                $(el).chosen({
                    disable_search: true,
                    width: '100%'
                })
                    .change(function () {

                        $(this).val(binding.value);

                        if (binding.arg !== undefined) {
                            el.vm.callUpdate(binding.arg, binding.value);
                        }

                    });

            },
            //update: function(el) {
            //    return $(el).trigger('chosen:updated');
            //}
        });

    }


    /*
    * Select Boxes
     */
    Vue.directive('datepicker', {
        twoWay: true,
        deep: true,
        bind: function (el) {
            var min_date = false;
            if ($(el).data('mindate') !== 'undefined') {
                min_date = $(el).data('mindate');
            }
            $(el).datepicker({
                showButtonPanel: true,
                dateFormat: 'dd/mm/yy',
                changeYear: true,
                minDate: min_date,
                yearRange: '1910:2030'
            });

        },
        update: function(el) {
            return $(el).trigger('update');
        }
    });


}); // document ready
