<template>

    <section class="categorySlider" :class="{ 'centerDesktop': centerItems }" v-if="hasCategories(categories)">

        <Splide
            :options="sliderOptions"
            ref="categorySlider"
        >

            <SplideSlide
                v-for="(cat, index) in categories"
                :key="'img' + index"
            >
                <div class="catSlideWrap">
                    <a :href="cat.URL.value">
                        <div class="catImgWrap">
                            <img :alt="cat.Image.alt"
                                 :data-splide-lazy="imagePath + '/' + cat.Image.value"
                                 height="216"
                                 width="216"
                            />
                        </div>
                        <span class="catTitleWrap">
                            {{ cat.Title.value }}
                        </span>
                    </a>
                </div>
            </SplideSlide>

        </Splide>

    </section>

</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: "CategorySlider",
    components: {
        Splide,
        SplideSlide
    },
    props: {
        categories: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        imagePath: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            sliderOptions: {
                gap: 25,
                rewind: false,
                pagination: false,
                arrows: true,
                fixedWidth: 216,
                perMove: 1,
                perPage: 6,
                lazyLoad: true,
                preloadPage: 1,
                mediaQuery: 'max',
                breakpoints : {
                    1240: {
                        perPage: 5,
                    },
                    1080: {
                        perPage: 4,
                    },
                    870: {
                        perPage: 3
                    },
                    640: {
                        fixedWidth: 216,
                        perMove: 1,
                        perPage: 2,
                        gap: 16
                    },
                    440: {
                        perPage: 1,
                    }
                }
            },
            centerItems: false,
        }
    },

    created() {

    },
    mounted() {
        if (this.categories.length < 6) {
            this.centerItems = true;
        }
    },
    computed: {


    },
    methods: {


        hasCategories(categories) {

            if(typeof categories != 'undefined') {
                return categories.length;
            }

            return false;

        }

    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';
@import '@splidejs/splide/dist/css/splide.min.css';


.categorySlider {

    width: 100%;
    position: relative;
    float: none;
    clear: both;
    padding: 0 0 56px;

    @media @mobile {
        padding: 0;
    }


    .catSlideWrap {
        width: 100%;
        max-width: 216px;
        text-align: center;

        .catImgWrap {
            margin: 0 0 13px;

            img {
                max-height: 216px;
                max-width: 216px;
                height: auto;
                width: auto;
            }
        }

        .catTitleWrap {
            display: inline-block;
            background: #ffffff;
            border-radius: 50px;
            color: #3D4446;
            .rems(15.5);
            line-height: 17.986px;
            font-weight: 600;
            padding: 14px 15px;

            svg {
                display: inline-block;
                height: 10px;
                width: 9px;
                margin: 0 0 0 12px;
            }
        }
    }

    .splide__track {
        overflow: visible;
    }

    .splide__arrows {
        //position: absolute;
        //bottom: auto;
        //top: 100%;
        //left: 0;
        //right: auto;
        //display: flex;
        //justify-content: flex-start;
        //align-items: center;
        //padding: 24px 0 0;
        //
        //@media @mobile {
        //    display: none;
        //}
    }

    .splide__arrow {
        width: 48px;
        height: 48px;
        background: transparent;
        border-radius: 0;
        //position: relative;
        //top: auto;
        //right: auto;
        //bottom: auto;
        //left: auto;
        //transform: none;
        display: flex;
        align-items: center;
        opacity: 1;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;

        &:after {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        &.splide__arrow--prev {
            //justify-content: flex-start;
            left: -15px;
        }

        &.splide__arrow--next {
            //margin-left: 1px;
            //justify-content: flex-end;
            right: -15px;
        }

        &[disabled] {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.centerDesktop {
        @media all and (min-width: @wrapper_width + 1px) {
            .splide__list {
                width: 100%;
                justify-content: center;
            }

            .splide__arrows {
                display: none;
            }
        }
    }
}
</style>