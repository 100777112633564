/**
 * Worldpay 2 initilzation and handling for our checkout basket overview page
 * @author Rihards Siliņš
 * @version 1 12/07/2016
 */
$(document).ready(function () {
    "use strict";
    // No need to proceed if we are not in the checkout page and the worldpay part3
    // isn't included (which contains id="worldpayContainer")
    if ($("#worldpayContainer").length === 0) {
        return false;
    }
    // Get the label of the button prior to any action.
    var default_first_step_button_label = $(".worldPayPay").html();
    // Init worldpay form via it's library
    Worldpay.useTemplateForm({
        clientKey: worldpay_variables.client_key,
        form: 'worldpayPaymentForm',
        paymentSection: 'worldpayPaymentSection',
        display: 'inline',
        saveButton: false,
        reusable: true,
        type: 'post',
        templateOptions: {
            dimensions: {
                width: 500,
                height: 265
            }
        },
        validationError: function () {
            $(".worldPayPay").removeClass("block");
            $(".worldPayPay").html(default_first_step_button_label);
        },
        callback: function (obj) {
            if (obj && obj.token) {
                $("#worldpayPaymentForm input[name=token]").val(obj.token);
                $("#worldpayPaymentForm input[name=name]").val(obj.paymentMethod.name);
                var data = {};
                $.each($('#worldpayPaymentForm').serializeArray(), function (i, field) {
                    data[field.name] = field.value;
                });
                $.ajax({
                    type: 'POST',
                    url: "/shop/checkout/worldpay/ajax/payment.php",
                    data: data,
                    dataType: 'json',
                    headers: {
                        Accept: worldpay_variables.server_http_accept
                    }
                }).done(function (response) {
                    if (response.paymentStatus === "PRE_AUTHORIZED" && response.redirectURL !== null && response.redirectURL !== "") {
                        $("#worldpayPaymentForm input[name=PaReq]").val(response.oneTime3DsToken);
                        $("#worldpayPaymentForm").attr("action", response.redirectURL).submit();
                    } else {
                        $(".worldPayPay").removeClass("block");
                        $(".worldPayPay").html(default_first_step_button_label);
                        $(".cardDeclined .reason").html(response.paymentStatusReason);
                        $(".cardDeclined").show();
                    }
                }).fail(function (jqXHR) {
                    $(".worldPayPay").removeClass("block");
                    $(".worldPayPay").html(default_first_step_button_label);
                    $(".paymentError .reason").html(jqXHR.responseText);
                    $(".paymentError").show();
                });
            }
        }
    });
    // Some handling added to the button so it's not clicked twice and right actions
    // are triggered at the right time.
    $(".worldPayPay").on("click", function (event) {
        if ($(this).hasClass("block")) {
            return false;
        }
        $(".worldpayErrorMsg").hide();
        if ($("#worldpayPaymentSection").is(":visible")) {
            $(".worldPayPay").html("Please wait ...");
            $(".worldPayPay").addClass("block");
            event.preventDefault();
            Worldpay.submitTemplateForm();
        } else {
            $("#worldpayPaymentSection").slideDown();
        }
    });
});
