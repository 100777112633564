$(document).ready(function () {
    'use strict';


    var footerMenus = false;

    if ($(window).width() <= 640) {

        if ($(document).scrollTop() > 200) {
            showHideMenus('.js_footerShowHideMenu .menuGroup:not(.newsletterGroup) .menuGroupTitle:not(.noCollapse)', 640);
        } else {


            $(window).on('scroll', function (event) {

                if (!footerMenus && $(document).scrollTop() > 200) {
                    footerMenus = true;
                    showHideMenus('.js_footerShowHideMenu .menuGroup:not(.newsletterGroup) .menuGroupTitle:not(.noCollapse)', 640);
                }

            });
        }

    } else {
        showHideMenus('.js_footerShowHideMenu .menuGroup:not(.newsletterGroup) .menuGroupTitle:not(.noCollapse)', 640);
    }

    $(window).on('resize', function (event) {

        if ($(document).scrollTop() > 200) {
            showHideMenus('.js_footerShowHideMenu .menuGroup:not(.newsletterGroup) .menuGroupTitle:not(.noCollapse)', 640);
        }

    });



});

