/*
    * Sidebar Navigation
    */
Vue.component('sidebar-navigation', {

    data () {
        return {
            pages: [],
            currentPage: 0,
            loading: false
        };
    },

    created () {

        EventBus.$on('initSidebarNav', (pages, currentPage) => {
            this.pages = pages;
            this.currentPage = currentPage;
        });

        EventBus.$on('updateSidebarNav', (currentPage) => {
            this.currentPage = currentPage;
        });

    }

});

$(document).ready(function () {

    'use strict';




    if ($('.js_helpOverlay').length) {
        $('.js_helpOverlay').mtcOverlay();
    }


}); // document ready


