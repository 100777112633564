var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasCategories(_vm.categories)
    ? _c(
        "section",
        {
          staticClass: "categorySlider",
          class: { centerDesktop: _vm.centerItems },
        },
        [
          _c(
            "Splide",
            { ref: "categorySlider", attrs: { options: _vm.sliderOptions } },
            _vm._l(_vm.categories, function (cat, index) {
              return _c("SplideSlide", { key: "img" + index }, [
                _c("div", { staticClass: "catSlideWrap" }, [
                  _c("a", { attrs: { href: cat.URL.value } }, [
                    _c("div", { staticClass: "catImgWrap" }, [
                      _c("img", {
                        attrs: {
                          alt: cat.Image.alt,
                          "data-splide-lazy":
                            _vm.imagePath + "/" + cat.Image.value,
                          height: "216",
                          width: "216",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "catTitleWrap" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(cat.Title.value) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }