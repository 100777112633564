<template>


    <div class="paginationWrap">

        <div class="innerPagination">


            <div class="paginationButtons buttonPrevious" v-if="pagination.show_previous && pagination.previous_page_url">
                <a 
                        :href="pagination.previous_page_url" 
                        aria-label="Previous Page" 
                        @click.prevent="updateAjax(pagination.previous_page_url)"
                >
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.00416697 5.08977C0.0231256 5.28643 0.102564 5.4724 0.231401 5.62207L3.67662 9.64149C3.83871 9.84376 4.07597 9.97153 4.33402 9.99582C4.5921 10.02 4.84882 9.93855 5.04576 9.76992C5.24269 9.60143 5.36284 9.36019 5.37873 9.10156C5.39462 8.84281 5.3049 8.58874 5.13 8.3973L2.21705 5L5.13 1.6027C5.30477 1.41124 5.39449 1.15718 5.3786 0.898443C5.36285 0.639832 5.24269 0.398579 5.04576 0.230077C4.84883 0.0614529 4.59197 -0.019989 4.33388 0.00417613C4.0758 0.0284748 3.8387 0.156244 3.67662 0.358507L0.231401 4.37793C0.0622433 4.57459 -0.0197345 4.83148 0.00403346 5.08967L0.00416697 5.08977Z" fill="currentColor"/>
                    </svg>
                </a>
            </div> <!-- .paginationButtons buttonNext -->
            
            <ul class="pagination">
                
                <li v-for="(page, index) in pagination.pages" :key="'pages' + index" :class="[page.active ? 'active' : '']">
                    <a 
                            :href="page.url" 
                            v-html="page.number"
                            :aria-label="'Go to page ' + page.number"
                            @click.prevent="updateAjax(page.url)"
                    ></a>
                </li>

                
                <template v-if="pagination.last_page">
                    
                    <li class="paginationDots">
                        <span>...</span>
                    </li>
    
                    <li :class="[pagination.last_page.active ? 'active' : '']">
                        <a
                                :href="pagination.last_page.url"
                                v-html="pagination.last_page.number"
                                :aria-label="'Go to page ' + pagination.last_page.number"
                                @click.prevent="updateAjax(pagination.last_page.url)"
                        ></a>
                    </li>
                    
                </template>
                
                
            </ul>

            <div class="paginationButtons buttonNext" v-if="pagination.show_next && pagination.next_page_url">
             
                <a 
                        :href="pagination.next_page_url" 
                        aria-label="Next Page" 
                        @click.prevent="updateAjax(pagination.next_page_url)"
                >
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.37669 4.91023C5.35773 4.71357 5.2783 4.5276 5.14946 4.37793L1.70424 0.358506C1.54215 0.156236 1.30489 0.0284694 1.04684 0.00417511C0.788764 -0.0199903 0.53204 0.0614516 0.335101 0.230076C0.138174 0.398567 0.0180149 0.639812 0.00212522 0.898443C-0.0137625 1.15719 0.0759587 1.41126 0.250855 1.6027L3.16381 5L0.250855 8.3973C0.076088 8.58876 -0.0136318 8.84282 0.00225835 9.10156C0.0180126 9.36017 0.138173 9.60142 0.335101 9.76992C0.532029 9.93855 0.788893 10.02 1.04698 9.99582C1.30505 9.97153 1.54216 9.84376 1.70424 9.64149L5.14946 5.62207C5.31862 5.42541 5.40059 5.16852 5.37683 4.91033L5.37669 4.91023Z" fill="currentColor"/>
                    </svg>
                </a>
                
            </div> <!-- .paginationButtons buttonNext -->

        </div> <!-- .innerPagination -->
        
    </div> <!-- .paginationWrap -->
 

</template>

<script>
export default {
    name: 'pagination',
    props: {
        pagination: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        updateAjax(url) {
            this.$emit('update-ajax', url);
        }
    }
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

</style>
