/*
 * functions - general functions use on all sites
 * Version: 0.2
 */

var vue_environment;

/*
 * AJAX all forms
 */

window.submitForm = function(form) {
    'use strict';

    $(document).on('submit', form, function (e) {
        var form_cache = $(this),
            form_data = new FormData(form_cache[0]), // JQuery serialize() doesn't handle file uploads.
            action = form_cache.attr('action'),
            submit_button = form_cache.find('[type="submit"]'),
            errors,
            errorField,
            offset;

        e.preventDefault();

        form_data.append('ajax', 1);

        // stop button from being clickable
        form_cache.addClass('submitting');
        submit_button.attr('disabled', 'disabled');

        // remove any existing instances of .successMessage - prevents multiple success messages
        $('.successMessage').remove();

        $.ajax({
            type: 'post',
            url: action,
            processData: false, // Important for FormData file uploads.
            contentType: false, // Important for FormData file uploads.
            data: form_data,
            context: form_cache,
            success: function (response) {

                response = $.parseJSON(response);

                // cache form within success
                form_cache = $(this);

                // remove traces of errors so form can be updated on a subsequent submit
                form_cache.find('.row').removeClass('errorWrap');
                form_cache.find('.validationMessage').remove();
                form_cache.find('.error').remove();
                form_cache.prev('.success').remove();

                // if errors add these to the dom
                if (response && response.errors !== null && !$.isArray(response.errors)) {

                    if ($.type(response.errors) === 'object' && !$.isEmptyObject(response.errors)) {
                        // add errors to relevant fields
                        $.each(response.errors, function (name, message) {
                            // cache fields with errors
                            var error_field = form_cache.find('input[name="' + name + '"], textarea[name="' + name + '"], select[name="' + name + '"]'),
                                error_field_row = error_field.parents('.row');

                            // check if error corresponds to an input field
                            if (error_field.length > 0) {
                                // added error class to input wrap
                                error_field_row.addClass('errorWrap');

                                error_field.on('keyup change', function () {
                                    if ($.trim($(this).val()).length > 0 || $.trim($(this).val()) !== '') {
                                        error_field_row.removeClass('errorWrap');
                                    } else {
                                        error_field_row.addClass('errorWrap');
                                    }
                                });

                                // add validation message to the dom
                                if (error_field.parents('.buttonInline').length > 0) {
                                    error_field.parent('.inputWrap').next('button').after('<div class="validationMessage"><span>' + message + '</span></div>');
                                } else {
                                    error_field.parent('.inputWrap').after('<div class="validationMessage"><span>' + message + '</span></div>');
                                }

                            // add error to dom before form
                            } else {

                                // check if ther is an error ul already in the dom - if not add one
                                if (!$('.error ul').length > 0) {
                                    form_cache.prepend('<div class="error"><ul></ul></div>');
                                }

                                form_cache.find('.error ul').prepend('<li>' + message + '</li>');

                            }

                        });

                        // scroll to first error
                        offset = form_cache.find('.errorWrap, .error').eq(0).offset();
                        $('html, body').stop(true, true).delay(200).animate({
                            scrollTop: offset.top - 100
                        }, 1000);

                        // remove disabled from button if errors on page
                        submit_button.removeAttr('disabled');

                        if ($('.overlayBoxOuter').length > 0) {
                            $(window).trigger('resize.overlay');
                        }

                    }

                // if success then move on
                } else {

                    if (response && response.success !== null && !$.isArray(response.success)) {

                        if (response.success.redirect) {
                            $(location).attr('href', response.success.redirect);
                        } else {

                            // add a success message div then put all the content in this
                            form_cache.before('<div class="successMessage" />');

                            // prepend title if exists
                            if (response.success.title) {
                                $('.successMessage').append(response.success.title);
                            }
                            $('.successMessage').append('<div class="message">' + response.success.message + '</div>');

                            if ($('.removeAfterSubmit').length) {
                                $('.removeAfterSubmit').remove();
                            }

                            if (response.success.hide_form) {
                                form_cache.fadeOut();
                            }

                            // scroll to success message
                            offset = $('.successMessage').offset();
                            $('html, body').stop(true, true).delay(200).animate({
                                scrollTop: offset.top - 100
                            }, 1000);

                        }

                        // remove disabled from button if errors on page
                        form_cache.find('[type="submit"]').removeAttr('disabled');

                        if ($('.overlayBoxOuter').length > 0) {
                            $(window).trigger('resize.overlay');
                        }
                    }

                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // remove disabled from button if errors on page
                submit_button.removeAttr('disabled');
            }
        });

    });
}

/*
 * Get breakpoints from css
 */

function getBreakpoint() {
    'use strict';

    // set some vars
    var style = null,
        breakpoint = [],
        breakpoints = [],
        i;

    /*
     * Use the js function getComputedStyle() to grab the styles of pseudo elements
     */
    style = window.getComputedStyle(document.documentElement, ':before');

    // split breakpoints into array
    style = style.content.replace(/\,\s+/g, ',').split(',');

    // split each breakpoint into array
    $.each(style, function () {
        // remove px from value and split each value into breakpoint name and number
        breakpoint = this.replace(': ', ':').replace('px', '').replace('\'', '').replace('"', '').split(':');

        // build array with breakpoint name as key and number as value
        for (i = 0; i < breakpoint.length; i++) {
            breakpoints[breakpoint[0]] = parseInt(breakpoint[1], 10);
        }
    });

    // return breakpoints
    return breakpoints;
}

/*
 * Debounce
 */

window.debouncer = function debouncer(func, timeout) {
    'use strict';

    var timeoutID;
    timeout = timeout || 200;

    return function () {
        var scope = this,
            args = arguments;

        clearTimeout(timeoutID);

        timeoutID = setTimeout(function () {
            func.apply(scope, Array.prototype.slice.call(args));
        }, timeout);
    };
};

/*
 * Sticky Bottom
 */

window.stickyBottom = function() {
    'use strict';

    // lets grab height of the footer
    var footerHeight = $('.footerWrap').outerHeight();

    if (matchesMediaQuery(0, window.breakpoints.mobile)) {

        $('.stickyFooter').css({
            paddingBottom: 0
        });

        $('.siteWrapper').removeClass('stickyFooter');

    } else {
        $('.siteWrapper').addClass('stickyFooter');

        // then apply it as a padding so no content is hidden
        $('.stickyFooter').css({
            'padding-bottom': footerHeight
        });
    }

}

/*
 * Returns whether a given media query is true
 * Params: (0, 800) checks if browser width is 800 or below; (801) or (801,0) checks if browser width is 801 or above
 * Alternatively, one or both arguments can be strings, such as "mobile" or "tablet" (from window.breakpoints)
 * in this case the min_width value will automatically have 1 added to it
 */

window.matchesMediaQuery = function(min_width, max_width) {
    'use strict';

    // check if matchMedia is supported
    if (!window.matchMedia) {
        return false;
    }

    // If strings any strings are supplied, grab that breakpoint from window.breakpoints
    // e.g. "mobile" grabs window.breakpoints.mobile
    if (typeof min_width === "string") {
        if (window.breakpoints.hasOwnProperty(min_width)) {
            min_width = window.breakpoints[min_width] + 1;
        } else {
            return false;
        }
    }
    if (typeof max_width === "string" ) {
        if (window.breakpoints.hasOwnProperty(max_width)) {
            max_width = window.breakpoints[max_width];
        } else {
            return false;
        }
    }

    // check which arguments are set
    var max_is_set = true,
        min_is_set = true;

    if ((min_width === undefined || min_width === 0)) {
        min_is_set = false;
    }
    if ((max_width === undefined || max_width === 0)) {
        max_is_set = false;
    }

    // perform the relevant media query based on which arguments have been supplied
    if (max_is_set && !min_is_set) {
        return window.matchMedia('(max-width: ' + max_width + 'px)').matches;
    } else if (!max_is_set && min_is_set) {
        return window.matchMedia('(min-width: ' + min_width + 'px)').matches;
    } else if (max_is_set && min_is_set) {
        return window.matchMedia('(min-width: ' + min_width + 'px) and (max-width: ' + max_width + 'px)').matches;
    } else {
        return false;
    }
}

/*
 * Slide Fade Toggle
 */

$.fn.slideFadeToggle  = function (speed, easing, callback) {
    'use strict';

    return this.animate({
        opacity: 'toggle',
        height: 'toggle',
        padding: 'toggle auto',
        margin: 'toggle auto'
    }, speed, easing, callback);
};

/*
 * Scroll Page Function
 * Works on anchors and form elements
 */

$.fn.scrollPage = function (options, callback) {
    'use strict';

    var target = this,
        defaults = {
            // browser check to use appropriate element
            scroll: 'html, body',
            speed: 500,
            easing: 'linear',
            scroll_offset: 0,
            event_handler: 'click'
        },
        settings = $.extend({}, defaults, options);

    return target.on(settings.event_handler, function (e) {
        var $this = $(this),
            href = $this.attr('href');
        // change href to value if event_handler is change
        if (settings.event_handler === 'change') {
            href = $this.val();
        }

        // if the href has a # scroll to act as it if wasn't even here
        if ($(href).length && href.indexOf('#') > - 1) {
            // prevent default only if needed
            e.preventDefault();

            $(settings.scroll)
                .stop(true, true)
                .animate({

                    scrollTop: $(href).offset().top + settings.scroll_offset

                }, settings.speed, settings.easing)
                .promise()
                .then(function () {

                    if (typeof callback === 'function') {
                        callback();
                    }

                });
        }
    });
};

/*
 * File Upload
 */

window.fileUpload = function() {

    // .js_fileUpload = input field
    $('.js_fileUpload').on('change', function () {
        var input_field = $(this),
            files = input_field.get(0).files,
            filename = '',
            filename_field = '.js_fileName';

        $(this).parent().removeClass('errorWrap');

        // remove previous filename
        input_field.parent().find(filename_field).empty();

        // foreach file within file object
        $(files).each(function () {
            // get filename from object
            filename = $(this).get(0).name;

            // append filename within span to DOM
            input_field.parent().find(filename_field).append('<span>' + filename + '</span>');
        });
    });

}



Vue.directive('select_paf', {
    twoWay: true,
    deep: true,
    bind: function () {
        var selector = $(this.el),
            element = selector[0],
            handle = this,
            action = handle.arg;

        selector.chosen({
            disable_search_threshold: 10,
            allow_single_deselect: true
        }).change(function () {
            if (element.hasAttribute('multiple')) {
                var i,
                    len,
                    option,
                    ref,
                    values;
                values = [];
                ref = el.selectedOptions;
                for (i = 0, len = ref.length; i < len; i++) {
                    option = ref[i];
                    values.push(option.value);
                }
                handle.set(values);
            } else {
                handle.set(element.value);
            }
            if (action !== undefined) {
                //handle.callUpdate(action);
            }
        });

    },
    update: function() {
        return $(this.el).trigger('chosen:updated');
    }
});

Vue.component('paf', {
    props: [
        'inputpostcode',
    ],
    data: function () {

        return {
            loaded: 0,
            visible: false,
            paf_address : '',
            postcode_found: false,
            search_started: false,
            fields_active: true,
            address_list: [],
            selectedIndex: 0,
            postcode: ''
        };
    },

    methods: {
        clearError: function (field) {
            try {
                return eval('this.' + field + ' = "false"');
            } catch (err) {
                if (err instanceof ReferenceError) {
                    return false;
                }
            }
        },
        hasField: function (field) {
            try {
                return eval('this.' + field);
            } catch (err) {
                if (err instanceof ReferenceError) {
                    return false;
                }
            }
        },
        hasAddresses: function () {

            if (typeof this.address_list === 'undefined') {
                return 0;
            }

            return Object.keys(this.address_list.length);
        },


        setPafAddress: function (type) {

            var address_values = this.paf_address.split(','),
                vm = this, found = false,
                found_index = 0;


            let a1 = '';
            let a2 = '';
            let city = '';

            if (address_values.length == 2) {
                a1 = address_values[0].replace('|', ',');
                city = address_values[1].replace('|', ',');
            } else {
                a1 = address_values[0].replace('|', ',');
                a2 = address_values[1].replace('|', ',');
                city = address_values[2].replace('|', ',');
            }

            $('#' + type + '-address-1').val(a1);
            $('#' + type + '-address-2').val(a2);
            $('#' + type + '-city').val(city);

        },

        triggerPAF: function (type) {
            var vm = this;
            this.busy = true;

            this.$http.post('/core/includes/ajax_postcode.php', {
                postcode: this.postcode,
                as_json: true
            }).then(function (response) {
                vm.busy = false;
                vm.address_list = [];

                for(var index in response.data.addresses){
                    if(response.data.addresses.hasOwnProperty(index)){
                        if(typeof response.data.addresses[index].display_name != 'undefined') {
                            //this.address_list.push({response.data.addresses[index].display_name);

                            var i = parseInt(index);

                            if(isNaN(i)) {
                                i = 1;
                            }

                            this.address_list.push({
                                id: response.data.addresses[index].display_name,
                                label: response.data.addresses[index].display_name,
                            });
                        }
                    }
                }


                // We need to ensure that paf_address for address is updated so we reset it
                vm.paf_address = false;
                vm.paf_address = '';

                if(this.address_list.length > 0) {
                    vm.paf_address = this.address_list[0].label;
                }
                vm.paf_addresses = response.data.addresses;
                vm.search_started = true;


            });
        },

        toggleReadonly: function (type) {


            if (this.fields_active) {
                $('#' + type + '-address-1').attr('readonly', false);
                $('#' + type + '-address-2').attr('readonly', false);
                $('#' + type + '-city').attr('readonly', false);
            }else{

                $('#' + type + '-address-1').attr('readonly', true);
                $('#' + type + '-address-2').attr('readonly', true);
                $('#' + type + '-city').attr('readonly', true);
            }
        }
    },
});

/*
* Load recaptcha
 */
window.recaptchaInit = function() {

    'use strict';

    if(!$('body').hasClass('recaptchaActive')) {
        $('.js_contactForm button[type="submit"], .newsletterForm button[type="submit"]').attr('disabled', true);
    }

    if( ( $(window).scrollTop() > 10 && !$('body').hasClass('recaptchaActive') ) || $('.js_contactForm').length) {

        $('body').addClass('recaptchaActive');

        var apiKey = '6LfeqoUpAAAAAIOAt9AsAJi9d19BB2deCj4Gfv6x';
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = function() {

            grecaptcha.ready(function() {

                var captcha1 = document.getElementById('recaptcha');
                var captcha2 = document.getElementById('recaptcha2');

                grecaptcha.execute(apiKey, {action: 'contact'}).then(function (token) {

                    if (token && (captcha1 || captcha2)) {

                        if(captcha1) {
                            captcha1.value = token;
                        }
                        if(captcha2) {
                            captcha2.value = token;
                        }
                    }
                });
            });

            $('.js_contactForm button[type="submit"], .newsletterForm button[type="submit"]').attr('disabled', false);
        };
        script.src = "https://www.google.com/recaptcha/api.js?render=" + apiKey;
        head.appendChild(script);

    }


}

//Update the recaptcha when the form is submitted
window.recaptchaUpdate = function(cb) {

    'use strict';

    if($('body').hasClass('recaptchaActive')) {

        var apiKey = '6LfeqoUpAAAAAIOAt9AsAJi9d19BB2deCj4Gfv6x';

        if(typeof grecaptcha != 'undefined') {
            grecaptcha.ready(function () {

                var captcha1 = document.getElementById('recaptcha');
                var captcha2 = document.getElementById('recaptcha2');

                grecaptcha.execute(apiKey, {action: 'contact'}).then(function (token) {

                    if (token && (captcha1 || captcha2)) {

                        if (captcha1) {
                            captcha1.value = token;
                        }
                        if (captcha2) {
                            captcha2.value = token;
                        }
                    }

                    cb(token);

                });
            });
        }

    }else{
        cb(false);
    }


}

/*
 * Slick below breakpoint, else remove slick
 */
window.slickIt = function slickIt(element_to_slick, breakpoint, slick_settings) {
    'use strict';
    var slider_active = $(element_to_slick).hasClass('slick-initialized');

    if ($(element_to_slick + ' li').length > 1) {

        if (matchesMediaQuery(0, breakpoint)) {

            if (!slider_active) {

                $(element_to_slick).on('init', function(){

                    if($(this).find('.slickNavigation').length) {
                        $(this).find('.slickNavigation').replaceWith($(this).find('.slick-dots'));
                    }

                    var $dots = $(this).find('.slick-dots').wrap('<div class="slickNavigation"></div>').parent();

                    if($(this).find('.slick-prev').length) {
                        var $prev = $(this).find('.slick-prev').clone(true, true);
                        $dots.prepend($prev.wrap('<div class="slickNav slickNext"></div>').parent());
                        $(this).find('.slick-prev').hide();
                        $prev.show();
                    }

                    if($(this).find('.slick-next').length) {
                        var $next = $(this).find('.slick-next').clone(true, true);
                        $dots.append($next.wrap('<div class="slickNav slickPrev"></div>').parent());
                        $(this).find('.slick-next').hide();
                        $next.show();
                    }

                }).slick(slick_settings);
            }

        } else {

            if (slider_active) {

                if($(element_to_slick).find('.slickNavigation').length) {
                    $(element_to_slick).find('.slickNavigation').replaceWith($(element_to_slick).find('.slick-dots'));
                }
                $(element_to_slick).slick('unslick');

            }
        }
    }
}

window.slickItAlt = function slickIt(element_to_slick, slick_settings) {

    'use strict';
    var slider_active = $(element_to_slick).hasClass('slick-initialized');

    if ($(element_to_slick + ' li').length > 1) {

        if(slider_active) {

            if ($(element_to_slick).find('.slickNavigation').length) {
                $(element_to_slick).find('.slickNavigation').replaceWith($(element_to_slick).find('.slick-dots'));
            }
            $(element_to_slick).slick('unslick');

        }

        var slideCount = $(element_to_slick + ' li').length;



        $(element_to_slick).on('init', function(){

                if($(this).find('.slickNavigation').length) {
                    $(this).find('.slickNavigation').replaceWith($(this).find('.slick-dots'));
                }

                var $dots = $(this).find('.slick-dots').wrap('<div class="slickNavigation"></div>').parent();

                if($(this).find('.slick-prev').length) {
                    var $prev = $(this).find('.slick-prev').clone(true, true);
                    $dots.prepend($prev.wrap('<div class="slickNav slickNext"></div>').parent());
                    $(this).find('.slick-prev').hide();
                    $prev.show();
                }

                if($(this).find('.slick-next').length) {
                    var $next = $(this).find('.slick-next').clone(true, true);
                    $dots.append($next.wrap('<div class="slickNav slickPrev"></div>').parent());
                    $(this).find('.slick-next').hide();
                    $next.show();
                }

                if(slideCount <= slick_settings.slidesToShow) {
                    $(element_to_slick).find('.slickNavigation').hide();
                }else{
                    $(element_to_slick).find('.slickNavigation').show();
                }

            }).slick(slick_settings);

    }
}


$(document).ready(function () {

/*
 * force strict mode
 */

    'use strict';

/*
 * Set initial window width on document ready
 */

    window.windowWidth = $(window).outerWidth();

/*
 * Set window breakpoints variable
 * call by using window.breakpoints.BREAKPOINT_NAME
 */
    window.breakpoints = getBreakpoint();


    if($('body').hasClass('hasRecaptcha')) {
        window.recaptchaInit();
    }
    
});


