var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currency
    ? _c("span", {
        staticClass: "multiPrice",
        domProps: { innerHTML: _vm._s(_vm.price_with_symbol) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }