/*
 * Reset #mobileCheckoutButton
 */

function unsetMobileCheckoutButton() {
    'use strict';

    if (matchesMediaQuery('tablet', 0)) {
        $('.userDetailsWrap').css({
            display: '',
            overflow: ''
        });
    }
}

$(document).ready(function () {

    'use strict';

    /*
     * mobile checkout button
     */
    $(document).on('click', '#mobileCheckoutButton', function (e) {
        e.preventDefault();

        $(this).parents('#checkout')
            .find('.userDetailsWrap')
            .stop(true, true)
            .slideFadeToggle();

        var offset,
            offTop = 0;

        if ($('#userDetails').length > 0) {
            offset = $('#userDetails').offset();
            offTop = offset.top - 100;
        }

        $('html, body').delay(200).animate({
            scrollTop: offTop
        }, 1000);
    });

    /*
     * Reset #mobileCheckoutButton
     */

    unsetMobileCheckoutButton();

}); // document ready

$(window).on('resize', debouncer(function (e) {
    'use strict';

    /*
     * Reset #mobileCheckoutButton
     */

    unsetMobileCheckoutButton();

})); // window resize
