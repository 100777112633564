<template>

    <div class="priceRangeSlider">

        <div class="priceRange">

            <div class="ranges">

                <div class="row">
                    <label for="minPrice">From</label>
                    <div class="innerInput">
                        
                        <span 
                                v-if="currencySymbol" 
                                v-html="currencySymbol"
                                class="currencySymbol"
                        ></span>

                        <input
                                id="minPrice"
                                type="number"
                                step="1.00"
                                :min="0"
                                :max="prices.price_max - 1"
                                v-model="prices.price_min"
                        >
                        
                    </div> <!-- .innerInput -->
                   
                </div> <!-- .row -->

                <div class="row">
                    <label for="maxPrice">To</label>
                    <div class="innerInput">

                        <span
                                v-if="currencySymbol"
                                v-html="currencySymbol"
                                class="currencySymbol"
                        ></span>
                        
                        <input
                                id="maxPrice"
                                type="number"
                                step="1.00"
                                :min="prices.price_min + 1"
                                :max="9999999"
                                v-model="prices.price_max"
                        >
                    </div> <!-- .innerInput -->
                </div> <!-- .row -->
                
            </div> <!-- .ranges -->

            <div class="buttonWrap">
                <button 
                        type="button" 
                        class="button buttonOutlined" 
                        @click.prevent="updateAjax(priceURL)"
                >
                    Update Prices
                </button>
            </div> <!-- .buttonWrap -->
            
        </div> <!-- .priceRange -->
        
    </div>

</template>

<script>

export default {
    name: 'priceRangeSlider',
    props: {
        prices: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    price_min: 0,
                    price_max: 0,
                    result_price_min: 0,
                    result_price_max: 0,
                    current_url: '',
                    start_price: 0,
                    to_price: 0,
                    price_max_foreign_currency: 0,
                    price_min_foreign_currency: 0
                };
            }
        },
        currencySymbol: {
            type: [String, Boolean],
            default (rawProps) {
                return "&pound;";
            }
        }
    },
    methods: {
        updateAjax(url) {
            this.$emit('update-ajax', url);
        },
        resetMaxPrice(pMax) {

            let max = parseFloat(pMax);

            if(!isNaN(max)) {
                if(max < 1) {
                    this.prices.price_max = '';
                }
            }

        }
    },
    watch: {
        prices(newVal) {
            this.resetMaxPrice(newVal.price_max);
        }
    },
    mounted() {
        this.resetMaxPrice(this.prices.price_max);
    },
    computed: {
        
        priceURL() {
            
            let searchParams = new URLSearchParams('');
            
            if(window.location.search) {
                searchParams = new URLSearchParams(window.location.search);
            }

            if(searchParams.has('price_min')) {
                searchParams.set("price_min", this.prices.price_min);
            }else{
                searchParams.append("price_min", this.prices.price_min);
            }

            if(searchParams.has('price_max')) {
                searchParams.set("price_max", this.prices.price_max);
            }else{
                searchParams.append("price_max", this.prices.price_max);
            }
            
            return window.location.pathname + '?' + searchParams.toString();
            
        }
    }
}
</script>

<style lang="less" scoped>

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .priceRangeSlider {
        
        .buttonWrap {
            .button {
                width: 100%;
                margin: 0;
            }
        }
        
        .ranges {
            display: flex;
            gap: 16px 0;
            padding-bottom: 16px;
            
            .row {
                float: none;
                clear: both;
                flex: 0 0 50%;
                width: 50%;
                margin: 0;
                
                label {
                    float: none;
                    clear: both;
                    width: 100%;
                }
                
                &:first-child {
                    padding-right: 8px;
                }

                &:last-child {
                    padding-left: 8px;
                }
            }
            
            .innerInput {
                position: relative;
                
                .currencySymbol {
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 2;
                    .rems(12);
                    line-height: 26px;
                    font-weight: 600;
                }
                
                input {
                    width: 100%;
                    z-index: 1;
                    padding-left: 24px;
                }
            }
        }
        
    }

</style>
