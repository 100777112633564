<template>

  <div
          id="assessmentContent"
          class="assessmentFormContent"
          :cat-id="categoryId"
  >

      <div class="wrapper">
          

          <div class="assessmentHeader">

              <div class="headerBack">

                  <a 
                          href="#back" 
                          class="button buttonOutlined" 
                          @click.prevent="goBack" 
                          :class="[showBackButton ? 'buttonVisible' : '']"
                  >

                      <i class="svgIcon">
                          <svg
                                  viewBox="0 0 10 9"
                          >
                              <defs>
                                  <linearGradient 
                                          id="140531f5-a172-4d96-955e-cf7eaf56b752" 
                                          x1="11.25" 
                                          y1="8.86" 
                                          x2="-0.01" 
                                          y2="1.89" 
                                          gradientTransform="matrix(1, 0, 0, -1, 0, 10)" 
                                          gradientUnits="userSpaceOnUse"
                                  >
                                      <stop offset="0" stop-color="#444c4e"/>
                                      <stop offset="1" stop-color="#313638"/>
                                  </linearGradient>
                              </defs>
                              <path d="M.54,4.51,5,0H6.68L2.82,3.9h7.72V5.1H2.81L6.66,9H5Z" transform="translate(-0.54 0)" style="fill:url(#140531f5-a172-4d96-955e-cf7eaf56b752)"/>
                          </svg>
                      </i>
                      
                      Back
                  </a>
                  
              </div> <!-- .headerBack -->
              
              <div class="headerPharmacist">

                  <div class="pharmacistBox" v-if="prescribingDoctorName">
                      
                      <div class="pharmacistBox__icon">
                          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.74 25"><path d="M19.92,11a2.81,2.81,0,0,0-.73,5.53v1.66a5.38,5.38,0,0,1-10.75,0V16.6a7,7,0,0,0,6.32-6.35l.63-6.64A2.57,2.57,0,0,0,12.84.82h-.06A1.54,1.54,0,0,0,11.44,0a1.56,1.56,0,0,0,0,3.11,1.54,1.54,0,0,0,1.34-.82h.06a1.1,1.1,0,0,1,.79.34,1.12,1.12,0,0,1,.29.82l-.64,6.64a5.61,5.61,0,0,1-11.17,0L1.48,3.45A1.08,1.08,0,0,1,2.56,2.29h.06A1.54,1.54,0,0,0,4,3.11,1.56,1.56,0,0,0,4,0,1.54,1.54,0,0,0,2.62.82H2.56A2.57,2.57,0,0,0,0,3.61l.63,6.64A7,7,0,0,0,7,16.6v1.55a6.85,6.85,0,0,0,13.7,0V16.49A2.81,2.81,0,0,0,19.92,11Zm0,4.16a1.35,1.35,0,1,1,1.35-1.34A1.32,1.32,0,0,1,19.92,15.12Z" transform="translate(0)" fill="currentColor"/><path d="M4.33,8a.75.75,0,0,0,.74.74H7V10.7a.74.74,0,0,0,1.47,0V8.77h1.93a.74.74,0,0,0,0-1.47H8.46V5.37A.74.74,0,0,0,7,5.37V7.3H5.07A.73.73,0,0,0,4.33,8Z" transform="translate(0)" fill="currentColor"/></svg>
                      </div> <!-- .icon -->

                      <div class="pharmacistBox__title">
                          Today’s prescribing pharmacist:
                      </div> <!-- .title -->

                      <div class="pharmacistBox__name" v-html="prescribingDoctorName"></div> <!-- .doctor -->
                      
                  </div> <!-- .pharmasistBox -->
                  
              </div> <!-- .headerPharmacist -->
              
          </div> <!-- .assessmentHeader -->
          
          <form
                  action=""
                  enctype="multipart/form-data"
                  method="post"
                  class="formContentWrap"
                  autocomplete="off"
                  ref="mainForm"
                  id="mainForm"
          >
    
              <template v-for="(section, sIndex) in sections">
    
    
                  <form-section
                          :section="section"
                          :key="'section' + section.id"
                          :show-section-header="false"
                          :url-add-to-basket="urlAddToBasket"
                          @update-section="proceed"
                          v-if="section"
                  >
                      <template slot="timer">
                        <slot name="timer"></slot>
                      </template>
                  </form-section>
    
              </template>
    
              <template v-if="$store.state.assessment.travel_form">
    
                  <template v-for="multiuser in multiusers">
    
                      <input
                              type="hidden"
                              :name="'multiusers[' + multiuser.id + '][name]'"
                              :value="multiuser.name"
                              :key="'multiusername' + multiuser.id"
                      >
    
                  </template>
    
              </template>
    
          </form> <!-- .formContentWrap -->

          <div class="assessmentProgress">
              <div class="assessmentProgress__bar">
                  <div class="assessmentProgress__progress" :style="'width:' + currentProgress + '%;'"></div>
              </div> <!-- .progressBar -->
          </div> <!-- .assessmentProgressWrap -->

      </div> <!-- .wrapper -->

  </div>

</template>

<script>

import axios from 'axios';

import { mapGetters } from 'vuex';
import formSection from './form_section';

export default {
    name: "formContent",
    components: {
        formSection
    },
    computed: {
        ...mapGetters({
            sections: 'getAssessmentSections',
            multiusers: 'getAssessmentMultiusers',
            currentProgress: 'getProgress',
            prescribingDoctorName: 'getPrescribingDoctorname'
        }),
        showBackButton() {
            if(this.$store.state.assessment.sections[0].visible && !document.referrer.length) {
                return false;
            }else{
                return true;
            }
            
        }
    },
    props: {
        urlGetMultiuserQuestions: {
            type: String,
            default: '/missing-url-get-multiuser-questions',
        },
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            loadingStartTimeout: null,
            loadingEndTimeout: null,
        }
    },
    methods: {

        proceed(section) {

            const _this = this;


            if(section.can_proceed && section.submit_assessment) {

                this.submitAssessment(section);

            }else{

                if(section.product_selection) {

                    this.updatedSelectedProducts(this.$store.state.assessment.products);

                    if(section.can_proceed) {
                        this.nextSection();
                    }

                }else if(section.travel_section) {

                    if(section.can_proceed) {
                        this.getTravelQuestions(section);
                    }

                }else if(section.can_proceed) {
                    this.nextSection();
                }

            }

        },

        getTravelQuestions: async function(section) {

            const _this = this;

            return new Promise((resolve, reject) => {

                this.$parent.setLoading(true, 'Loading questions');

                const user_question = section.travel_questions.filter(question => question.question_type == 'travel_users');

                let users = [];

                if(user_question.length) {
                    users = user_question[0].answer;
                }

                axios.post(_this.urlGetMultiuserQuestions, {
                    users: users,
                })
                .then(function (response) {

                    const data = response.data;
                    const errors = data.errors

                    if(data.ok) {

                        _this.$store.dispatch('setAssessmentMultiusers', data.multiuser_questions);

                        _this.nextSection();

                    }else{
                        //errors
                    }

                })
                .catch(function (error) {

                })
                .finally(function () {
                    section.loading = false;
                    resolve();
                });


            });
        },

        submitAssessment(section) {

            section.loading = true;
            this.$parent.setLoading(true, 'Saving assessment');

            const _this = this;

            const form = this.$refs.mainForm;
            const form_data = new FormData(form);

            form_data.set('assessment_type', this.$store.state.assessment.type);

            axios.post(this.urlSubmitAssessment, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {

                const data = response.data;

                const errors = data.errors,
                    questionErrors = data.question_errors;

                if (data.ok) {
                    _this.$store.dispatch('setCreatedAssessments', data.created_assessments);
                    axios.post(_this.urlGetAllowedProducts, {
                        assessment_ids: data.created_assessments,
                        category_id: _this.categoryId,
                        product_id: _this.productId,
                    })
                    .then(function (response) {
                        _this.nextSection();
                        _this.$store.dispatch('setAssessmentProducts', response.data);
                    })
                }

            })
            .finally(function () {
                section.loading = false;
            });

        },

        updatedSelectedProducts(products) {


            for(const userProp in products) {

                this.$store.dispatch('setAssessmentSelectedProducts', {
                    productProp: userProp,
                    products: products[userProp]
                });

            }


        },
        
        sectionHasVisibleQuestions(sectionID) {

            let questionsVisible = [];
            
            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];
                

                if (section.id == sectionID) {

                    questionsVisible = section.questions.filter(q => {
                        return q.visible;
                    });


                }


            }
            
            
            return questionsVisible.length > 0;
        },
        
        
        goBack() {
            if(this.$store.state.assessment.sections[0].visible) {
                if(document.referrer.length) {
                    window.location = document.referrer;
                }
            }else{
                this.previousSection();
            }
        },
        
        nextSection() {
            
            let sectionID = false,
                next = false;
            

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];

          
                if(next) {
                    
                    if( this.sectionHasVisibleQuestions(section.id) || section.product_recommendations || section.product_confirmation || section.product_selection) {
                        sectionID = section.id;
                        next = false;
                        break;
                    }
                }

                if(section.visible) {
                    next = true;
                }
                
            }

            if(sectionID) {
                this.goToSection(sectionID);
            }

        },

        previousSection() {

            let sectionID = false;

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];
                
                if(section.visible) {
                    break;
                }

                if( this.sectionHasVisibleQuestions(section.id) || section.product_recommendations || section.product_confirmation || section.product_selection) {
                    sectionID = section.id;
                }


            }

            if(sectionID) {
                this.goToSection(sectionID);
            }

        },

        goToSection(sectionID) {

            const _this = this;

            //this.setLoading(true, 'Loading next step');

            clearTimeout(this.loadingStartTimeout);
            clearTimeout(this.loadingEndTimeout);

            this.loadingStartTimeout = setTimeout(() => {

                //assessmentContent
                
                document.getElementById('assessmentContent').scrollIntoView({
                    behavior: 'instant'
                });
               
                 

                this.$store.dispatch('showAssessmentSection', sectionID);
                this.$store.dispatch('updateProgress', sectionID);

                this.loadingStartTimeout = setTimeout(() => {

                    this.setLoading(false);

                }, 0);

            },0);


        },

        setLoading(loading, message) {
            this.$parent.setLoading(loading, message);
        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .assessmentFormContent {
        background: transparent;
        display: flex;
        justify-content: center;
        padding: 35px 0 50px 0;
        position: relative;
        width: 100%;
        
        .wrapper {
            width: 100%;
        }

        .assessmentHeader {
            display: flex;
            gap: 0 16px;
            
            @media all and (max-width: 700px) {
                flex-wrap: wrap;
            }
            

            .headerBack {
                flex: 1 1 100%;
            }

            .headerPharmacist {
                flex: 0 0 190px;
                display: flex;
                justify-content: flex-end;
                position: relative;

                @media all and (max-width: 700px) {
                    flex: 0 0 100%;
                }
            }
            
            .button {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: opacity 200ms linear, visibility 200ms linear;
                
                &.buttonVisible {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                    transition: opacity 200ms linear, visibility 200ms linear;
                }
            }
        }
        
        .pharmacistBox {
            .rems(10);
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0.2px;
            padding: 15px 15px 20px 15px;
            background: #fff;
            border-radius: 10px;
            width: 100%;
            max-width: 190px;
            border: 1.5px solid @secondary_color;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;

            @media all and (max-width: 700px) {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                width: 100%;
                max-width: 100%;
            }
            
            &__icon {
               color: @secondary_color;
                width: 100%;
                display: flex;
                justify-content: center;
                
                svg {
                    width: 100%;
                    max-width: 22px;
                    margin: 0 auto;
                }
            }
            
            &__title {
                text-align: center;
                padding: 10px 0 3px;
            }
            
            &__name {
                .rems(14);
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }
        

        .formContentWrap {
            width: 100%;
            
            @media all and (max-width: 1100px) {
                padding-top: 70px;
            }

            @media all and (max-width: 700px) {
                padding-top: 24px;
            }
            
            
            &:before, &:after {
                display: none;
            }
        }
        
        .assessmentProgress{
            width: 100%;
            max-width: 632px;
            margin: 0 auto;
            padding: 77px 0;
            
            &__bar {
                width: 100%;
                background: #fff;
                border-radius: 50px;
                height: 5px;
                overflow: hidden;
            }
            
            &__progress {
                background: @primary_color;
                height: 100%;
                border-radius: 50px;
                width: 0;
                transition: width 200ms linear;
            }
        }

    }



</style>
