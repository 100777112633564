$('document').ready(function () {
    'use strict';

    /*
     * Featured Items
     */

    var $featuredItems = $('.featuredItems'),
        $featuredItem = $featuredItems.find('li');

    if ($featuredItem.length) {

        var featuredItemsOptions = {
            dots: true,
            infinite: true,
            arrows: true,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            prevArrow: '<i class="slideshowLeftArrow fa fa-angle-left">',
            nextArrow: '<i class="slideshowRightArrow fa fa-angle-right">',
            appendArrows: '.featuredItemsWrap',
            responsive: [{
                breakpoint: (window.breakpoints.tablet + 20),

                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, {
                breakpoint: (window.breakpoints.mobile + 1),
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            }]
        };

        $featuredItems.on('init', function (event, slick, direction) {
            if (slick.$slides.length < featuredItemsOptions.slidesToShow) {
                $('.featuredItems .slick-dots').hide();
            }
        });

        $featuredItems.slick(featuredItemsOptions);
    }

}); // document ready
