$(document).ready(function () {
    'use strict';

    /*
     * Change the currency being used.
     */

    $('.currencySelector a').on('click', function (e) {

        e.preventDefault();

        var currency = $(this).data('currency'),
            currency_symbol = $(this).data('currency-symbol'),
            currency_selector = $('.selectedCurrency'),
            current_currency = currency_selector.data('current-currency'),
            current_currency_symbol = currency_selector.data('current-currency-symbol'),
            html = currency_selector.html(),
            multi_currency_total = $('#multiCurrencyTotal'),
            current_ratio = $("#currency_" + current_currency).attr("data-currency-ratio"),
            selected_ratio = parseFloat($("#currency_" + currency).attr("data-currency-ratio"));

        var url = $('#filter_current_url').val(),
            min = parseFloat($('#filter_price_min').val()),
            minRange = parseFloat($('#filter_price_min_range').val()),
            max = parseFloat($('#filter_price_max').val()),
            maxRange = parseFloat($('#filter_price_max_range').val()),
            filter_currency_ratio = $('#filter_currency_ratio').val(),
            priceUrlFirstChar = '',
            tempNum = 0;

        // set cookies on entire site with expires in 100 days
        $.cookie('currency', currency, {
            expires: 100,
            path: '/'
        });

        // get new html
        html = html.replace(current_currency, currency);
        html = html.replace(current_currency_symbol, currency_symbol);

        // update currency data
        currency_selector.data('current-currency', currency);
        currency_selector.data('current-currency-symbol', currency_symbol);

        if (url) {
            //remove current price range form the url
            url = url.replace(/&price_min=[^&]+/g, '');
            url = url.replace(/price_min=[^&]+/g, '');
            url = url.replace(/&price_max=[^&]+/g, '');
            url = url.replace(/\?&?$/, '');

            //swap min and max if min is more than max
            if (min > max) {
                tempNum = min;
                min = max;
                max = tempNum;
            }

            //if the price range filter is required, add it to the url
            if (min >= minRange || max <= maxRange) {
                if (url.indexOf('?') > -1) {
                    priceUrlFirstChar = '&';
                } else {
                    priceUrlFirstChar = '?';
                }
                url += priceUrlFirstChar + 'price_min=' + ( min * ((selected_ratio > 0)?selected_ratio:1) ) + '&price_max=' + ( max * ((selected_ratio > 0)?selected_ratio:1) );
            }

            if (filter_currency_ratio != '1') {
                url += '&currency_ratio=' + filter_currency_ratio;
            }
            filterAjax(url);
        }
        // hide show new prices
        $('.multiPriceBlock span').hide().removeClass('show');
        $('.multiPriceBlock .' + currency).show().addClass('show');

        // hide multiCurrencyTotal
        if (multi_currency_total.length) {
            if (currency === 'GBP') {
                multi_currency_total.removeClass('show');
            } else {
                multi_currency_total.addClass('show');
            }
        }

        // apply new html to currency
        $('.selectedCurrency').html(html);

    });

}); // document ready
