var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "datepickerWrap" },
    [
      _c("datepicker", {
        attrs: {
          name: _vm.questionName,
          id: "id_" + _vm.question.id,
          "data-question-id": _vm.question.id,
          autocomplete: "off",
        },
        on: {
          input: function ($event) {
            return _vm.updateQuestion(_vm.question)
          },
        },
        model: {
          value: _vm.question.answer,
          callback: function ($$v) {
            _vm.$set(_vm.question, "answer", $$v)
          },
          expression: "question.answer",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }