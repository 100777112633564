var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productFilter" }, [
    _c(
      "section",
      {
        staticClass: "filterFlyout",
        class: [_vm.filterMenuVisible ? "menuVisible" : ""],
      },
      [
        _c("form", { staticClass: "filterContainer", attrs: { action: "/" } }, [
          _c("div", { staticClass: "menuHeader" }, [
            _c("h2", { staticClass: "headerTitle" }, [
              _vm._v(
                "\n                    Filter medication\n\n                    "
              ),
              _c(
                "button",
                {
                  attrs: { type: "button", "aria-label": "Close filter menu" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeFilterMenu()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "screenreader" }, [
                    _vm._v("Close filter menu"),
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "svgIcon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 512 512",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z",
                            fill: "currentColor",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "innerFilterMenu",
              class: [_vm.loading ? "isLoading" : ""],
            },
            [
              _c("div", { staticClass: "filterScroll" }, [
                _vm.filterNotEmpty("selections")
                  ? _c("div", { staticClass: "filterBlock" }, [
                      _c("h3", { staticClass: "filterBlockTitle" }, [
                        _vm._v("Current selection"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "filterForm" }, [
                        _c(
                          "div",
                          { staticClass: "currentSelections" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "selectionLink",
                                attrs: { href: "/pharmacy/" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateAjax("/pharmacy/")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "selectionText" }, [
                                  _vm._v("Clear All"),
                                ]),
                                _vm._v(" "),
                                _c("i", { staticClass: "svgIcon" }, [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 512 512",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z",
                                          fill: "currentColor",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.filters.selections,
                              function (selection, n) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      key: "selection" + n,
                                      staticClass: "selectionLink",
                                      attrs: { href: selection.url },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateAjax(selection.url)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "selectionText",
                                        domProps: {
                                          innerHTML: _vm._s(selection.value),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("i", { staticClass: "svgIcon" }, [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 384 512",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                                                fill: "currentColor",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.filterNotEmpty("categories")
                  ? _c("div", { staticClass: "filterBlock" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "filterBlockTitle toggleOpener",
                          class: [_vm.menu.categories ? "open" : ""],
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.menu.categories = !_vm.menu.categories
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Category\n\n                            "
                          ),
                          _c("i", { staticClass: "svgIcon iconOpen" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "svgIcon iconClose" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "filterForm" }, [
                        _c(
                          "div",
                          { staticClass: "innerFilterForm" },
                          _vm._l(
                            _vm.filters.categories,
                            function (category, n) {
                              return _c(
                                "div",
                                {
                                  key: "cat" + category.id,
                                  staticClass: "row",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.filters.categories[n].selected,
                                        expression:
                                          "filters.categories[n].selected",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "checkboxCat" + category.id,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.filters.categories[n].selected
                                      )
                                        ? _vm._i(
                                            _vm.filters.categories[n].selected,
                                            null
                                          ) > -1
                                        : _vm.filters.categories[n].selected,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.filters.categories[n].selected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filters.categories[n],
                                                "selected",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filters.categories[n],
                                                "selected",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filters.categories[n],
                                            "selected",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "checkboxCat" + category.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateAjax(category.url)
                                        },
                                      },
                                    },
                                    [
                                      _c("a", {
                                        staticClass: "filterLink",
                                        attrs: { href: category.url },
                                        domProps: {
                                          innerHTML: _vm._s(category.name),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.filterNotEmpty("brands")
                  ? _c("div", { staticClass: "filterBlock" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "filterBlockTitle toggleOpener",
                          class: [_vm.menu.brands ? "open" : ""],
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.menu.brands = !_vm.menu.brands
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Brands\n\n                            "
                          ),
                          _c("i", { staticClass: "svgIcon iconOpen" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "svgIcon iconClose" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "filterForm" }, [
                        _c(
                          "div",
                          { staticClass: "innerFilterForm" },
                          _vm._l(_vm.filters.brands, function (brand, n) {
                            return _c(
                              "div",
                              { key: "cat" + brand.id, staticClass: "row" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filters.brands[n].selected,
                                      expression: "filters.brands[n].selected",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "checkboxBrand" + brand.id,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.filters.brands[n].selected
                                    )
                                      ? _vm._i(
                                          _vm.filters.brands[n].selected,
                                          null
                                        ) > -1
                                      : _vm.filters.brands[n].selected,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.filters.brands[n].selected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.filters.brands[n],
                                              "selected",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.filters.brands[n],
                                              "selected",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.filters.brands[n],
                                          "selected",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    attrs: { for: "checkboxBrand" + brand.id },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.updateAjax(brand.url)
                                      },
                                    },
                                  },
                                  [
                                    _c("a", {
                                      staticClass: "filterLink",
                                      attrs: { href: brand.url },
                                      domProps: {
                                        innerHTML: _vm._s(brand.name),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.filterNotEmpty("price", "prices")
                  ? _c("div", { staticClass: "filterBlock" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "filterBlockTitle toggleOpener",
                          class: [_vm.menu.prices ? "open" : ""],
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.menu.prices = !_vm.menu.prices
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Price\n\n                            "
                          ),
                          _c("i", { staticClass: "svgIcon iconOpen" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "svgIcon iconClose" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z",
                                    fill: "currentColor",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filterForm" },
                        [
                          _c("price-range-slider", {
                            attrs: {
                              prices: _vm.filters.price.prices,
                              "currency-symbol":
                                _vm.filters.price.currency_data.symbol,
                            },
                            on: { "update-ajax": _vm.updateAjax },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "menuFooter" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeFilterMenu()
                  },
                },
              },
              [_vm._v("\n                    Done filtering\n                ")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "filterTopBar" }, [
      _c("div", { staticClass: "topBarLeft" }, [
        _c(
          "button",
          {
            staticClass: "button buttonOutlined filterButtonLink",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleFilterMenu.apply(null, arguments)
              },
            },
          },
          [
            _vm._v("\n                Filter\n\n                "),
            _c("div", { staticClass: "svgIcon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "topBarRight" }, [
        _c("div", { staticClass: "selectWrap" }, [
          _vm.filterNotEmpty("sort", "options")
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c("chosen", {
                    staticClass: "no-chosen",
                    attrs: {
                      options: _vm.filters.sort.options,
                      placeholder: "Sort by",
                      label: "name",
                      "track-by": "value",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateSort()
                      },
                    },
                    model: {
                      value: _vm.filters.sort.option_selected,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters.sort, "option_selected", $$v)
                      },
                      expression: "filters.sort.option_selected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }