
/**
 * Vue filter to define decimal format
 * Usage [% item.line_total | decimal 2 %]
 */
Vue.filter('decimal', function (value, digits) {
    return parseFloat(value).toFixed(digits);
});

/**
 * Multi-currency filter
 * Receives value and returns html based on registered currencies
 * Expects the environment to have currencies defined
 *
 * Usage [%% item.line_total | multi_currency %%]
 */
Vue.filter('multi_currency', function (value) {

    var html = "<span class='multiPriceBlock'>",
        currency,
        selected = $('#selectedCurrency').data('current-currency');

    var currencies = {
        "GBP": {
            "id":2,
            "currency":"GBP",
            "ratio":1,
            "timestamp":"2015-02-12 10:46:13",
            "enabled":1,
            "symbol":"£",
            "name":"Great British Pound",
            "country_code":"GBP"
        }
    };

    if (selected === undefined) {
        selected = 'GBP';
    }

    for (currency in currencies) {
        if (currency !== 'GBP') {
            value = parseFloat(value) * currencies[currency].ratio;
        }

        html += "<span class='" + currency;
        if (selected === currency) {
            html += ' show';
        }
        html += "'>";
        html += currencies[currency].symbol + parseFloat(value).toFixed(2);
        html += "</span>\n";
    }
    html += "</span>";
    return html;
});

/**
 * Vue filter to truncate text to a limit
 * Usage [% text | truncate 50 %]
 */
Vue.filter('truncate', function (text, stop, end_style) {
    return text.slice(0, stop) + (stop < text.length ? end_style || '...' : '');
});


