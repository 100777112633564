var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "assessmentFormContent",
      attrs: { id: "assessmentContent" },
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "assessmentHeader" }, [
          _c("div", { staticClass: "headerBack" }, [
            _c(
              "a",
              {
                staticClass: "button buttonOutlined",
                class: [_vm.showBackButton ? "buttonVisible" : ""],
                attrs: { href: "#back" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "svgIcon" }, [
                  _c("svg", { attrs: { viewBox: "0 0 10 9" } }, [
                    _c(
                      "defs",
                      [
                        _c(
                          "linearGradient",
                          {
                            attrs: {
                              id: "140531f5-a172-4d96-955e-cf7eaf56b752",
                              x1: "11.25",
                              y1: "8.86",
                              x2: "-0.01",
                              y2: "1.89",
                              gradientTransform: "matrix(1, 0, 0, -1, 0, 10)",
                              gradientUnits: "userSpaceOnUse",
                            },
                          },
                          [
                            _c("stop", {
                              attrs: { offset: "0", "stop-color": "#444c4e" },
                            }),
                            _vm._v(" "),
                            _c("stop", {
                              attrs: { offset: "1", "stop-color": "#313638" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("path", {
                      staticStyle: {
                        fill: "url(#140531f5-a172-4d96-955e-cf7eaf56b752)",
                      },
                      attrs: {
                        d: "M.54,4.51,5,0H6.68L2.82,3.9h7.72V5.1H2.81L6.66,9H5Z",
                        transform: "translate(-0.54 0)",
                      },
                    }),
                  ]),
                ]),
                _vm._v(
                  "\n                    \n                    Back\n                "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "headerPharmacist" }, [
            _vm.prescribingDoctorName
              ? _c("div", { staticClass: "pharmacistBox" }, [
                  _c("div", { staticClass: "pharmacistBox__icon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          "data-name": "Layer 1",
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 22.74 25",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M19.92,11a2.81,2.81,0,0,0-.73,5.53v1.66a5.38,5.38,0,0,1-10.75,0V16.6a7,7,0,0,0,6.32-6.35l.63-6.64A2.57,2.57,0,0,0,12.84.82h-.06A1.54,1.54,0,0,0,11.44,0a1.56,1.56,0,0,0,0,3.11,1.54,1.54,0,0,0,1.34-.82h.06a1.1,1.1,0,0,1,.79.34,1.12,1.12,0,0,1,.29.82l-.64,6.64a5.61,5.61,0,0,1-11.17,0L1.48,3.45A1.08,1.08,0,0,1,2.56,2.29h.06A1.54,1.54,0,0,0,4,3.11,1.56,1.56,0,0,0,4,0,1.54,1.54,0,0,0,2.62.82H2.56A2.57,2.57,0,0,0,0,3.61l.63,6.64A7,7,0,0,0,7,16.6v1.55a6.85,6.85,0,0,0,13.7,0V16.49A2.81,2.81,0,0,0,19.92,11Zm0,4.16a1.35,1.35,0,1,1,1.35-1.34A1.32,1.32,0,0,1,19.92,15.12Z",
                            transform: "translate(0)",
                            fill: "currentColor",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M4.33,8a.75.75,0,0,0,.74.74H7V10.7a.74.74,0,0,0,1.47,0V8.77h1.93a.74.74,0,0,0,0-1.47H8.46V5.37A.74.74,0,0,0,7,5.37V7.3H5.07A.73.73,0,0,0,4.33,8Z",
                            transform: "translate(0)",
                            fill: "currentColor",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pharmacistBox__title" }, [
                    _vm._v(
                      "\n                        Today’s prescribing pharmacist:\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "pharmacistBox__name",
                    domProps: { innerHTML: _vm._s(_vm.prescribingDoctorName) },
                  }),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "mainForm",
            staticClass: "formContentWrap",
            attrs: {
              action: "",
              enctype: "multipart/form-data",
              method: "post",
              autocomplete: "off",
              id: "mainForm",
            },
          },
          [
            _vm._l(_vm.sections, function (section, sIndex) {
              return [
                section
                  ? _c(
                      "form-section",
                      {
                        key: "section" + section.id,
                        attrs: {
                          section: section,
                          "show-section-header": false,
                          "url-add-to-basket": _vm.urlAddToBasket,
                        },
                        on: { "update-section": _vm.proceed },
                      },
                      [_c("template", { slot: "timer" }, [_vm._t("timer")], 2)],
                      2
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm.$store.state.assessment.travel_form
              ? [
                  _vm._l(_vm.multiusers, function (multiuser) {
                    return [
                      _c("input", {
                        key: "multiusername" + multiuser.id,
                        attrs: {
                          type: "hidden",
                          name: "multiusers[" + multiuser.id + "][name]",
                        },
                        domProps: { value: multiuser.name },
                      }),
                    ]
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "assessmentProgress" }, [
          _c("div", { staticClass: "assessmentProgress__bar" }, [
            _c("div", {
              staticClass: "assessmentProgress__progress",
              style: "width:" + _vm.currentProgress + "%;",
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }