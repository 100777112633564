<template>

    <section class="outOfStockNotifyWrapper">

        <div class="outOfStockBox" :class="[isBusy ? 'loading' : '']">


            <template v-if="!didSubmit">

                <div class="innerStockBox">

                    <h4 class="stockTitle">Get stock notifications</h4>

                    <div class="stockContent">
                        <p>Enter your email address below and we will notify you when this item is back in stock.</p>
                    </div> <!-- .stockContent -->

                    <div class="stockFormWrap">

                        <div class="row">
                            <label for="stockEmail">Email address:</label>
                            <input
                                    name="email"
                                    type="email"
                                    placeholder="Email address"
                                    v-model="email"
                                    id="stockEmail"
                            />
                        </div>

                        <button
                                type="submit"
                                class="button"
                                :disabled="(! isEmailValid) || isBusy"
                                v-text="buttonLabel"
                                @click="submitOutOfStockNotifyForm"
                        >
                        </button>

                    </div>

                </div> <!-- .innerStockBox -->

            </template>

            <template v-else>

                <div class="innerStockBox">

                    <h4 class="stockTitle">Thank you.</h4>

                    <div class="stockContent">
                        <p>Your email has been registered and we will email you when the product is back in stock.</p>
                    </div> <!-- .stockContent -->

                </div>
            </template>


            <div class="loadingOverlay">

                <loading-spinner
                        :loading="isBusy"
                        :loading-text="loadingMessage"
                        :show-loading-text="false"
                ></loading-spinner>

            </div> <!-- .loadingOverlay -->

        </div> <!-- .outOfStockBox -->

    </section>

</template>

<script>
export default {
    name: 'outOfStockNotify',

    props: {
        urlSubmit: {
            type: String,
            default: null,
        },
        buttonLabel: {
            type: String,
            default: null,
        },
        shopItemId: {
            type: Number,
            default: null,
        },
    },

    data: function() {
        return {
            email: '',
            didSubmit: false,
            isBusy: false,
            loadingMessage: 'Please wait...'
        }
    },

    created: function() {
        //this.fetchAvailableOptions();
    },

    computed: {

        isEmailValid: function() {
            let is_valid = false;

            // Super simple validation.
            if (this.email.length >= 5 && this.email.match(/^[^@]+@[^\.@]+\.[^\.@]+/)) {
                is_valid = true;
            }

            return is_valid;
        },

    },

    methods: {

        submitOutOfStockNotifyForm: function() {
            this.isBusy = true;

            this.$http.post(this.urlSubmit, {
                email: this.email,
                item_id: this.shopItemId,
            })
                    .then(function (res) {
                        if (! res.data.errors) {
                            this.didSubmit = true;
                        }
                    })
                    .finally(function() {
                        this.isBusy = false;
                    })
        },

    }
}
</script>

<style lang="less" scoped>
    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .outOfStockNotifyWrapper {

         padding: 35px 0 0;

        .outOfStockBox {
            background: @tertiary_color;
            padding: 35px;
            border-radius: 15px;
            position: relative;
            overflow: hidden;

            @media all and (max-width:@product_breakpoint_width) {
                max-width: 500px;
                margin: auto;
                text-align: center;
            }

            &.loading {

                .loadingOverlay {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: none;
                    transition: opacity 200ms linear, visibility 200ms linear;
                }
            }
        }

        .loadingOverlay {
            opacity: 0;
            visibility: hidden;
            pointer-events: auto;
            transition: opacity 200ms linear, visibility 200ms linear;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(255,255,255,0.16);
            backdrop-filter: blur(4px);

            display: flex;
            justify-content: center;
            align-items: center;

            .loading-spinner {
                position: relative;
            }


        }

        .innerStockBox {
            z-index: 1;
            position: relative;
        }

        .stockTitle {
            .rems(14);
            line-height: 22px;
            margin: 0;
        }

        .stockContent {
            .rems(12);
            line-height: 12px;
            padding: 4px 0 0;
        }

        .stockFormWrap {
            padding: 16px 0 0;
        }

        .row {
            float: none;
            clear: both;
            width: 100%;
            margin: 0 0 20px;

            label {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        .button {
            background: @primary_gradient;
            margin: 0;
            width: 100%;
        }
    }


</style>
