Vue.directive('select_tabs', {
    twoWay: true,
    deep: true,
    bind: function () {
        var selector = $(this.el),
            element = selector[0],
            handle = this,
            action = handle.arg;

        selector.chosen({
            disable_search: true,
        }).change(function () {
            handle.set(element.value);

            if (action !== undefined) {
                handle.vm.callUpdate(action);
            }
        });

    },
    update: function() {
        return $(this.el).trigger('chosen:updated');
    }
});

Vue.component('cta_tabs', {
    props: [
        'tab_list',
        'use_value',
        'pagination'
    ],

    data: function () {
        return {
            tabs: [],
            currentlyVisibleID: '',
            page: 1,
            totalPages: 1,
            paginationEnabled: false,
            loading: true
        };
    },

    created: function () {

        this.tabs = this.tab_list;
        this.currentlyVisibleID = typeof Object != 'undefined' ? Object.keys(this.tabs)[0] : 0;

        if(typeof this.pagination != 'undefined') {
            if(this.pagination) {
                this.paginationEnabled = true;
                this.totalPages = this.tabs[this.currentlyVisibleID].pagination.total_pages;

            }
        }

    },

    mounted: function() {
        this.loading = false;
    },

    methods: {
        updateVisible: function(tabName) {
            this.currentlyVisibleID = tabName;

            if(typeof this.pagination != 'undefined') {
                if(this.pagination) {

                    this.page = 1;
                    this.totalPages = this.tabs[this.currentlyVisibleID].pagination.total_pages;

                }
            }

        },
        callUpdate: function(action) {

            if(action == 'reset_pages') {
                this.page = 1;

                if(typeof this.pagination != 'undefined') {
                    if(this.pagination) {

                        this.page = 1;
                        this.totalPages = this.tabs[this.currentlyVisibleID].pagination.total_pages;

                    }
                }
            }

        },
        nextPage: function() {
            this.page = this.page + 1;
            this.scrollPage();
        },
        prevPage: function() {
            this.page = this.page - 1;

            if(this.page < 1) {
                this.page = 1;
            }
            this.scrollPage();
        },
        gotoPage: function(pageNum) {
            this.page = pageNum;
            this.scrollPage();
        },

        scrollPage: function() {

            $('body,html').animate({
                scrollTop: ($('.membersIntro').eq(0).offset().top - 16)
            }, 2000);
        }

    }


});
