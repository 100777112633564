var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assessmentSidebar" }, [
    _c(
      "div",
      { staticClass: "sidebarWrap" },
      [
        _c("form-card", {
          attrs: { image: _vm.formImage, title: _vm.formTitle },
        }),
        _vm._v(" "),
        _c("section-navigation", { attrs: { sections: _vm.sections } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }