$(document).ready(function () {

    'use strict';

    slickServices();

});


$(window).on('resize', debouncer(function (e) {

    'use strict';

    slickServices();

})); // debounce


/*
* Init slick service slider
 */
function slickServices() {

    'use strict';

    if ($('.serviceList').length) {

        slickIt('.serviceList', 800, {
            dots : true,
            infinite : false,
            arrows : false,
            autoplay : false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            fade: false,
            lazyLoad: 'ondemand'
        });
    }

}
