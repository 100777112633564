Vue.component('searchv2', {
    created: function () {

        var vm = this;

        $(window).click(function () {
            vm.term = '';
        });

        $('.searchForm').click(function (event) {
            event.stopPropagation();
        });

        this.results = this.defaultResults;

    },
    props: {
        action: {
            type: [Array, Object, String],
            default: false
        },
        error: {
            type: [Boolean],
            default: false
        }
    },
    data: function () {
        return {
            results: [],
            searching: false,
            defaultResults: {
                products: [],
                categories: [],
                pages: [],
                brands: [],
                doctor_surgeries: [],
                faqs: []
            },
            term: ''
        };
    },
    methods: {
        search: function () {

            this.searching = true;
            this.$http.get(this.action + '?term=' + this.term, {
                before: function (request) {

                    // abort previous request, if exists
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }
                    // set previous request on Vue instance
                    this.previousRequest = request;
                }
            }).then(function (response) {
                if (response.data.status === 'ok') {
                    this.results = response.data;
                    this.searching = false;
                }
            }, function (response) {
                //Catch failed request
            });


            //Close menu menu to avoid overlap
            this.closeMainMenu();

        },
        getSearch: function() {

            window.location = '?search_term=' + this.term;

        },
        clearSearch: function () {
            this.term = '';
        },
        selectItem: function (selected_item, type) {
            selected_item.type = type;
            this.results = this.defaultResults;
            EventBus.$emit('searchSelection', selected_item);
        },
        closeMainMenu: function() {
            return false;
        }
    }

});
