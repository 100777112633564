var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "themeToggleWrapper" }, [
    _c("h6", [
      _c("span", [_vm._v("THEME TOGGLE")]),
      _vm._v(" "),
      _c("span", { staticClass: "closeButton", on: { click: _vm.hidePopup } }, [
        _vm._v("✖"),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.themes, function (temp_theme) {
        return _c("li", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.themeName,
                expression: "themeName",
              },
            ],
            attrs: { type: "radio", id: "theme_" + temp_theme },
            domProps: {
              value: temp_theme,
              checked: _vm._q(_vm.themeName, temp_theme),
            },
            on: {
              change: [
                function ($event) {
                  _vm.themeName = temp_theme
                },
                _vm.changeTheme,
              ],
            },
          }),
          _vm._v(" "),
          _c("label", {
            attrs: { for: "theme_" + temp_theme },
            domProps: { textContent: _vm._s(temp_theme) },
          }),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "homeLink" }, [
      _c("a", { attrs: { href: "/", title: "Return to homepage" } }, [
        _vm._v("\n            Go to homepage\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }